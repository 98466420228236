export function getParentPath(path: string) {
    const segments = path.split('/');
    if (segments[segments.length - 1] === '') {
        segments.pop();
    }
    segments.pop();
    let parentPath = segments.reduce((ans, segment) => ans + segment + '/', '');
    if (parentPath.length === 0) parentPath = '/';

    return parentPath;
}

export function isFolderPath(path: string) {
    if (path[0] !== '/' || path[path.length - 1] !== '/') {
        throw new Error('Invalid folder path');
    }
}
