import React, { useCallback, useEffect, useRef, useState } from 'react';
import { IUser } from '../../types/user.interface';
import axiosService, { axiosErrorHandler } from '../../services/axios.service';
import axios from 'axios';
import Swal from 'sweetalert2';
import { IMultiItemResponseData } from '../../types/response-data.interface';
import clsx from 'clsx';
import styles from './update-admin-group-modal.module.css';
import SearchBar from '../search-bar/search-bar';
import ManageItemsTable from '../manage-items-table/manage-items-table';
import { IProject } from '../../types/project.interface';

function UpdateAdminGroupModal({
    id,
    project,
    onUpdateSuccess
}: {
    id: string;
    project: IProject;
    onUpdateSuccess?: Function;
}) {
    const [isLoading, setIsLoading] = useState(false);
    const [admins, setAdmins] = useState<IUser[]>([]);

    const closeButtonRef = useRef(null);

    useEffect(() => {
        setAdmins(project.admins as IUser[]);
    }, [project]);

    const updateAdminsOfProject = useCallback(
        async (projectId: string, admins: IUser[]) => {
            try {
                setIsLoading(true);
                await axiosService.patch(`projects/${projectId}`, {
                    admins: admins.map((m) => m.id)
                });
                (closeButtonRef.current as any).click();
                Swal.fire({
                    title: 'Update group successfully',
                    icon: 'success',
                    showConfirmButton: true,
                    confirmButtonText: 'Ok'
                });
                onUpdateSuccess && onUpdateSuccess();
            } catch (error) {
                if (axios.isAxiosError(error)) {
                    axiosErrorHandler(error);
                } else {
                    console.log('error:  ', error);
                }
            } finally {
                setIsLoading(false);
            }
        },
        [onUpdateSuccess]
    );

    const handleUpdateAdminsOfProject = useCallback(
        async (projectId: string, admins: IUser[]) => {
            await updateAdminsOfProject(projectId, admins);
        },
        [updateAdminsOfProject]
    );

    const fetchUsers = useCallback(async (value: string): Promise<IUser[]> => {
        try {
            const { data }: { data: IMultiItemResponseData<IUser> } = (
                await axiosService.get(`/users?fields=username email&q=${value}&limit=10`)
            ).data;
            const { items } = data;
            return items;
        } catch (error) {
            if (axios.isAxiosError(error)) {
                axiosErrorHandler(error);
            } else {
                console.log('error:  ', error);
            }
            return [];
        }
    }, []);

    const handleDisplaySearchUserOption = useCallback((u: IUser) => {
        return u.username;
    }, []);

    const userIsNotExistsInAdmins = useCallback(
        (u: IUser) => {
            return !admins.some((e) => e.id === u.id);
        },
        [admins]
    );

    const addMember = useCallback(
        (admin: IUser) => {
            setAdmins([...admins, admin]);
        },
        [admins]
    );

    return (
        <div
            className={clsx('modal fade', styles.modalWrapper)}
            id={id}
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            tabIndex={-1}
            aria-labelledby="staticBackdropLabel"
            aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="staticBackdropLabel">
                            Update group
                        </h5>
                        <button
                            type="button"
                            className={clsx('btn-close')}
                            data-bs-dismiss="modal"
                            aria-label="Close"
                            ref={closeButtonRef}></button>
                    </div>
                    <div className="modal-body">
                        {isLoading ? (
                            <div className="d-flex justify-content-center">
                                <div className="spinner-border" role="status"></div>
                            </div>
                        ) : (
                            <div className={clsx(styles.form)}>
                                <div className="form-group">
                                    <label className={clsx('form-label required', styles.label)}>
                                        Name
                                    </label>
                                    <input
                                        type="text"
                                        className={clsx('form-control')}
                                        value="Admins"
                                        disabled
                                    />
                                </div>

                                <div className="form-group mt-3">
                                    <label className={clsx('form-label', styles.label)}>
                                        Members
                                    </label>
                                    <SearchBar<IUser>
                                        placeholder="Add member"
                                        loadOptions={fetchUsers}
                                        delayTime={500}
                                        handleDisplayOption={handleDisplaySearchUserOption}
                                        filterBeforeDisplay={userIsNotExistsInAdmins}
                                        onSelectOption={addMember}
                                        cacheOptions={true}
                                    />
                                    <ManageItemsTable
                                        items={admins}
                                        setItems={setAdmins}
                                        editable={true}
                                        columns={['username', 'email']}
                                    />
                                </div>

                                <div className="w-100 pt-3">
                                    <button
                                        type="submit"
                                        className="btn btn-success w-100"
                                        disabled={isLoading}
                                        onClick={(e: React.MouseEvent) => {
                                            e.preventDefault();
                                            handleUpdateAdminsOfProject(project.id, admins);
                                        }}>
                                        {isLoading ? 'Updating...' : 'Update'}
                                    </button>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default UpdateAdminGroupModal;
