import React from 'react';
import { MessageWsType, useWs } from '../hooks/use-websocket';
import { StatusAssembliesWsType, EnableFileWsType } from '../events/types';

export type SocketContextType = {
    message?: MessageWsType<StatusAssembliesWsType | EnableFileWsType> | null;
    getData?<T>(): MessageWsType<T>;
};

type Props = {
    children: React.ReactNode | JSX.Element;
};

type MessageStateType = StatusAssembliesWsType | EnableFileWsType;

type DataStateType = MessageWsType<StatusAssembliesWsType | EnableFileWsType>;

const WebSocketContext = React.createContext<SocketContextType>({});

const SocketProvider: React.FC<Props> = ({ children }) => {
    const [data, setData] = React.useState<DataStateType>();
    const { message } = useWs<MessageStateType>();

    React.useEffect(() => {
        if (!message) return;
        setData({ ...message });
    }, [message?.id, message?.t]);

    function getData<T>(): MessageWsType<T> {
        return message as MessageWsType<T>;
    }

    return (
        <WebSocketContext.Provider
            value={{
                message: message,
                getData: getData
            }}>
            {children}
        </WebSocketContext.Provider>
    );
};

export function useWebSocketContext() {
    return React.useContext<SocketContextType>(WebSocketContext);
}

export default SocketProvider;
