import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import clsx from 'clsx';
import styles from './index.module.css';

const appName = 'ADSCivil CDE';
const titles: { [key: string]: string } = {
    '/': appName,
    '/login': `Login - ${appName}`
};

function UnauthenicatedLayout({ children }: { children: React.ReactNode }) {
    const location = useLocation();
    useEffect(() => {
        document.title = titles[location.pathname] ?? appName;
    }, [location]);
    return <div className={clsx(styles.layoutWrapper)}>{children}</div>;
}

export default UnauthenicatedLayout;
