import clsx from 'clsx';
import React, { useCallback, useState } from 'react';
import styles from './login-page.module.css';
import axiosService, { axiosErrorHandler } from '../../../services/axios.service';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import type { ILoginResponse } from '../../../types/login.interface';
import { AuthStorageService } from '../../../services/authStorage.service';
import { useAuth } from '../../../providers/auth.provider';

function LoginPage() {
    const [username, setUsername] = useState('');
    const [isValidUsername, setIsValidUsername] = useState(true);
    const [inValidUsernameMessage, setInValidUsernameMessage] = useState('');
    const [password, setPassword] = useState('');
    const [isValidPassword, setIsValidPassword] = useState(true);
    const [inValidpasswordMessage, setInValidpasswordMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const { setUser } = useAuth();

    // const resetForm = () => {
    //   setUsername('');
    //   setPassword('');
    // }

    const resetValidate = useCallback(() => {
        setIsValidUsername(true);
        setIsValidPassword(true);
    }, []);

    const validateForm = useCallback(
        (username: string, password: string): boolean => {
            resetValidate();
            let isValid = true;
            if (username.length === 0) {
                setIsValidUsername(false);
                setInValidUsernameMessage('Username is required');
                isValid = false;
            }
            if (password.length === 0) {
                setIsValidPassword(false);
                setInValidpasswordMessage('Password is required');
                isValid = false;
            }
            return isValid;
        },
        [resetValidate]
    );

    const login = useCallback(
        async (username: string, password: string) => {
            try {
                setIsLoading(true);
                const { data }: { data: ILoginResponse } = (
                    await axiosService.post('/auth/login', {
                        username,
                        password
                    })
                ).data;
                const {
                    access_token,
                    refresh_token,
                    access_token_expires_at,
                    refresh_token_expires_at,
                    ...user
                } = data;
                AuthStorageService.setLoginUser(user);
                setUser(user);
                navigate('/');
            } catch (error) {
                if (axios.isAxiosError(error)) {
                    axiosErrorHandler(error);
                } else {
                    console.log('error:  ', error);
                }
            } finally {
                setIsLoading(false);
            }
        },
        [setUser, navigate]
    );

    const handleLogin = useCallback(
        async (username: string, password: string) => {
            if (validateForm(username, password)) {
                await login(username, password);
            }
        },
        [validateForm, login]
    );

    return (
        <div className={clsx('pageMinHeight d-flex position-relative ')}>
            <p className={clsx(styles.copyright)}>
                © 2024 a product of <Link to="https://adscivil.vn">ADSCivil</Link>
            </p>
            <div className={clsx(styles.left, styles.expandingElement, 'p-2')}>
                <div className={clsx(styles.subBlock)}>
                    <h5 className={clsx(styles.productName)}>ADSCivil CDE</h5>
                    <h1 className={clsx(styles.slogan)}>
                        ADSCIVIL CDE - Môi trường dữ liệu chung cho dự án BIM hạ tầng
                    </h1>
                    <h5>
                        Brings practical benefits to infrastructure design engineers: Quality - Time
                        - Cost.
                    </h5>
                </div>
            </div>
            <div
                className={clsx(
                    styles.right,
                    styles.fixedElement,
                    'd-flex flex-column justify-content-center align-items-center'
                )}>
                <img
                    src="/company-logo.png"
                    alt="BaezeniSoft Logo"
                    style={{
                        height: 120,
                        width: 'auto'
                    }}
                    className={clsx('mb-2')}
                />
                <h4 className={clsx('mb-2', styles.title)}>Login to A2Z CDE</h4>
                <div className={clsx('d-flex flex-column align-items-center', styles.formWrapper)}>
                    <form
                        className={clsx(styles.loginForm)}
                        onSubmit={(e: React.FormEvent) => {
                            e.preventDefault();
                            handleLogin(username, password);
                        }}>
                        <div className="form-group ">
                            <label htmlFor="username" className="form-label required mb-1">
                                Username
                            </label>
                            <input
                                type="text"
                                className={clsx('form-control', !isValidUsername && 'is-invalid')}
                                id="username"
                                placeholder="Username"
                                onChange={(e) => setUsername(e.target.value)}
                                value={username}
                            />
                            <div id="validationUsername" className="invalid-feedback">
                                {inValidUsernameMessage}
                            </div>
                        </div>
                        <div className="form-group mt-3">
                            <label htmlFor="password" className="form-label required mb-1">
                                Password
                            </label>
                            <input
                                type="password"
                                className={clsx('form-control', !isValidPassword && 'is-invalid')}
                                id="password"
                                placeholder="Password"
                                onChange={(e) => setPassword(e.target.value)}
                                value={password}
                            />
                            <div id="validationPassword" className="invalid-feedback">
                                {inValidpasswordMessage}
                            </div>
                        </div>
                        <button
                            type="submit"
                            className="btn btn-success mt-4 w-100"
                            disabled={isLoading}>
                            {isLoading ? 'Logging in...' : 'Login'}
                        </button>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default LoginPage;
