import { OpenDesign } from '../../utils/api-path.util';
import axiosService from '../axios.service';

export const getTreeAssemblies = async (assembliesId: string) => {
    try {
        const { data } = await axiosService.get(
            OpenDesign({ assemblyId: assembliesId }).GET_TREE_ASSEMBLIES
        );

        return data.data;
    } catch (error) {
        console.log(error);
    }
};
