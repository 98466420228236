import clsx from 'clsx';
import React from 'react';
import styles from './profile-submenu.module.css';
import { Link } from 'react-router-dom';
import { logoutIcon, settingIcon, user2Icon } from '../../../assets/icons';
import { useAuth } from '../../../providers/auth.provider';
import { ILoggedInUser } from '../../../types/login.interface';

function ProfileSubmenu({ setIsOpen }: { setIsOpen: Function }) {
    const { user }: { user: ILoggedInUser } = useAuth();
    return (
        <div className={clsx(styles.wrapper)}>
            <Link
                to="/me"
                className={clsx(
                    'text-decoration-none d-flex justify-content-start align-items-center',
                    styles.item
                )}
                onClick={(e: React.MouseEvent) => {
                    e.stopPropagation();
                    setIsOpen(false);
                }}>
                <img src={user2Icon} alt="" className={clsx(styles.item_icon, 'me-3')} />
                <p className={clsx(styles.item_text)}>{user.username}</p>
            </Link>
            <Link
                to="/setting"
                className={clsx(
                    'text-decoration-none d-flex justify-content-start align-items-center',
                    styles.item
                )}
                onClick={(e: React.MouseEvent) => {
                    e.stopPropagation();
                    setIsOpen(false);
                }}>
                <img src={settingIcon} alt="" className={clsx(styles.item_icon, 'me-3')} />
                <p className={clsx(styles.item_text)}>Setting</p>
            </Link>
            <Link
                to="/auth/logout"
                className={clsx(
                    'text-decoration-none d-flex justify-content-start align-items-center',
                    styles.item
                )}
                onClick={(e: React.MouseEvent) => {
                    e.stopPropagation();
                    setIsOpen(false);
                }}>
                <img src={logoutIcon} alt="" className={clsx(styles.item_icon, 'me-3')} />
                <p className={clsx(styles.item_text)}>Logout</p>
            </Link>
        </div>
    );
}

export default ProfileSubmenu;
