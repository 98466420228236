import clsx from 'clsx';
import React, { useCallback, useRef, useState } from 'react';
import styles from './create-user-modal.module.css';
import { EUserRole } from '../../types/login.interface';
import axiosService, { axiosErrorHandler } from '../../services/axios.service';
import Swal from 'sweetalert2';
import axios from 'axios';

function CreateUserModal({ id, onCreateSuccess }: { id: string; onCreateSuccess: Function }) {
    const [isLoading, setIsLoading] = useState(false);

    const [username, setUsername] = useState('');
    const [isValidUsername, setIsValidUsername] = useState(true);
    const [inValidUsernameMessage, setInValidUsernameMessage] = useState('');
    const [password, setPassword] = useState('');
    const [isValidPassword, setIsValidPassword] = useState(true);
    const [inValidPasswordMessage, setInValidPasswordMessage] = useState('');
    const [retypePassword, setRetypePassword] = useState('');
    const [isValidRetypePassword, setIsValidRetypePassword] = useState(true);
    const [inValidRetypePasswordMessage, setInValidRetypePasswordMessage] = useState('');
    const [email, setEmail] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [role, setRole] = useState(0);

    const closeButtonRef = useRef(null);

    const resetForm = useCallback(() => {
        setUsername('');
        setPassword('');
        setRetypePassword('');
        setEmail('');
        setFirstName('');
        setLastName('');
        setRole(EUserRole.USER);
    }, []);

    const resetValidate = useCallback(() => {
        setIsValidUsername(true);
        setIsValidPassword(true);
        setIsValidRetypePassword(true);
    }, []);

    const validateForm = useCallback(
        (username: string, password: string, retypePassword: string, email: string, firstName: string, lastName: string): boolean => {
            resetValidate();
            let isValid = true;
            if (username.length === 0) {
                setIsValidUsername(false);
                setInValidUsernameMessage('Please choose a username');
                isValid = false;
            }
            if (password.length === 0) {
                setIsValidPassword(false);
                setInValidPasswordMessage('Please enter password');
                isValid = false;
            }
            if (password.length < 8) {
                setIsValidPassword(false);
                setInValidPasswordMessage('Password must be at least 8 characters');
                isValid = false;
            }
            if (password !== retypePassword) {
                setIsValidRetypePassword(false);
                setInValidRetypePasswordMessage('Passwords do not match');
                isValid = false;
            }
            if (email.length > 0 && !email.includes('@')) {
                isValid = false;
            }
            if (firstName.length > 0 && firstName.length < 2) {
                isValid = false;
            }
            if (lastName.length > 0 && lastName.length < 2) {
                isValid = false;
            }
            return isValid;
        },
        [resetValidate]
    );

    const createNewUser = useCallback(
        async (
            username: string,
            password: string,
            email: string,
            firstName: string,
            lastName: string,
            role: number
        ) => {
            try {
                setIsLoading(true);
                await axiosService.post('users', {
                    username,
                    password,
                    ...(email.length > 0 && { email }),
                    ...(firstName.length > 0 && { firstName }),
                    ...(lastName.length > 0 && { lastName }),
                    role
                });
                resetForm();
                (closeButtonRef.current as any).click();
                Swal.fire({
                    title: 'Create user successfully',
                    icon: 'success',
                    showConfirmButton: true,
                    confirmButtonText: 'Ok'
                }).then((result) => {
                    if (result.isConfirmed) {
                        onCreateSuccess();
                    }
                });
            } catch (error) {
                if (axios.isAxiosError(error)) {
                    axiosErrorHandler(error);
                } else {
                    console.log('error:  ', error);
                }
            } finally {
                setIsLoading(false);
            }
        },
        [resetForm, onCreateSuccess]
    );

    const handleCreateUser = useCallback(
        async (
            username: string,
            password: string,
            retypePassword: string,
            email: string,
            firstName: string,
            lastName: string,
            role: number
        ) => {
            if (validateForm(username, password, retypePassword, email, firstName, lastName)) {
                await createNewUser(username, password, email, firstName, lastName, role);
            }
        },
        [createNewUser, validateForm]
    );

    return (
        <div
            className={clsx('modal fade', styles.modalWrapper)}
            id={id}
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            tabIndex={-1}
            aria-labelledby="staticBackdropLabel"
            aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="staticBackdropLabel">
                            Create new user
                        </h5>
                        <button
                            type="button"
                            className={clsx('btn-close')}
                            data-bs-dismiss="modal"
                            aria-label="Close"
                            ref={closeButtonRef}></button>
                    </div>
                    <div className="modal-body">
                        <div className={clsx(styles.form)}>
                            <div className="form-group">
                                <label className={clsx('form-label required', styles.label)}>
                                    Username
                                </label>
                                <input
                                    type="text"
                                    className={clsx(
                                        'form-control',
                                        !isValidUsername && 'is-invalid'
                                    )}
                                    value={username}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                        setUsername(e.target.value);
                                    }}
                                    maxLength={255}
                                />
                                <div id="validationProjectName" className="invalid-feedback">
                                    {inValidUsernameMessage}
                                </div>
                            </div>

                            <div className="form-group mt-3">
                                <label className={clsx('form-label required', styles.label)}>
                                    Password
                                </label>
                                <input
                                    type="password"
                                    className={clsx(
                                        'form-control',
                                        !isValidPassword && 'is-invalid'
                                    )}
                                    value={password}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                        setPassword(e.target.value);
                                    }}
                                />
                                <div id="validationProjectName" className="invalid-feedback">
                                    {inValidPasswordMessage}
                                </div>
                            </div>

                            <div className="form-group mt-3">
                                <label className={clsx('form-label required', styles.label)}>
                                    Confirm password
                                </label>
                                <input
                                    type="password"
                                    className={clsx(
                                        'form-control',
                                        !isValidRetypePassword && 'is-invalid'
                                    )}
                                    value={retypePassword}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                        setRetypePassword(e.target.value);
                                    }}
                                />
                                <div id="validationProjectName" className="invalid-feedback">
                                    {inValidRetypePasswordMessage}
                                </div>
                            </div>

                            <div className="form-group mt-3">
                                <label className={clsx('form-label required', styles.label)}>Email</label>
                                <input
                                    type="email"
                                    className={clsx('form-control')}
                                    value={email}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                        setEmail(e.target.value);
                                    }}
                                    maxLength={255}
                                />
                            </div>

                            <div className="form-group mt-3">
                                <label className={clsx('form-label required', styles.label)}>
                                    First name
                                </label>
                                <input
                                    type="text"
                                    className={clsx('form-control')}
                                    value={firstName}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                        setFirstName(e.target.value);
                                    }}
                                    maxLength={64}
                                />
                            </div>

                            <div className="form-group mt-3">
                                <label className={clsx('form-label required', styles.label)}>
                                    Last name
                                </label>
                                <input
                                    type="text"
                                    className={clsx('form-control')}
                                    value={lastName}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                        setLastName(e.target.value);
                                    }}
                                    maxLength={64}
                                />
                            </div>

                            <div className="form-group mt-3">
                                <label className={clsx('form-label required', styles.label)}>
                                    Role
                                </label>
                                <select
                                    name="role"
                                    className="form-select"
                                    value={role}
                                    onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                                        setRole(parseInt(e.target.value));
                                    }}>
                                    <option value={0}>User</option>
                                    <option value={1}>System admin</option>
                                </select>
                            </div>

                            <div className="w-100 pt-4">
                                <button
                                    type="submit"
                                    className="btn btn-success w-100"
                                    disabled={isLoading}
                                    onClick={(e: React.MouseEvent) => {
                                        e.preventDefault();
                                        handleCreateUser(
                                            username,
                                            password,
                                            retypePassword,
                                            email,
                                            firstName,
                                            lastName,
                                            role
                                        );
                                    }}>
                                    {isLoading ? 'Creating...' : 'Create'}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CreateUserModal;
