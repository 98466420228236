import React from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthStorageService } from '../../../services/authStorage.service';
import { useAuth } from '../../../providers/auth.provider';

function LogoutPage() {
    const navigate = useNavigate();
    const { setUser } = useAuth();
    AuthStorageService.resetAll();
    setUser({});
    navigate('/login');

    return <div>LogoutPage</div>;
}

export default LogoutPage;
