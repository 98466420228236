import React, { useCallback, useRef, useState } from 'react'
import { Tooltip } from 'react-tooltip';
import styles from './upload-file-button.module.css';
import clsx from 'clsx';
import { cancelIcon, uploadIcon } from '../../assets/icons';
import { IProject } from '../../types/project.interface';
import axiosService, { axiosErrorHandler } from '../../services/axios.service';
import axios from 'axios';
import Swal from 'sweetalert2';
import { byteConverter } from '../../utils/byte-converter.util';

function UploadFileButton({
    project,
    path,
}: {
    project: IProject,
    path: string,
}) {
    const [isLoading, setIsLoading] = useState(false);
    const [files, setFiles] = useState<File[]>([]);
    const [isValidFiles, setIsValidFiles] = useState(true);
    const [message, setMessage] = useState("");
    const inputFilesRef = useRef<HTMLInputElement>(null);

    const closeButtonRef = useRef(null);

    const resetForm = useCallback(() => {
        setFiles([]);
        setMessage('');
    }, []);

    const resetValidate = useCallback(() => {
        setIsValidFiles(true);
    }, []);

    const validateForm = useCallback((files: File[]) => {
        resetValidate();
        let isValid = true;
        if (!files.length) {
            setIsValidFiles(false);
            isValid = false;
        }
        return isValid;
    }, [resetValidate]);

    const uploadFiles = useCallback(
        async (
            projectId: string,
            path: string,
            files: File[],
            message: string,
        ) => {
            try {
                setIsLoading(true);
                // create form data
                const formData = new FormData();
                files.forEach((f) => {
                    formData.append(`files`, f, f.name);
                });
                // formData.append("files", files);
                formData.append("path", path);
                formData.append("commitMessage", message);

                // call upload api
                await axiosService.post(`fifos/upload/${projectId}`, formData, {
                    headers: { "Content-Type": "multipart/form-data" },
                });

                // reset and close form
                resetForm();
                (closeButtonRef.current as any).click();

                // show success notification
                Swal.fire({
                    title: 'Upload success',
                    icon: 'success',
                    showConfirmButton: true,
                    confirmButtonText: 'Ok',
                }).then((result) => {
                    if (result.isConfirmed) {
                        window.location.reload();
                    }
                });
            } catch (error) {
                if (axios.isAxiosError(error)) {
                    axiosErrorHandler(error);
                } else {
                    console.log('error:  ', error);
                }
            } finally {
                setIsLoading(false);
            }
        }, [resetForm]);

    const handleUploadFiles = useCallback(
        async (
            projectId: string,
            path: string,
            files: File[],
            message: string,
        ) => {
            if (validateForm(files)) {
                await uploadFiles(projectId, path, files!, message);
            }
        },
        [validateForm, uploadFiles]
    )

    const addFilesToList = useCallback((newFiles: FileList, files: File[]) => {
        let newList = [...files];
        for (let i = 0; i < newFiles.length; i++) {
            if (newList.findIndex(x => x.name === newFiles[i].name) === -1) {
                newList.unshift(newFiles[i]);
            }
        };
        setFiles(newList);
    }, []);

    const removeFilesFormList = useCallback((index: number, files: File[]) => {
        const newList = [...files];
        newList.splice(index, 1);
        setFiles(newList);
    }, []);

    return (
        <>
            {/* tooltip */}
            <Tooltip
                className={clsx(styles.tooltip)}
                anchorSelect='#uploadFilesIcon'
            >
                Upload files
            </Tooltip>

            {/* icon */}
            <img
                className={clsx(styles.icon)}
                id="uploadFilesIcon"
                src={uploadIcon}
                alt=""
                data-bs-toggle="modal"
                data-bs-target={`#uploadFilesModal`}
            />

            {/* modal */}
            <div
                className={clsx('modal fade', styles.modalWrapper)}
                id="uploadFilesModal"
                data-bs-backdrop="static"
                data-bs-keyboard="false"
                tabIndex={-1}
                aria-labelledby="staticBackdropLabel"
                aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="staticBackdropLabel">
                                Upload files
                            </h5>
                            <button
                                type="button"
                                className={clsx('btn-close')}
                                data-bs-dismiss="modal"
                                aria-label="Close"
                                ref={closeButtonRef}></button>
                        </div>
                        <div className="modal-body">
                            <div className="form-group">
                                <label className={clsx("form-label required", styles.label)}>
                                    Project
                                </label>
                                <input
                                    className="form-control"
                                    type="text"
                                    value={project.name}
                                    disabled
                                />
                            </div>
                            <div className="form-group mt-3">
                                <label className={clsx("form-label required", styles.label)}>
                                    Path
                                </label>
                                <input
                                    className="form-control"
                                    type="text"
                                    value={path}
                                    disabled
                                />
                            </div>
                            <div className="form-group mt-3">
                                <div className="d-flex justify-content-between align-item-center">
                                    <label htmlFor="formFiles" className={clsx("form-label required m-0", styles.label)}>
                                        Files
                                    </label>
                                    <button
                                        onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                                            e.stopPropagation();
                                            inputFilesRef.current?.click();
                                        }}
                                        className='btn btn-success'
                                    >
                                        Add
                                    </button>
                                    <input
                                        ref={inputFilesRef}
                                        className={clsx("form-control")}
                                        type="file"
                                        id="formFiles"
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                            if (e.target.files) {
                                                addFilesToList(e.target.files, files);
                                                e.target.files = null;
                                                e.target.value = '';
                                            }
                                        }}
                                        multiple
                                        hidden
                                    />
                                </div>
                                {files.length > 0 && (
                                    <div className={clsx(styles.tableWrapper)}>
                                        <table className={clsx('table table-hover', styles.table)}>
                                            <thead className={clsx(styles.tableHead)}>
                                                <tr>
                                                    <th>Name</th>
                                                    <th>Size</th>
                                                    <th className={clsx(styles.lastCol)}></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {files.map((f, i) => (
                                                    <tr>
                                                        <td>{f.name}</td>
                                                        <td>{byteConverter(f.size)}</td>
                                                        <td>
                                                            <img
                                                                src={cancelIcon}
                                                                alt=""
                                                                style={{ width: 16, height: 16, cursor: 'pointer' }}
                                                                onClick={(e: React.MouseEvent<HTMLElement>) => {
                                                                    e.stopPropagation();
                                                                    removeFilesFormList(i, files);
                                                                }}
                                                            />
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                )}
                                {!isValidFiles && <p className={styles.missingFile}>*Missing file(s)!</p>}
                            </div>
                            <div className="form-group mt-3">
                                <label className={clsx('form-label', styles.label)}>
                                    Commit message
                                </label>
                                <textarea
                                    className="form-control"
                                    rows={5}
                                    value={message}
                                    onChange={(
                                        e: React.ChangeEvent<HTMLTextAreaElement>
                                    ) => {
                                        setMessage(e.target.value);
                                    }}
                                />
                            </div>
                            <button
                                type="submit"
                                className="btn btn-success w-100 mt-3"
                                disabled={isLoading}
                                onClick={(e: React.MouseEvent) => {
                                    e.preventDefault();
                                    handleUploadFiles(project.id, path, files, message);
                                }}>
                                {isLoading ? 'Uploading...' : 'Upload'}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default UploadFileButton;
