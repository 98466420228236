import { pencilIcon } from '../../../assets/icons'
import clsx from 'clsx'
import styles from "../dropdown-menu.module.css"

export type RenameButtonProps = {
    setRename: (rename: boolean) => void;
    index: string;
    setSelectedIndex: any;
}

const RenameButton = ({
    setRename,
    index,
    setSelectedIndex,
}: RenameButtonProps) => {
    return (
        <li>
            <a className="dropdown-item d-flex align-items-center" href="#" onClick={() => {
                setSelectedIndex(index);
                setRename(true);
            }} key={index}>
                <img src={pencilIcon} alt="" className={clsx(styles.icon, "me-2")} />
                Rename
            </a>
        </li>
    )
}

export default RenameButton