import clsx from 'clsx';
import React, { useCallback, useRef, useState } from 'react';
import styles from './create-project-modal.module.css';
import { debounce } from 'lodash';
import { IUser } from '../../types/user.interface';
import { IMultiItemResponseData } from '../../types/response-data.interface';
import axiosService, { axiosErrorHandler } from '../../services/axios.service';
import axios from 'axios';
import { IProject } from '../../types/project.interface';
import Swal from 'sweetalert2';
import AsyncSelect from 'react-select/async';
import { OnChangeValue } from 'react-select';
import { useNavigate } from 'react-router-dom';

const projectNameRegex = /^[a-zA-Z0-9_ ]*$/;

function CreateProjectModal({ id, onCreateSuccess }: { id: string; onCreateSuccess: Function }) {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [name, setName] = useState('');
    const [isValidName, setIsValidName] = useState(true);
    const [inValidNameMessage, setInValidNameMessage] = useState('');
    const [description, setDescription] = useState('');
    const [initAdmin, setInitAdmin] = useState<{ value: string; label: string } | null>(null);
    const [status, setStatus] = useState(1);
    const [startDate, setStartDate] = useState<string>('');
    const [endDate, setEndDate] = useState<string>('');
    const [isValidEndDate, setIsValidEndDate] = useState(true);
    const [inValidEndDateMessage, setInValidEndDateMessage] = useState('');
    const [location, setLocation] = useState('');

    const closeButtonRef = useRef(null);

    const resetForm = useCallback(() => {
        setName('');
        setDescription('');
        setInitAdmin(null);
        setStatus(1);
        setStartDate('');
        setEndDate('');
        setLocation('');
    }, []);

    const resetValidate = useCallback(() => {
        setIsValidName(true);
        setIsValidEndDate(true);
    }, []);

    const validateForm = useCallback(
        (name: string, startDate: string | undefined, endDate: string | undefined): boolean => {
            resetValidate();
            let isValid = true;
            if (name.length === 0) {
                setIsValidName(false);
                setInValidNameMessage('Please choose a project name');
                isValid = false;
            } else if (name && name.length > 0 && !projectNameRegex.test(name)) {
                setIsValidName(false);
                setInValidNameMessage(
                    'Project names must only contain letters, numbers, underscores and space'
                );
                isValid = false;
            }

            if (startDate && endDate && startDate > endDate) {
                setIsValidEndDate(false);
                setInValidEndDateMessage('End date must greater than or equal start date');
                isValid = false;
            }
            return isValid;
        },
        [resetValidate]
    );

    const createNewProject = useCallback(
        async (
            name: string,
            description: string,
            initAdmin: { value: string; label: string } | null,
            status: number,
            startDate: string,
            endDate: string,
            location: string
        ) => {
            try {
                setIsLoading(true);
                const { data }: { data: IProject } = (
                    await axiosService.post('projects', {
                        name,
                        description,
                        admins: initAdmin ? [initAdmin.value] : [],
                        status,
                        ...(startDate && { startDate }),
                        ...(endDate && { endDate }),
                        ...(location && { location })
                    })
                ).data;
                resetForm();
                (closeButtonRef.current as any).click();
                Swal.fire({
                    title: 'Create project successfully',
                    text: 'Go to project?',
                    icon: 'success',
                    showConfirmButton: true,
                    confirmButtonText: 'Ok',
                    showCancelButton: true,
                    cancelButtonText: 'Stay here',
                    reverseButtons: true
                }).then((result) => {
                    if (result.isConfirmed) {
                        navigate(`/projects/${data.id}`);
                    } else if (result.isDismissed) {
                        onCreateSuccess();
                    }
                });
            } catch (error) {
                if (axios.isAxiosError(error)) {
                    axiosErrorHandler(error);
                } else {
                    console.log('error:  ', error);
                }
            } finally {
                setIsLoading(false);
            }
        },
        [resetForm, navigate]
    );

    const handleCreateProject = useCallback(
        async (
            name: string,
            description: string,
            initAdmin: { value: string; label: string } | null,
            status: number,
            startDate: string,
            endDate: string,
            location: string
        ) => {
            if (validateForm(name, startDate, endDate)) {
                await createNewProject(
                    name,
                    description,
                    initAdmin,
                    status,
                    startDate,
                    endDate,
                    location
                );
            }
        },
        [createNewProject, validateForm]
    );

    const fetchUsers = useCallback(async (value: string): Promise<IUser[]> => {
        try {
            const { data }: { data: IMultiItemResponseData<IUser> } = (
                await axiosService.get(`/users?fields=username email&q=${value}&limit=10`)
            ).data;
            const { items } = data;
            return items;
        } catch (error) {
            if (axios.isAxiosError(error)) {
                axiosErrorHandler(error);
            } else {
                console.log('error:  ', error);
            }
            return [];
        }
    }, []);

    const convertIUserToOptions = useCallback((users: IUser[]) => {
        return users.map((u) => {
            return {
                value: u.id,
                label: u.username
            };
        });
    }, []);

    const loadOptions = debounce((text, callback) => {
        fetchUsers(text).then((result) => {
            callback(convertIUserToOptions(result));
        });
    }, 500);

    return (
        <div
            className={clsx('modal modal-xl fade')}
            id={id}
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            tabIndex={-1}
            aria-labelledby="staticBackdropLabel"
            aria-hidden="true">
            <div className={clsx('modal-dialog')}>
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="staticBackdropLabel">
                            Create new project
                        </h5>
                        <button
                            type="button"
                            className={clsx('btn-close')}
                            data-bs-dismiss="modal"
                            aria-label="Close"
                            ref={closeButtonRef}></button>
                    </div>
                    <div className="modal-body">
                        <div className={clsx(styles.form)}>
                            <div className="row">
                                <div className="col-12 col-lg-6">
                                    <div className="form-group">
                                        <label
                                            className={clsx('form-label required', styles.label)}>
                                            Name
                                        </label>
                                        <input
                                            type="text"
                                            className={clsx(
                                                'form-control',
                                                !isValidName && 'is-invalid'
                                            )}
                                            value={name}
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                setName(e.target.value);
                                            }}
                                        />
                                        <div
                                            id="validationProjectName"
                                            className="invalid-feedback">
                                            {inValidNameMessage}
                                        </div>
                                    </div>

                                    <div className="form-group mt-3">
                                        <label className={clsx('form-label', styles.label)}>
                                            Description
                                        </label>
                                        <textarea
                                            className="form-control"
                                            rows={5}
                                            value={description}
                                            onChange={(
                                                e: React.ChangeEvent<HTMLTextAreaElement>
                                            ) => {
                                                setDescription(e.target.value);
                                            }}
                                        />
                                    </div>

                                    <div className=" form-group mt-3">
                                        <label className={clsx('form-check-label', styles.label)}>
                                            Select first project admin:
                                        </label>
                                        <AsyncSelect
                                            className={clsx(styles.selectAdmin)}
                                            value={initAdmin}
                                            isClearable={true}
                                            cacheOptions
                                            loadOptions={loadOptions}
                                            onChange={(
                                                selectedOptions: OnChangeValue<
                                                    { value: string; label: string },
                                                    false
                                                >
                                            ) => {
                                                setInitAdmin(selectedOptions);
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="col-12 col-lg-6">
                                    <div className="form-group mt-3 mt-lg-0">
                                        <label
                                            className={clsx('form-label required', styles.label)}>
                                            Status
                                        </label>
                                        <select
                                            className="form-select"
                                            aria-label="Default select example"
                                            value={status}
                                            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                                                e.stopPropagation();
                                                setStatus(parseInt(e.target.value));
                                            }}>
                                            <option value={1}>Initialization</option>
                                            <option value={2}>In Progress</option>
                                            <option value={3}>Completed</option>
                                            <option value={4}>On Hold</option>
                                        </select>
                                    </div>

                                    <div className="form-group mt-3">
                                        <label className={clsx('form-label', styles.label)}>
                                            Start date
                                        </label>
                                        <input
                                            type="date"
                                            className={clsx('form-control')}
                                            value={startDate || ''}
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                setStartDate(e.target.value);
                                            }}
                                        />
                                    </div>

                                    <div className="form-group mt-3">
                                        <label className={clsx('form-label', styles.label)}>
                                            End date
                                        </label>
                                        <input
                                            type="date"
                                            className={clsx(
                                                'form-control',
                                                !isValidEndDate && 'is-invalid'
                                            )}
                                            value={endDate || ''}
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                setEndDate(e.target.value);
                                            }}
                                        />
                                        <div id="validationEndDate" className="invalid-feedback">
                                            {inValidEndDateMessage}
                                        </div>
                                    </div>

                                    <div className="form-group mt-3">
                                        <label className={clsx('form-label', styles.label)}>
                                            Location
                                        </label>
                                        <input
                                            type="text"
                                            className={clsx('form-control')}
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                setLocation(e.currentTarget.value);
                                            }}
                                            value={location}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="w-100 pt-3">
                                <button
                                    type="submit"
                                    className="btn btn-success float-end"
                                    disabled={isLoading}
                                    onClick={(e: React.MouseEvent) => {
                                        e.preventDefault();
                                        handleCreateProject(
                                            name,
                                            description,
                                            initAdmin,
                                            status,
                                            startDate,
                                            endDate,
                                            location
                                        );
                                    }}>
                                    {isLoading ? 'Creating...' : 'Create'}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CreateProjectModal;
