import { useCallback, useEffect, useState } from 'react';
import { IEmail } from '../../types/email.interface';
import axiosService, { axiosErrorHandler } from '../../services/axios.service';
import Swal from 'sweetalert2';
import axios from 'axios';
import clsx from 'clsx';
import styles from './email-table.module.css';
import { deleteIcon, editIcon, messageExchangeIcon } from '../../assets/icons';
import UpdateEmailModal from '../update-email-modal/update-email-modal';
import EmailStatus from '../email-status/email-status';
import { Tooltip } from 'react-tooltip';

function EmailTable({
    emails,
    setEmails,
    isLoading,
    onDeleteEmail
}: {
    emails: IEmail[];
    setEmails: Function;
    isLoading: boolean;
    onDeleteEmail: () => void;
}) {
    const [firstOpened, setFirstOpened] = useState<boolean[]>(emails.map(() => false));
    const openEdit = useCallback(
        (i: number) => {
            firstOpened[i] = true;
            setFirstOpened([...firstOpened]);
        },
        [firstOpened]
    );
    useEffect(() => {
        setFirstOpened(emails.map(() => false));
    }, [emails]);

    const deleteEmail = async (emailId: string) => {
        try {
            await axiosService.delete(`emails/${emailId}`);
            Swal.fire({
                icon: 'success',
                title: 'Delete mailer success',
                showConfirmButton: true,
                confirmButtonText: 'Ok'
            }).then(() => {
                onDeleteEmail();
            });
        } catch (error) {
            if (axios.isAxiosError(error)) {
                axiosErrorHandler(error);
            } else {
                console.log('error:  ', error);
            }
        }
    };

    const handleDeleteEmail = (email: IEmail) => {
        Swal.fire({
            icon: 'question',
            title: `Delete mailer '${email.email}'`,
            text: 'Are you sure you want to delete this mailer?',
            showConfirmButton: true,
            showCancelButton: true,
            reverseButtons: true,
            showLoaderOnConfirm: true,
            preConfirm: async () => {
                await deleteEmail(email.id);
            }
        });
    };

    const onUpdateSuccess = useCallback(
        async (email: IEmail) => {
            const updatedList = [...emails].map((e) => {
                if (e.id === email.id) {
                    return email;
                }
                return e;
            });
            setEmails([...updatedList]);
        },
        [emails, setEmails]
    );

    const testSendMail = useCallback(async (emailId: string) => {
        try {
            const { data } = (await axiosService.get(`emails/test/${emailId}`)).data;
            return data;
        } catch (error) {
            if (axios.isAxiosError(error)) {
                axiosErrorHandler(error);
            } else {
                console.log('error:  ', error);
            }
            return undefined;
        }
    }, []);

    const handleTestSendEmail = useCallback(async (email: IEmail) => {
        Swal.fire({
            text: `Trying to send an email with ${email.email}`,
            allowOutsideClick: false,
            allowEscapeKey: false,
            didOpen: () => {
                Swal.showLoading();
            }
        });
        const ans = await testSendMail(email.id);
        if (ans) {
            if (ans.status === 'success') {
                Swal.fire({
                    title: 'Success',
                    icon: 'success'
                });
            } else {
                Swal.fire({
                    title: 'Fail',
                    icon: 'error',
                    text: JSON.stringify(ans.error, null, 4)
                });
            }
        } else {
            Swal.close();
        }
    }, []);

    return (
        <table className={clsx(styles.table)}>
            <thead className={clsx(styles.tableHead)}>
                <tr>
                    <th className={clsx(styles.firstCol)}>Server Name</th>
                    <th>Status</th>
                    <th>Email</th>
                    <th>Host</th>
                    <th>Port</th>
                    <th className={clsx(styles.lastCol)}>Action</th>
                </tr>
            </thead>
            <tbody className={clsx()}>
                {isLoading ? (
                    <></>
                ) : (
                    emails.map((email, index) => (
                        <tr key={email.id} className={clsx(styles.tableRow)}>
                            <td className={clsx(styles.firstCol)}>{email.serverName}</td>
                            <td>
                                <EmailStatus statusId={email.status} />
                            </td>
                            <td>{email.email}</td>
                            <td>{email.host}</td>
                            <td>{email.port}</td>
                            <td>
                                <Tooltip
                                    className={clsx(styles.tooltip)}
                                    anchorSelect="#testSendMail">
                                    Try to send a mail
                                </Tooltip>
                                <img
                                    id="testSendMail"
                                    src={messageExchangeIcon}
                                    alt=""
                                    style={{ width: 20, height: 20, cursor: 'pointer' }}
                                    className="me-2"
                                    onClick={(e: React.MouseEvent<HTMLElement>) => {
                                        e.stopPropagation();
                                        handleTestSendEmail(email);
                                    }}
                                />
                                <img
                                    src={editIcon}
                                    alt=""
                                    style={{ width: 20, height: 20, cursor: 'pointer' }}
                                    className="me-2"
                                    onClick={(e: React.MouseEvent<HTMLElement>) => {
                                        e.stopPropagation();
                                        openEdit(index);
                                    }}
                                    title="Update mailer info"
                                    data-bs-toggle="modal"
                                    data-bs-target={`#updateEmail${email.id}`}
                                />
                                <UpdateEmailModal
                                    id={`updateEmail${email.id}`}
                                    email={email}
                                    onUpdateSuccess={onUpdateSuccess}
                                />
                                <img
                                    src={deleteIcon}
                                    alt=""
                                    style={{ width: 20, height: 20, cursor: 'pointer' }}
                                    onClick={(e: React.MouseEvent<HTMLElement>) => {
                                        e.stopPropagation();
                                        handleDeleteEmail(email);
                                    }}
                                    title="Delete mailer"
                                />
                            </td>
                        </tr>
                    ))
                )}
            </tbody>
        </table>
    );
}

export default EmailTable;
