import React from 'react';
import { Link } from 'react-router-dom';

function NotFoundPage() {
    return (
        <div className="d-flex justify-content-center align-align-items-center h-100">
            <div className="d-flex flex-column justify-content-center align-items-center">
                <p style={{ fontSize: '10rem', fontWeight: 700, color: '#00000060', margin: 0 }}>
                    404
                </p>
                <p style={{ fontSize: '2rem', fontWeight: 700, color: '#00000060' }}>
                    Page not found!
                </p>
                <Link
                    to={'/'}
                    style={{
                        border: '1px solid #00000030',
                        color: '#000000aa',
                        textDecoration: 'none',
                        padding: '10px 20px',
                        borderRadius: '99px',
                        marginBottom: '20vh'
                    }}>
                    Back to home page
                </Link>
            </div>
        </div>
    );
}

export default NotFoundPage;
