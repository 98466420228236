import React, { useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import styles from './search-bar.module.css';
import { findIcon } from '../../assets/icons';
import { debounce } from 'lodash';

function SearchBar<T>({
    placeholder,
    loadOptions,
    delayTime,
    handleDisplayOption,
    filterBeforeDisplay,
    onSelectOption,
    cacheOptions = false
}: {
    placeholder: string;
    loadOptions: (inputText: string) => Promise<T[]>;
    delayTime: number;
    handleDisplayOption: (option: T) => string;
    filterBeforeDisplay: (option: T) => boolean;
    onSelectOption: (option: T) => void;
    cacheOptions?: boolean;
}) {
    const [isShowResult, setIsShowResult] = useState(false);
    // cache loaded option
    const cache = useRef<{ [key: string]: T[] }>({});

    // handle click inside and outside
    const searchBarRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
        function handleClickOutside(event: any): void {
            if (searchBarRef.current && !searchBarRef.current.contains(event.target)) {
                // console.log('outside');
                setIsShowResult(false);
            } else {
                // console.log('inside');
                setIsShowResult(true);
            }
        }
        // Bind the event listener
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [searchBarRef]);

    // handle load options
    const [isLoading, setIsLoading] = useState(false);
    const [result, setResult] = useState<T[]>([]);
    const handleLoadOptions = debounce(async (inputText: string) => {
        if (inputText.length === 0) {
            setResult([]);
        } else {
            if (cacheOptions && cache.current[inputText]) {
                setResult(cache.current[inputText]);
            } else {
                setIsLoading(true);
                const res = await loadOptions(inputText);
                setIsLoading(false);
                setResult(res);
                if (cacheOptions) {
                    cache.current[inputText] = res;
                }
            }
        }
    }, delayTime);

    return (
        <div ref={searchBarRef} className={clsx(styles.searchBar)}>
            <input
                type="text"
                placeholder={placeholder}
                className={clsx(styles.input, 'form-control')}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    handleLoadOptions(e.target.value);
                }}
            />
            <img src={findIcon} alt="" className={clsx(styles.icon)} />
            <div className={clsx(styles.result, 'shadow', isShowResult ? 'd-block' : 'd-none')}>
                <ul className={clsx(styles.options)}>
                    {isLoading ? (
                        <div className={clsx(styles.loading)}>
                            <span
                                className="spinner-border spinner-border-sm text-secondary"
                                role="status"
                                aria-hidden="true"></span>
                        </div>
                    ) : result.filter((r) => filterBeforeDisplay(r)).length > 0 ? (
                        result
                            .filter((r) => filterBeforeDisplay(r))
                            .map((r, i) => (
                                <li
                                    key={i}
                                    className={clsx(styles.option)}
                                    onClick={(e: React.MouseEvent<HTMLElement>) => {
                                        e.stopPropagation();
                                        onSelectOption(r);
                                        setIsShowResult(false);
                                    }}>
                                    <p className={clsx(styles.option_text)}>
                                        {handleDisplayOption(r)}
                                    </p>
                                </li>
                            ))
                    ) : (
                        <div className={clsx(styles.noContent)}>
                            <p className={clsx(styles.noContent_text)}>no result</p>
                        </div>
                    )}
                </ul>
            </div>
        </div>
    );
}

export default SearchBar;
