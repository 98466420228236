import React from 'react';
import { IUser } from '../../types/user.interface';
import { editIcon, groupIcon, user3Icon } from '../../assets/icons';
import clsx from 'clsx';
import styles from './group-member.module.css';
import { IGroup } from '../../types/group.interface';
import UpdateGroupModal from '../update-group-modal/update-group-modal';

function GroupMember({
    role,
    group,
    onUpdateSuccess
}: {
    role: number;
    group: IGroup;
    onUpdateSuccess?: Function;
}) {
    return (
        <div>
            <div className="d-flex justify-content-start align-items-center mb-2">
                <img
                    className="me-2"
                    src={groupIcon}
                    alt=""
                    style={{
                        width: 18,
                        height: 18
                    }}
                />
                <p className={clsx('m-0', styles.groupName)}>{group.name}</p>
                {role >= 1 && (
                    <>
                        <img
                            className="ms-2"
                            src={editIcon}
                            alt=""
                            style={{
                                width: 14,
                                height: 14,
                                cursor: 'pointer'
                            }}
                            data-bs-toggle="modal"
                            data-bs-target={`#updateGroup${group.id}`}
                        />
                        <UpdateGroupModal
                            id={`updateGroup${group.id}`}
                            group={group}
                            onUpdateSuccess={onUpdateSuccess}
                        />
                    </>
                )}
            </div>
            {group.members.map((m) => (
                <div
                    className={clsx(
                        styles.username,
                        'd-flex justify-content-start align-items-center mb-2'
                    )}>
                    <img
                        className="me-2"
                        src={user3Icon}
                        alt=""
                        style={{
                            width: 15,
                            height: 15
                        }}
                    />
                    <p className={clsx('m-0')}>{(m as IUser).username}</p>
                </div>
            ))}
        </div>
    );
}

export default GroupMember;
