import React from 'react';
import clsx from 'clsx';
import styles from './popup-tree.module.css';
import { IOpenCloud } from '../../lib/open-cloud.interface';
import { TreeNodeType } from '../..';
import { getTreeAssemblies } from '../../../../services/assemblies/get-tree-assemblies';
import { ITreeAssemblies } from '../../../../types/open-design.interface';
import { IndexedDB } from '../../../../services/indexed-db';
import { EIndexedDB, EStores } from '../../../../services/indexed-db/types';

type PopupTreePropTypes = {
    isAssemblies: boolean;
    setTree: (tree: TreeNodeType[]) => void;
    tree: TreeNodeType[];
    openCloud: IOpenCloud | null;
};

function collectHighlightHandles(node: ITreeAssemblies, handles: string[]) {
    handles.push(node.handle);
    node.children.forEach((x) => collectHighlightHandles(x, handles));
}

const PopupTree: React.FC<PopupTreePropTypes> = ({ isAssemblies, setTree, tree, openCloud }) => {
    async function selectNodeTree(item: TreeNodeType, index: number) {
        if (isAssemblies) {
            const handles: { [key: string]: string[] } = {};
            const indexed = new IndexedDB<ITreeAssemblies[]>(
                EIndexedDB.DB_NAME,
                EStores.TREE_ASSEMBLIES
            );
            await indexed.initial();

            let res: ITreeAssemblies[];
            const trees = await indexed.get(item.id);
            if (!trees?.value) {
                res = await getTreeAssemblies(item.id);
                indexed.add(res, item.id);
            } else res = trees.value;

            let isChoose = false;
            for (const node of res) {
                isChoose = false;
                if (node.handle === `${index}_0`) {
                    isChoose = true;
                }
                if (isChoose) {
                    if (!handles[`${index}_0`]) handles[`${index}_0`] = [];
                    collectHighlightHandles(node, handles[`${index}_0`]);
                }
            }

            openCloud?.setSelected(handles[`${index}_0`]);
            openCloud?.zoomToSelected();
        } else {
            openCloud?.zoomToExtents(false, true);
        }
    }
    return (
        <div className={clsx(styles.PopupTreeContainer)}>
            <h5>Object Explorer</h5>
            <div className={clsx(styles.PopupTreeContent)}>
                {tree.map((item, index) => (
                    <p key={index} onClick={() => selectNodeTree(item, index)}>
                        {item.name}
                    </p>
                ))}
            </div>
        </div>
    );
};

export default PopupTree;
