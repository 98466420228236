import clsx from 'clsx';
import React, { useCallback, useState } from 'react'
import { Tooltip } from 'react-tooltip';
import styles from './copy-checkout-url-button.module.css';
import { copyLinkIcon } from '../../assets/icons';
import { IProject } from '../../types/project.interface';

function CopyCheckoutUrlButton({
    project,
    path,
}: {
    project: IProject,
    path: string,
}) {
    const [copyIconText, setCopyIconText] = useState('Copy checkout url');

    const handleCopyCheckoutUrl = useCallback((projectName: string, path: string) => {
        navigator.clipboard.writeText(`${process.env.REACT_APP_SVN_URL}/${projectName}${path}`);
        setCopyIconText('Copied!');
        setTimeout(() => {
            setCopyIconText('Copy checkout url');
        }, 3000);
    }, []);

    return (
        <>
            <Tooltip
                className={clsx(styles.tooltip)}
                anchorSelect="#copyCheckoutUrlIcon">
                {copyIconText}
            </Tooltip>
            <img
                id="copyCheckoutUrlIcon"
                className="me-2"
                src={copyLinkIcon}
                alt=""
                style={{ width: 25, height: 25, cursor: 'pointer' }}
                onClick={(e: React.MouseEvent) => {
                    e.stopPropagation();
                    handleCopyCheckoutUrl(project.name, path);
                }}
            />
        </>
    )
}

export default CopyCheckoutUrlButton