import React, { useEffect } from 'react';
import Header from './header';
import { useLocation } from 'react-router-dom';
import clsx from 'clsx';
import styles from './index.module.css';
import Sidebar from './sidebar';

const appName = 'ADSCivil CDE';
const titles: { [key: string]: string } = {
    '/': appName,
    '/login': `Login - ${appName}`
};

function AuthenicatedLayout({ children }: { children: React.ReactNode }) {
    const location = useLocation();
    useEffect(() => {
        document.title = titles[location.pathname] ?? appName;
    }, [location]);
    return (
        <div className={clsx(styles.layoutWrapper)}>
            <Header />
            <div className={clsx(styles.body)}>
                <div className={clsx(styles.sidebar)} style={{ zIndex: 100 }}>
                    <div className={clsx(styles.sidebarSticky)}>
                        <Sidebar />
                    </div>
                </div>

                <div className={clsx(styles.mainContent)}>{children}</div>
            </div>
        </div>
    );
}

export default AuthenicatedLayout;
