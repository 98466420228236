import clsx from 'clsx';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { EFifoKind, IFifo, IFolderTreeElement } from '../../types/fifo.interface';
import { useAuth } from '../../providers/auth.provider';
import { EUserRole, ILoggedInUser } from '../../types/login.interface';
import styles from './browser-table.module.css';
import { ETreeReducerActionType } from '../../reducers/tree.reducer';
import { getParentPath } from '../../utils/path-helper.util';
import { folderIcon, documentIcon, viewFile1Icon } from '../../assets/icons';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { IProject } from '../../types/project.interface';
import ManagePermission from './manage-permission';
import MyPermission from './my-permission';
import { Tooltip } from 'react-tooltip';
import { InfoSelectedFileType } from '../browser-tab-content/data-view';
import FolderDropdownMenu from '../dropdown-menu/folder-dropdown-menu/folder-dropdown-menu';
import FileDropdownMenu from '../dropdown-menu/file-dropdown-menu/file-dropdown-menu';
import RenameInput from '../dropdown-menu/rename/input';
dayjs.extend(relativeTime);

function BrowserTable({
    project,
    files,
    isLoading,
    path,
    dispatch,
    fetchChildren,
    setSearchParams,
    selectedPaths,
    setSelectedPaths,
    selectedModelViewer,
    selectedInfoFilesHandle,
    selectedInfoFiles,
    setSelectedInfoFiles,
    rename,
    setRename,
    selectedIndex,
    setSelectedIndex,
    projectId
}: {
    project: IProject;
    files: IFifo[];
    isLoading: boolean;
    path: string;
    dispatch: React.Dispatch<any>;
    fetchChildren: (path: string) => Promise<boolean>;
    setSearchParams: Function;
    selectedPaths: string[];
    setSelectedPaths: Function;
    selectedModelViewer: (projectId: string, path: string, revision: any) => void;
    selectedInfoFilesHandle: (data: InfoSelectedFileType) => void;
    setSelectedInfoFiles: (files: InfoSelectedFileType[]) => void;
    selectedInfoFiles: InfoSelectedFileType[];
    rename: boolean;
    setRename: (rename: boolean) => void;
    selectedIndex: any;
    setSelectedIndex: any;
    projectId: string;
}) {
    const { user }: { user: ILoggedInUser } = useAuth();
    const [loading, setLoading] = useState(false);

    const role = useRef(
        user.role === EUserRole.SYSTEM_ADMIN ? 2 : project.admins.some((a) => a === user.id) ? 1 : 0
    );

    const handleSelectFolder = async (f: IFolderTreeElement) => {
        // when children haven't been fetched => fetch
        if (!f.children && !loading) {
            setLoading(true);
            await fetchChildren(`${path}${f.name}/`);
            setLoading(false);
        }
        dispatch({
            type: ETreeReducerActionType.ON_SELECT_FOLDER,
            payload: { path: `${path}${f.name}/` }
        });
        setSearchParams({ path: `${path}${f.name}/` });
    };

    const handleShowParentFolder = () => {
        const parentPath = getParentPath(path);
        setSearchParams({ path: parentPath });
        dispatch({
            type: ETreeReducerActionType.ON_SELECT_FOLDER,
            payload: { path: parentPath }
        });
    };

    const handleSelectFile = async () => {
        // TODO
    };

    const handleClickCheckBox = useCallback(
        (path: string, revision: number | undefined) => {
            selectedInfoFilesHandle({ path: path, revision: revision });
            if (selectedPaths.includes(path)) {
                setSelectedPaths(selectedPaths.filter((p) => p !== path));
            } else {
                setSelectedPaths([...selectedPaths, path]);
            }
        },
        [selectedPaths, setSelectedPaths]
    );

    const handleClickSelectAll = useCallback(
        (path: string, files: IFifo[]) => {
            if (selectedPaths.length !== files.length) {
                setSelectedPaths(
                    files.map((f) => {
                        return `${path}${f.name}${f.kind === EFifoKind.DIR ? '/' : ''}`;
                    })
                );
                setSelectedInfoFiles(
                    files.map((f) => ({
                        path: `${path}${f.name}${f.kind === EFifoKind.DIR ? '/' : ''}`,
                        revision: f.commit?.revision
                    }))
                );
            } else {
                setSelectedPaths([]);
                setSelectedInfoFiles([]);
            }
        },
        [selectedPaths, setSelectedPaths]
    );

    return (
        <table className={clsx('table table-hover', styles.table)}>
            <thead className={clsx(styles.tableHead)}>
                <tr>
                    <th className={clsx(styles.firstCol)}>
                        {files.length !== 0 && (
                            <input
                                type="checkbox"
                                name=""
                                id=""
                                checked={selectedPaths.length === files.length}
                                onChange={() => { }}
                                onClick={(e: React.MouseEvent) => {
                                    e.stopPropagation();
                                    handleClickSelectAll(path, files);
                                }}
                            />
                        )}
                    </th>
                    <th>Name</th>
                    <th>Status</th>
                    <th>Revision</th>
                    <th>Author</th>
                    <th>Last commit date</th>
                    <th className={clsx(styles.lastCol)}></th>
                </tr>
            </thead>
            <tbody className={clsx(styles.tableBody)}>
                {isLoading ? (
                    <></>
                ) : (
                    <>
                        {path !== '/' && (
                            <tr>
                                <td className={clsx(styles.firstCol)}></td>
                                <td>
                                    <div className="d-flex align-items-center">
                                        <img
                                            src={folderIcon}
                                            alt=""
                                            style={{ width: 16, height: 16 }}
                                        />
                                        <p
                                            className={clsx('m-0 ms-2', styles.theLink)}
                                            onClick={(e: React.MouseEvent<HTMLElement>) => {
                                                e.stopPropagation();
                                                handleShowParentFolder();
                                            }}>
                                            ..
                                        </p>
                                    </div>
                                </td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                        )}
                        {files.map((f, i) => (
                            <tr key={i}>
                                <td className={clsx(styles.firstCol)}>
                                    <input
                                        type="checkbox"
                                        name=""
                                        id=""
                                        onChange={() => { }}
                                        checked={selectedPaths.includes(
                                            `${path}${f.name}${f.kind === EFifoKind.DIR ? '/' : ''}`
                                        )}
                                        onClick={(e: React.MouseEvent) => {
                                            e.stopPropagation();
                                            handleClickCheckBox(
                                                `${path}${f.name}${f.kind === EFifoKind.DIR ? '/' : ''}`,
                                                f.commit?.revision
                                            );
                                        }}
                                    />
                                </td>
                                <td >
                                    <div className={clsx('d-flex align-items-center justify-content-between')}>
                                        <div className="d-flex align-items-center">
                                            {f.kind === EFifoKind.DIR ? (
                                                <img
                                                    src={folderIcon}
                                                    alt=""
                                                    style={{ width: 16, height: 16 }}
                                                />
                                            ) : (
                                                <img
                                                    src={documentIcon}
                                                    alt=""
                                                    style={{ width: 16, height: 16 }}
                                                />
                                            )}

                                            {rename && selectedIndex === `${f.name}_${i}_${f.commit?.revision}` ? (
                                                <RenameInput
                                                    value={f.kind === EFifoKind.DIR ? f.name : f.name.split('.').slice(0, -1).join('.')}
                                                    setRename={setRename}
                                                    path={`${path}${f.name}${f.kind === EFifoKind.DIR ? '/' : ''}`}
                                                    element={f}
                                                    projectId={projectId}
                                                />
                                            ) : (
                                                <p
                                                    className={clsx(styles.theLink, 'm-0 ms-2')}
                                                    onClick={(e: React.MouseEvent<HTMLElement>) => {
                                                        e.stopPropagation();
                                                        f.kind === EFifoKind.DIR
                                                            ? handleSelectFolder(f)
                                                            : handleSelectFile();
                                                    }}>
                                                    {f.name}
                                                </p>
                                            )}

                                        </div>

                                        <div>
                                            {f.kind === EFifoKind.DIR ? (
                                                <FolderDropdownMenu
                                                    setRename={setRename}
                                                    index={`${f.name}_${i}_${f.commit?.revision}`}
                                                    setSelectedIndex={setSelectedIndex}
                                                />
                                            ) : (
                                                <FileDropdownMenu
                                                    setRename={setRename}
                                                    index={`${f.name}_${i}_${f.commit?.revision}`}
                                                    setSelectedIndex={setSelectedIndex}
                                                />
                                            )}
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <p className="m-0">
                                        {path.startsWith('/Shared/')
                                            ? 'S1'
                                            : path.startsWith('/WIP/')
                                                ? 'S0'
                                                : path.startsWith('/Published/')
                                                    ? 'A1'
                                                    : ''}
                                    </p>
                                </td>
                                <td>
                                    <p className="m-0">{f.commit && `${f.commit.revision}`}</p>
                                </td>
                                <td>
                                    <p className="m-0">{f.commit && `${f.commit.author}`}</p>
                                </td>
                                <td>
                                    <p className="m-0">
                                        {f.commit && dayjs(f.commit.date).fromNow().toString()}
                                    </p>
                                </td>
                                {role.current >= 1 ? (
                                    <td className="d-flex align-items-center">
                                        {/* {f.kind !== EFifoKind.DIR ? (
                                            <>
                                                {!!f.enableView ? (
                                                    <div className="me-2">
                                                        <Tooltip
                                                            anchorSelect="#viewFile"
                                                            className={clsx(styles.tooltip)}>
                                                            View file
                                                        </Tooltip>
                                                        <img
                                                            onClick={() =>
                                                                selectedModelViewer(
                                                                    project.id,
                                                                    `${path}${f.name}${f.kind === EFifoKind.DIR ? '/' : ''}`,
                                                                    f.commit?.revision
                                                                )
                                                            }
                                                            id="viewFile"
                                                            src={viewFile1Icon}
                                                            alt="view"
                                                            style={{
                                                                width: 16,
                                                                height: 16,
                                                                cursor: 'pointer'
                                                            }}
                                                        />
                                                    </div>
                                                ) : (
                                                    <div className="me-2">
                                                        <Tooltip
                                                            anchorSelect="#notViewFile"
                                                            className={clsx(styles.tooltip)}>
                                                            Not Ready to view
                                                        </Tooltip>
                                                        <img
                                                            id="notViewFile"
                                                            src={viewFile1Icon}
                                                            alt="view"
                                                            style={{
                                                                width: 16,
                                                                height: 16,
                                                                cursor: 'pointer',
                                                                opacity: 0.5
                                                            }}
                                                        />
                                                    </div>
                                                )}
                                            </>
                                        ) : (
                                            <div style={{ width: '16px' }} className="me-2"></div>
                                        )} */}
                                        <ManagePermission
                                            projectId={project.id}
                                            path={`${path}${f.name}${f.kind === EFifoKind.DIR ? '/' : ''}`}
                                            iconSize={16}
                                        />
                                    </td>
                                ) : (
                                    <td>
                                        <MyPermission
                                            projectId={project.id}
                                            path={`${path}${f.name}${f.kind === EFifoKind.DIR ? '/' : ''}`}
                                            iconSize={16}
                                        />
                                    </td>
                                )}
                            </tr>
                        ))}
                    </>
                )}
            </tbody>
        </table>
    );
}

export default BrowserTable;
