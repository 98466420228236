import type { ILoggedInUser } from '../types/login.interface';
import { LocalStorageService } from './localStorage.service';

export const enum AUTH_SERVICE_KEY {
    ACCESS_TOKEN = 'ACCESS_TOKEN',
    REFRESH_TOKEN = 'REFRESH_TOKEN',
    USER = 'USER',
    ACCESS_TOKEN_EXPIRES_AT = 'ACCESS_TOKEN_EXPIRES_AT',
    REFRESH_TOKEN_EXPIRES_AT = 'REFRESH_TOKEN_EXPIRES_AT'
}

export class AuthStorageService {
    // ACCESS_TOKEN
    static setAccessToken(token: string): void {
        LocalStorageService.set(AUTH_SERVICE_KEY.ACCESS_TOKEN, token);
    }
    static getAccessToken(): string {
        return LocalStorageService.get(AUTH_SERVICE_KEY.ACCESS_TOKEN);
    }
    static resetAccessToken(): void {
        LocalStorageService.set(AUTH_SERVICE_KEY.ACCESS_TOKEN, '');
    }

    // ACCESS_TOKEN_EXPIRES_AT
    static setAccessTokenExpiresAt(unixTime: number): void {
        LocalStorageService.set(AUTH_SERVICE_KEY.ACCESS_TOKEN_EXPIRES_AT, unixTime.toString());
    }
    static getAccessTokenExpiresAt(): number {
        return parseInt(LocalStorageService.get(AUTH_SERVICE_KEY.ACCESS_TOKEN_EXPIRES_AT)) || 1;
    }
    static resetAccessTokenExpiresAt(): void {
        LocalStorageService.set(AUTH_SERVICE_KEY.ACCESS_TOKEN_EXPIRES_AT, '');
    }

    // REFRESH_TOKEN
    static setRefreshToken(token: string): void {
        LocalStorageService.set(AUTH_SERVICE_KEY.REFRESH_TOKEN, token);
    }
    static getRefreshToken(): string {
        return LocalStorageService.get(AUTH_SERVICE_KEY.REFRESH_TOKEN);
    }
    static resetRefreshToken(): void {
        LocalStorageService.set(AUTH_SERVICE_KEY.REFRESH_TOKEN, '');
    }

    // REFRESH_TOKEN_EXPIRES_AT
    static setRefreshTokenExpiresAt(unixTime: number): void {
        LocalStorageService.set(AUTH_SERVICE_KEY.REFRESH_TOKEN_EXPIRES_AT, unixTime.toString());
    }
    static getRefreshTokenExpiresAt(): number {
        return parseInt(LocalStorageService.get(AUTH_SERVICE_KEY.REFRESH_TOKEN_EXPIRES_AT)) || 1;
    }
    static resetRefreshTokenExpiresAt(): void {
        LocalStorageService.set(AUTH_SERVICE_KEY.REFRESH_TOKEN_EXPIRES_AT, '');
    }

    // LOGIN USER
    static setLoginUser(user: null | ILoggedInUser): void {
        if (!user) {
            LocalStorageService.set(AUTH_SERVICE_KEY.USER, '');
        } else {
            LocalStorageService.setObject(AUTH_SERVICE_KEY.USER, user);
        }
    }
    static getLoginUser(): ILoggedInUser {
        return LocalStorageService.getObject(AUTH_SERVICE_KEY.USER) as ILoggedInUser;
    }

    static resetAll(): void {
        // this.resetAccessToken();
        // this.resetRefreshToken();
        // this.resetAccessTokenExpiresAt();
        // this.resetRefreshTokenExpiresAt();
        this.setLoginUser(null);
    }
}
