import { Navigate, Outlet } from 'react-router-dom';
import { useAuth } from '../providers/auth.provider';
import AuthenicatedLayout from '../components/layouts/authenticated-layout';
import { ILoggedInUser } from '../types/login.interface';

export function AuthenticatedOnly() {
    const { user }: { user: ILoggedInUser } = useAuth();
    if (!user.id) {
        return <Navigate to="/login" />;
    }

    return (
        <AuthenicatedLayout>
            <Outlet />
        </AuthenicatedLayout>
    );
}
