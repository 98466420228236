import React from 'react';
import styles from './project-status.module.css';
import clsx from 'clsx';

const status = [
    {
        className: 'initialization',
        title: 'Initialization'
    },
    {
        className: 'inProgress',
        title: 'In Progress'
    },
    {
        className: 'completed',
        title: 'Completed'
    },
    {
        className: 'onHold',
        title: 'On Hold'
    }
];

function ProjectStatus({ statusId }: { statusId: number }) {
    return (
        <p className={clsx(styles[status[statusId - 1].className], styles.cell)}>
            {status[statusId - 1].title}
        </p>
    );
}

export default ProjectStatus;
