import React from 'react';
import clsx from 'clsx';
import { folderTreeIcon, timelineIcon } from '../../assets/icons';
import { IProject } from '../../types/project.interface';
import { Link } from 'react-router-dom';
import styles from './my-project-table.module.css';
import ProjectStatus from '../project-status/project-status';
import dayjs from 'dayjs';

function MyProjectTable({ projects, isLoading }: { projects: IProject[]; isLoading: boolean }) {
    return (
        <table className={clsx(styles.table)}>
            <thead className={clsx(styles.tableHead)}>
                <tr>
                    <th className={clsx(styles.firstCol)}>Name</th>
                    <th>Status</th>
                    <th>Start date</th>
                    <th>End date</th>
                    <th>Location</th>
                    <th className={clsx(styles.lastCol)}>Action</th>
                </tr>
            </thead>
            <tbody className={clsx()}>
                {isLoading ? (
                    <></>
                ) : (
                    projects.map((project, index) => (
                        <tr key={project.id} className={clsx(styles.tableRow)}>
                            <td className={clsx(styles.firstCol)}>
                                <Link to={`/projects/${project.id}`} className={clsx(styles.link)}>
                                    {project.name}
                                </Link>
                            </td>
                            <td>
                                <ProjectStatus statusId={project.status} />
                            </td>
                            <td>
                                {project.startDate && dayjs(project.startDate).format('DD/MM/YYYY')}
                            </td>
                            <td>
                                {project.endDate && dayjs(project.endDate).format('DD/MM/YYYY')}
                            </td>
                            <td>{project.location}</td>
                            <td>
                                <Link to={`/projects/${project.id}/browser?path=/`}>
                                    <img
                                        id="goToBrowser"
                                        className="me-2"
                                        src={folderTreeIcon}
                                        alt=""
                                        style={{ width: 20, height: 20, cursor: 'pointer' }}
                                        onClick={(e: React.MouseEvent<HTMLElement>) => {
                                            e.stopPropagation();
                                        }}
                                        title="Browser"
                                    />
                                </Link>
                                <Link to={`/projects/${project.id}/timeline`}>
                                    <img
                                        id="timeline"
                                        className="me-2"
                                        src={timelineIcon}
                                        alt=""
                                        style={{ width: 20, height: 20, cursor: 'pointer' }}
                                        onClick={(e: React.MouseEvent) => {
                                            e.stopPropagation();
                                        }}
                                        title="Timeline"
                                    />
                                </Link>
                            </td>
                        </tr>
                    ))
                )}
            </tbody>
        </table>
    );
}

export default MyProjectTable;
