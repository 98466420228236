import clsx from 'clsx';
import React, { useCallback } from 'react'
import { Tooltip } from 'react-tooltip';
import styles from './timeline-button.module.css';
import { timelineIcon } from '../../assets/icons';
import { useNavigate } from 'react-router-dom';

function TimelineButton({
    projectId,
}: {
    projectId: string;
}) {
    const navigate = useNavigate();
    const gotoTimelinePage = useCallback(() => {
        navigate(`/projects/${projectId}/timeline`);
    }, [navigate, projectId]);

    return (
        <>
            <Tooltip className={clsx(styles.tooltip)} anchorSelect="#timeline">
                Timeline
            </Tooltip>
            <img
                id="timeline"
                className="me-2"
                src={timelineIcon}
                alt=""
                style={{ width: 25, height: 25, cursor: 'pointer' }}
                onClick={(e: React.MouseEvent) => {
                    e.stopPropagation();
                    gotoTimelinePage();
                }}
            />
        </>
    )
}

export default TimelineButton