export class IndexedDB<T> {
    private database: IDBDatabase | null = null;
    constructor(
        private dbName: string,
        private storeName: string,
        private keyPath: string = 'id'
    ) {}

    async initial(): Promise<void> {
        this.database = await this.openDB();
    }

    async openDB(): Promise<IDBDatabase | null> {
        try {
            if (window === undefined) {
                return null;
            }
            return new Promise((resovle, reject) => {
                const request = window.indexedDB.open(this.dbName, 1);

                request.onupgradeneeded = (event) => {
                    if (!event.target) {
                        return null;
                    }

                    // @ts-ignore
                    const db = event.target.result as IDBDatabase;
                    db.createObjectStore(this.storeName, {
                        keyPath: this.keyPath,
                        autoIncrement: false
                    });
                };

                request.onsuccess = (event) => {
                    if (!event.target) {
                        return null;
                    }
                    // @ts-ignore
                    return resovle(event.target.result as IDBDatabase);
                };

                request.onerror = (err: any) => {
                    throw new Error(err);
                };
            });
        } catch (error) {
            console.log(error);
            return null;
        }
    }

    async get(key: string): Promise<{ id: string; value?: T } | null> {
        return new Promise((resovle, reject) => {
            if (!this.database) {
                reject(null);
                return null;
            }
            const transaction = this.database.transaction(this.storeName, 'readonly');
            const store = transaction.objectStore(this.storeName);
            const res = store.get(key) as IDBRequest<T>;
            res.onsuccess = () => {
                resovle(res.result as { id: string; value: T });
            };
        });
    }

    async add(data: T, key: string): Promise<void> {
        if (!this.database) {
            return;
        }

        const transaction = this.database.transaction([this.storeName], 'readwrite');
        const store = transaction.objectStore(this.storeName);
        const res = store.add({
            [this.keyPath]: key,
            value: data
        });

        res.onsuccess = () => {
            console.log('COMPLETED!');
        };
    }
}
