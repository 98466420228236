import clsx from 'clsx';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { IProject } from '../../types/project.interface';
import axiosService, { axiosErrorHandler } from '../../services/axios.service';
import axios from 'axios';
import { IUser } from '../../types/user.interface';
import styles from './project-detail-page.module.css';
import CommitTable from '../../components/commit-table/commit-table';
import { IFifo } from '../../types/fifo.interface';
import { IMultiItemResponseData } from '../../types/response-data.interface';
import OverviewBrowserTable from '../../components/overview-browser-table/overview-browser-table';
import {
    copyLinkIcon,
    editIcon,
    deleteIcon,
    folderTreeIcon,
    timelineIcon
} from '../../assets/icons';
import { useAuth } from '../../providers/auth.provider';
import { EUserRole, ILoggedInUser } from '../../types/login.interface';
import NotFoundPage from '../not-found-page';
import { Tooltip } from 'react-tooltip';
import UpdateProjectModal from '../../components/update-project-modal/update-project-modal';
import GroupMember from '../../components/group-member/group-member';
import { IGroup } from '../../types/group.interface';
import dayjs from 'dayjs';
import ProjectStatus from '../../components/project-status/project-status';
import AdminGroupMember from '../../components/admin-group-member/admin-group-member';

function ProjectDetailPage() {
    let { projectId } = useParams();
    const [isLoading, setIsLoading] = useState(false);
    const [isNotFound, setIsNotFound] = useState(false);
    const [project, setProject] = useState<IProject>();
    const { user }: { user: ILoggedInUser } = useAuth();

    const role = useRef(user.role === EUserRole.USER ? 0 : 2);

    const [isLoadingBrowser, setIsLoadingBrowser] = useState(false);
    const [files, setFiles] = useState<IFifo[]>([]);

    const [copyIconText, setCopyIconText] = useState('Copy checkout url');
    const [firstOpened, setFitstOpened] = useState(false);

    const fetchProjectData = useCallback(
        async (projectId: string): Promise<boolean> => {
            try {
                setIsLoading(true);
                const { data }: { data: IProject } = (
                    await axiosService.get(`projects/${projectId}?detail=true`)
                ).data;

                if (
                    user.role === EUserRole.USER &&
                    data.admins.some((a) => (a as IUser).id === user.id)
                ) {
                    role.current = 1;
                }

                setProject(data);
                return true;
            } catch (error) {
                setProject(undefined);
                if (axios.isAxiosError(error)) {
                    if (error.response?.status === 404) {
                        setIsNotFound(true);
                    } else {
                        axiosErrorHandler(error);
                    }
                } else {
                    console.log('error:  ', error);
                }
                return false;
            } finally {
                setIsLoading(false);
            }
        },
        [user]
    );

    // get files in path '/'
    const fetchFilesData = useCallback(async (projectId: string) => {
        try {
            setIsLoadingBrowser(true);
            const { data }: { data: IMultiItemResponseData<IFifo> } = (
                await axiosService.get(`fifos?path=/&prj=${projectId}`)
            ).data;
            const { items } = data;
            setFiles(items);
        } catch (error) {
            setFiles([]);
            if (axios.isAxiosError(error)) {
                axiosErrorHandler(error);
            } else {
                console.log('error:  ', error);
            }
        } finally {
            setIsLoadingBrowser(false);
        }
    }, []);

    useEffect(() => {
        (async () => {
            const success = await fetchProjectData(projectId as string);
            success && (await fetchFilesData(projectId as string));
        })();
    }, [projectId, fetchProjectData, fetchFilesData]);

    const handleCopyCheckoutUrl = useCallback((projectName: string) => {
        navigator.clipboard.writeText(`${process.env.REACT_APP_SVN_URL}/${projectName}/`);
        setCopyIconText('Copied!');
        setTimeout(() => {
            setCopyIconText('Copy checkout url');
        }, 3000);
    }, []);

    const onUpdateSuccess = useCallback((updatedProjectData: IProject) => {
        window.location.reload();
    }, []);

    const onUpdateAdminsGroupSuccess = useCallback((group: IGroup) => {
        window.location.reload();
    }, []);

    const onUpdateDefaultGroupSuccess = useCallback((group: IGroup) => {
        window.location.reload();
    }, []);

    const onUpdateCustomGroupSuccess = useCallback((group: IGroup) => {
        window.location.reload();
    }, []);

    return project ? (
        <div className={clsx('p-3')}>
            <div className={clsx('row')}>
                <div className={clsx('col-8', styles.leftContent)}>
                    <div className={clsx(styles.block)}>
                        <div
                            className={clsx(
                                styles.heading,
                                'd-flex justify-content-between align-items-center'
                            )}>
                            <div className={clsx(styles.titles)}>{project.name}</div>
                            <div className="">
                                <Tooltip anchorSelect="#copyCheckoutUrlIcon">
                                    {copyIconText}
                                </Tooltip>
                                <img
                                    id="copyCheckoutUrlIcon"
                                    className="ms-0"
                                    src={copyLinkIcon}
                                    alt=""
                                    style={{ width: 25, height: 25, cursor: 'pointer' }}
                                    onClick={(e: React.MouseEvent) => {
                                        e.stopPropagation();
                                        handleCopyCheckoutUrl(project.name);
                                    }}
                                />
                                <Tooltip anchorSelect="#goToBrowser">Go to browser</Tooltip>
                                <Link to={`/projects/${projectId}/browser?path=/`}>
                                    <img
                                        id="goToBrowser"
                                        className="ms-2"
                                        src={folderTreeIcon}
                                        alt=""
                                        style={{ width: 25, height: 25, cursor: 'pointer' }}
                                        onClick={(e: React.MouseEvent<HTMLElement>) => {
                                            e.stopPropagation();
                                        }}
                                    />
                                </Link>
                                {role.current >= 1 && (
                                    <>
                                        <Tooltip anchorSelect="#editProjectIcon">
                                            Update project
                                        </Tooltip>
                                        <img
                                            id="editProjectIcon"
                                            className="ms-2"
                                            src={editIcon}
                                            alt=""
                                            style={{ width: 25, height: 25, cursor: 'pointer' }}
                                            onClick={(e: React.MouseEvent<HTMLElement>) => {
                                                e.stopPropagation();
                                                setFitstOpened(true);
                                            }}
                                            data-bs-toggle="modal"
                                            data-bs-target={`#updateProject${projectId}`}
                                        />
                                        <UpdateProjectModal
                                            id={`updateProject${projectId}`}
                                            projectId={projectId!}
                                            firstOpened={firstOpened}
                                            projectData={project}
                                            onUpdateSuccess={onUpdateSuccess}
                                            gotoProject={false}
                                        />
                                    </>
                                )}
                                {role.current >= 2 && (
                                    <>
                                        <Tooltip anchorSelect="#deleteProjectIcon">
                                            Delete project
                                        </Tooltip>
                                        <img
                                            id="deleteProjectIcon"
                                            className="ms-2"
                                            src={deleteIcon}
                                            alt=""
                                            style={{ width: 25, height: 25, cursor: 'pointer' }}
                                        />
                                    </>
                                )}
                            </div>
                        </div>
                        <OverviewBrowserTable
                            projectId={projectId!}
                            files={files}
                            isLoading={isLoadingBrowser}
                        />
                    </div>
                    <div className={clsx(styles.block, 'mt-3')}>
                        <div
                            className={clsx(
                                styles.heading,
                                'd-flex justify-content-between align-items-center'
                            )}>
                            <div className={clsx(styles.titles)}>Last ten commits</div>
                            <div className="">
                                <Tooltip anchorSelect="#timeline">Timeline</Tooltip>
                                <Link to={`/projects/${projectId}/timeline`}>
                                    <img
                                        id="timeline"
                                        className="ms-2"
                                        src={timelineIcon}
                                        alt=""
                                        style={{ width: 25, height: 25, cursor: 'pointer' }}
                                        onClick={(e: React.MouseEvent) => {
                                            e.stopPropagation();
                                        }}
                                    />
                                </Link>
                            </div>
                        </div>
                        <CommitTable projectId={projectId!} limit={10} showMessage />
                    </div>
                </div>

                <div className={clsx('col-4', styles.rightContent)}>
                    <div className={clsx(styles.smallBlock)}>
                        <div className={clsx(styles.smallHeading)}>
                            Infomation
                            <hr className="m-0 mt-1" />
                        </div>
                        <div className={clsx(styles.smallContent)}>
                            <div className="m-0 d-flex">
                                <b className="me-2">Status: </b>
                                <ProjectStatus statusId={project.status} />
                            </div>
                            <p className="m-0">
                                <b>Start date: </b>
                                {project.startDate && dayjs(project.startDate).format('DD/MM/YYYY')}
                            </p>
                            <p className="m-0">
                                <b>End date: </b>
                                {project.startDate && dayjs(project.endDate).format('DD/MM/YYYY')}
                            </p>
                            <p className="m-0">
                                <b>Description: </b> {project.description}
                            </p>
                        </div>
                    </div>

                    <div className={clsx(styles.smallBlock, 'mt-3')}>
                        <div className={clsx(styles.smallHeading)}>
                            Groups
                            <hr className="m-0 mt-1" />
                        </div>
                        <div className={clsx(styles.smallContent)}>
                            <AdminGroupMember
                                project={project}
                                role={role.current}
                                onUpdateSuccess={onUpdateAdminsGroupSuccess}
                            />
                            {project.defaultGroups.map((g) => (
                                <GroupMember
                                    key={(g as IGroup).id}
                                    role={role.current}
                                    group={g as IGroup}
                                    onUpdateSuccess={onUpdateDefaultGroupSuccess}
                                />
                            ))}
                            {project.customGroups.map((g) => (
                                <GroupMember
                                    key={(g as IGroup).id}
                                    role={role.current}
                                    group={g as IGroup}
                                    onUpdateSuccess={onUpdateCustomGroupSuccess}
                                />
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    ) : isNotFound ? (
        <NotFoundPage />
    ) : null;
}

export default ProjectDetailPage;
