import { useReducer } from 'react';
import { EFifoKind, IFifo, IFolderTree, IFolderTreeElement } from '../types/fifo.interface';
import { isFolderPath } from '../utils/path-helper.util';

export interface ITreeReducerState {
    table: IFifo[];
    tree: IFolderTree;
}

export interface ITreeReducerAction {
    type: ETreeReducerActionType;
    payload: any;
}

export enum ETreeReducerActionType {
    INIT_TREE = 0,
    APPEND_CHILDREN_TO_FOLDER = 1,
    ON_SELECT_FOLDER = 2,
    SET_OPEN = 3,
    ON_RENAME = 4
}

const initState: ITreeReducerState = {
    table: [],
    tree: { children: [] }
};

export const reducer = (
    state: ITreeReducerState,
    action: ITreeReducerAction
): ITreeReducerState => {
    switch (action.type) {
        case ETreeReducerActionType.INIT_TREE:
            (() => {
                const path = action.payload.path as string;
                const data = action.payload.data as IFolderTree;
                sortTreeElement(data);
                state.tree = data;

                const folder = getFifoByPath(state.tree, path);
                state.table = folder.children || [];
                openAllSegments(state.tree, path);
            })();
            break;

        case ETreeReducerActionType.APPEND_CHILDREN_TO_FOLDER:
            (() => {
                const path = action.payload.path as string;
                const children = action.payload.children as IFolderTreeElement[];
                const sortedChildren = children
                    .filter((c) => c.kind === EFifoKind.DIR)
                    .concat(children.filter((c) => c.kind === EFifoKind.FILE));
                isFolderPath(path);
                const folder = getFifoByPath(state.tree, path);
                folder.children = sortedChildren;
                state.table = sortedChildren;
            })();
            break;

        case ETreeReducerActionType.ON_SELECT_FOLDER:
            (() => {
                const path = action.payload.path as string;
                isFolderPath(path);
                const folder = getFifoByPath(state.tree, path);
                state.table = folder.children || [];
                openAllSegments(state.tree, path);
            })();
            break;

        case ETreeReducerActionType.SET_OPEN:
            (() => {
                const path = action.payload.path as string;
                const open = action.payload.open as boolean;
                isFolderPath(path);
                const folder = getFifoByPath(state.tree, path);
                folder.open = open;
            })();
            break;

        case ETreeReducerActionType.ON_RENAME:
            (() => {
                const path = action.payload.path as string;
                const newName = action.payload.newName as string;

                console.log("dispatch rename: ", path, newName);
            })();
            break;

        default:
            throw new Error('Invalid action');
    }

    return { ...state };
};

const getFifoByPath = (tree: IFolderTree, path: string): IFolderTreeElement => {
    const segments = path.split('/');
    segments.shift();
    let isFolder = false;
    if (segments[segments.length - 1] === '') {
        isFolder = true;
        segments.pop();
    }

    let ans: IFolderTreeElement = {
        name: '',
        kind: EFifoKind.DIR,
        children: tree.children
    };
    segments.forEach((seg, index) => {
        if (index === segments.length - 1) {
            const fifo = ans.children?.find(
                (f) => f.kind === (isFolder ? EFifoKind.DIR : EFifoKind.FILE) && f.name === seg
            );
            if (!fifo) throw new Error('Fifo not found');
            ans = fifo;
        } else {
            const folder = ans.children?.find((f) => f.kind === EFifoKind.DIR && f.name === seg);
            if (!folder) throw new Error('Fifo not found');
            ans = folder;
        }
    });
    return ans;
};

const openAllSegments = (tree: IFolderTree, path: string) => {
    const segments = path.split('/');
    segments.shift();
    segments.pop();

    let currentFolder: IFolderTreeElement | undefined = {
        name: '',
        kind: EFifoKind.DIR,
        children: tree.children
    };

    segments.forEach((seg) => {
        currentFolder = currentFolder?.children?.find(
            (f) => f.kind === EFifoKind.DIR && f.name === seg
        );
        if (!currentFolder) throw new Error('Folder not found');
        currentFolder.open = true;
    });
};

const sortTreeElement = (tree: IFolderTreeElement | IFolderTree) => {
    tree.children = tree.children
        ? tree.children
              .filter((c) => c.kind === EFifoKind.DIR)
              .concat(tree.children.filter((c) => c.kind === EFifoKind.FILE))
        : tree.children;

    tree.children?.forEach((c) => {
        sortTreeElement(c);
    });
};

export const useTreeReducer = () => {
    return useReducer(reducer, initState);
};
