import clsx from 'clsx';
import React, { useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import { IPermission } from '../../types/permission.interface';
import axiosService, { axiosErrorHandler } from '../../services/axios.service';
import { EAccessPermission } from '../../types/fifo.interface';
import styles from './my-permission-table.module.css';

function MyPermissionTable({ projectId, path }: { projectId: string; path: string }) {
    const [isLoading, setIsLoading] = useState(false);
    const [permissions, setPermissions] = useState<EAccessPermission>(EAccessPermission.NO_PERMISSION);

    const fetchMyPermision = useCallback(async (projectId: string, path: string) => {
        try {
            setIsLoading(true);
            const { data }: { data: IPermission } = (
                await axiosService.get(`permission/my-permission?prj=${projectId}&path=${path}`)
            ).data;
            setPermissions(data.permission);
        } catch (error) {
            if (axios.isAxiosError(error)) {
                axiosErrorHandler(error);
            } else {
                console.log('error:  ', error);
            }
        } finally {
            setIsLoading(false);
        }
    }, []);

    useEffect(() => {
        fetchMyPermision(projectId, path);
    }, [projectId, path, fetchMyPermision]);

    return (
        <div className={clsx(styles.wrapper)}>
            <table className={clsx('table')}>
                <thead className={clsx('')}>
                    <tr>
                        <th>Read</th>
                        <th>Write</th>
                    </tr>
                </thead>
                <tbody className={clsx(styles.tableBody)}>
                    {isLoading ? (
                        <tr>
                            <td className="placeholder-glow">
                                <span className="placeholder col-10 bg-secondary"></span>
                            </td>
                            <td className="placeholder-glow">
                                <span className="placeholder col-10 bg-secondary"></span>
                            </td>
                        </tr>
                    ) : (
                        <tr>
                            <td>
                                <input
                                    type="checkbox"
                                    disabled
                                    checked={permissions !== EAccessPermission.NO_PERMISSION}
                                />
                            </td>
                            <td>
                                <input
                                    type="checkbox"
                                    disabled
                                    checked={permissions === EAccessPermission.READ_WRITE}
                                />
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
    );
}

export default MyPermissionTable;
