import clsx from 'clsx';
import React, { useCallback, useRef, useState } from 'react';
import styles from './change-password-modal.module.css';
import axiosService, { axiosErrorHandler } from '../../services/axios.service';
import axios from 'axios';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';

function ChangePasswordModal() {
    const navigate = useNavigate();
    const modalCloseButtonRef = useRef(null);

    const [currentPass, setCurrentPass] = useState('');
    const [isValidCurrentpass, setIsvalidCurrentpass] = useState(true);
    const [newPass, setNewPass] = useState('');
    const [isValidNewpass, setIsvalidNewpass] = useState(true);
    const [inValidNewPassMessage, setInValidNewPassMessage] = useState('');
    const [retypePass, setRetypePass] = useState('');
    const [isValidRetypepass, setIsvalidRetypepass] = useState(true);

    const [isLoading, setIsLoading] = useState(true);

    const resetValidate = useCallback(() => {
        setIsvalidCurrentpass(true);
        setIsvalidNewpass(true);
        setIsvalidRetypepass(true);
    }, []);

    const validateForm = useCallback(
        (currentPass: string, newPass: string, retypePass: string): boolean => {
            resetValidate();
            let isValid = true;
            if (currentPass.length === 0) {
                setIsvalidCurrentpass(false);
                isValid = false;
            }
            if (newPass.length === 0) {
                setIsvalidNewpass(false);
                setInValidNewPassMessage('Please enter new password');
                isValid = false;
            }
            if (newPass.length < 8) {
                setIsvalidNewpass(false);
                setInValidNewPassMessage('Password must be at least 8 characters');
                isValid = false;
            }
            if (retypePass !== newPass) {
                setIsvalidRetypepass(false);
                isValid = false;
            }
            return isValid;
        },
        [resetValidate]
    );

    const changePassword = useCallback(
        async (currentPass: string, newPass: string) => {
            try {
                setIsLoading(true);
                await axiosService.post(`auth/change-password`, {
                    currentPassword: currentPass,
                    newPassword: newPass
                });
                Swal.fire({
                    title: 'Change password successfully',
                    text: 'You need to log in again',
                    icon: 'success',
                    showConfirmButton: true,
                    confirmButtonText: 'Ok',
                    allowOutsideClick: false
                }).then((result) => {
                    if (result.isConfirmed) {
                        navigate(`/auth/logout`);
                    }
                });
            } catch (error) {
                if (axios.isAxiosError(error)) {
                    axiosErrorHandler(error);
                } else {
                    console.log('error:  ', error);
                }
            } finally {
                setIsLoading(false);
            }
        },
        [navigate]
    );

    const handleChangePassword = useCallback(
        async (currentPass: string, newPass: string, retypePass: string) => {
            if (validateForm(currentPass, newPass, retypePass)) {
                await changePassword(currentPass, newPass);
                (modalCloseButtonRef.current as any).click();
            }
        },
        [changePassword, validateForm]
    );

    return (
        <div
            className="modal fade"
            id="changePassModal"
            tabIndex={-1}
            aria-labelledby="exampleModalLabel"
            aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">
                            Change password
                        </h5>
                        <button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                            ref={modalCloseButtonRef}></button>
                    </div>
                    <div className="modal-body">
                        <div className={clsx(styles.form)}>
                            <div className="form-group">
                                <label
                                    htmlFor="currentPass"
                                    className={clsx('form-label required', styles.label)}>
                                    Current password
                                </label>
                                <input
                                    type="password"
                                    className={clsx(
                                        'form-control',
                                        !isValidCurrentpass && 'is-invalid'
                                    )}
                                    id="currentPass"
                                    value={currentPass}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                        setCurrentPass(e.target.value);
                                    }}
                                    required
                                />
                                <div className="invalid-feedback">
                                    Please enter current password
                                </div>
                            </div>
                            <div className="form-group mt-3">
                                <label
                                    htmlFor="newPass"
                                    className={clsx('form-label required', styles.label)}>
                                    New password
                                </label>
                                <input
                                    type="password"
                                    className={clsx(
                                        'form-control',
                                        !isValidNewpass && 'is-invalid'
                                    )}
                                    id="newPass"
                                    value={newPass}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                        setNewPass(e.target.value);
                                    }}
                                    required
                                />
                                <div className="invalid-feedback">{inValidNewPassMessage}</div>
                            </div>
                            <div className="form-group mt-3">
                                <label
                                    htmlFor="retypePass"
                                    className={clsx('form-label required', styles.label)}>
                                    Confirm password
                                </label>
                                <input
                                    type="password"
                                    className={clsx(
                                        'form-control',
                                        !isValidRetypepass && 'is-invalid'
                                    )}
                                    id="retypePass"
                                    value={retypePass}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                        setRetypePass(e.target.value);
                                    }}
                                    required
                                />
                                <div className="invalid-feedback">Password does not match</div>
                            </div>
                            <button
                                type="button"
                                className="btn btn-success w-100 me-1 mt-3"
                                onClick={(e: React.MouseEvent) => {
                                    e.stopPropagation();
                                    handleChangePassword(currentPass, newPass, retypePass);
                                }}>
                                Save changes
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ChangePasswordModal;
