import clsx from 'clsx';
import React, { useEffect, useRef, useState } from 'react';
import styles from './my-permission.module.css';
import { lockIcon } from '../../assets/icons';
import MyPermissionTable from '../my-permission-table/my-permission-table';

function MyPermission({
    projectId,
    path,
    iconSize,
    title = null
}: {
    projectId: string;
    path: string;
    iconSize: number;
    title?: string | null;
}) {
    const [isMounted, setIsMounted] = useState(false);
    const [isOpen, setIsOpen] = useState(true);

    // handle click inside and outside
    const theRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
        function handleClickOutside(event: any): void {
            if (theRef.current && !theRef.current.contains(event.target)) {
                // console.log('outside');
                setIsOpen(false);
            } else {
                // console.log('inside');
                setIsOpen(true);
            }
        }
        // Bind the event listener
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [theRef]);

    return (
        <div ref={theRef} className={clsx(styles.myPermission)}>
            <img
                src={lockIcon}
                alt=""
                style={{ width: iconSize, height: iconSize, cursor: 'pointer' }}
                onClick={(e: React.MouseEvent) => {
                    e.stopPropagation();
                    if (!isMounted) {
                        setIsMounted(true);
                    }
                }}
            />
            {isMounted && (
                <div className={clsx(styles.myPermissionDialog, isOpen ? 'd-block' : 'd-none')}>
                    {title && <p className={clsx('m-0', styles.title)}>{title}</p>}
                    <MyPermissionTable projectId={projectId} path={path} />
                </div>
            )}
        </div>
    );
}

export default MyPermission;
