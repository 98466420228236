import React, { useCallback, useState } from 'react';
import { EFifoKind, IFolderTreeElement } from '../../types/fifo.interface';
import { FaAngleRight } from 'react-icons/fa';
import { folderIcon, folderOpenIcon, documentIcon, moreIcon } from '../../assets/icons';
import styles from './folder-tree-element.module.css';
import clsx from 'clsx';
import { ETreeReducerActionType, ITreeReducerAction } from '../../reducers/tree.reducer';
import { EBrowserSearchAction } from '../../reducers/brower-search.reducer';
import FolderDropdownMenu from '../dropdown-menu/folder-dropdown-menu/folder-dropdown-menu';
import FileDropdownMenu from '../dropdown-menu/file-dropdown-menu/file-dropdown-menu';
import RenameInput from '../dropdown-menu/rename/input';

function FolderTreeElement({
    elementData,
    level,
    path,
    dispatch,
    fetchChildren,
    setSearchParams,
    currentPath,
    browserSearchDispatch,
    rename,
    setRename,
    index,
    selectedIndex,
    setSelectedIndex,
    projectId
}: {
    elementData: IFolderTreeElement;
    level: number;
    path: string;
    fetchChildren: (path: string) => Promise<boolean>;
    dispatch: React.Dispatch<ITreeReducerAction>;
    setSearchParams: Function;
    currentPath: string;
    browserSearchDispatch: Function;
    rename: boolean;
    setRename: (rename: boolean) => void;
    index: string;
    selectedIndex: any;
    setSelectedIndex: any;
    projectId: string;
}) {
    const [loading, setLoading] = useState(false);

    const setOpen = useCallback(
        (open: boolean) => {
            dispatch({
                type: ETreeReducerActionType.SET_OPEN,
                payload: { path, open }
            });
        },
        [dispatch, path]
    );

    const handleOpenFolder = useCallback(async () => {
        setOpen(true);
        // when children haven't been fetched => fetch
        if (!elementData.children && !loading) {
            setLoading(true);
            const isSuccess = await fetchChildren(path);
            if (!isSuccess) {
                setTimeout(() => {
                    setOpen(false);
                    setLoading(false);
                }, 300);
            } else {
                setLoading(false);
            }
        }
    }, [elementData, loading, path, setOpen, fetchChildren]);

    const handleSelectFolder = useCallback(() => {
        dispatch({
            type: ETreeReducerActionType.ON_SELECT_FOLDER,
            payload: { path }
        });
        browserSearchDispatch({
            type: EBrowserSearchAction.SET_IS_SHOW_SEARCH_RESULT,
            payload: { isShowSearchResult: false }
        });
        browserSearchDispatch({
            type: EBrowserSearchAction.SET_SEARCH_TEXT,
            payload: { searchText: '' }
        });
    }, [dispatch, path, browserSearchDispatch]);

    const onClickFifo = useCallback(async () => {
        if (elementData.kind === EFifoKind.DIR) {
            setSearchParams({ path });
            await handleOpenFolder();
            handleSelectFolder();
        } else {
            // TODO: view file content
        }
    }, [elementData, handleOpenFolder, handleSelectFolder, path, setSearchParams]);

    const onClickArrow = useCallback(async () => {
        if (elementData.open) {
            setOpen(false);
        } else {
            await handleOpenFolder();
        }
    }, [elementData, handleOpenFolder, setOpen]);

    return (
        <li className={clsx(styles.treeView_item)} key={index}>
            <div
                className={clsx(
                    styles.treeView_item_container,
                    path === currentPath && styles.treeView_item_container_active
                )}
            >
                {/* fixed width part */}
                <div className={clsx(styles.fixedPart)}>
                    {/* level lines */}
                    <div className={clsx(styles.treeView_item_levelLine_wrapper)}>
                        {[...Array(level - 1)].map((x, index) => (
                            <div className={clsx(styles.treeView_item_levelLine)} key={index} />
                        ))}
                    </div>

                    {elementData.kind === EFifoKind.DIR ? (
                        <div
                            className={clsx(styles.treeView_item_toggle_folder)}
                            onClick={(e: React.MouseEvent<HTMLElement>) => {
                                e.stopPropagation();
                                onClickArrow();
                            }}>
                            <FaAngleRight
                                size={12}
                                className={clsx(elementData.open && styles.rotate_90)}
                            />
                        </div>
                    ) : (
                        <div className={clsx(styles.treeView_item_toggle_file)}></div>
                    )}

                    <div className={clsx(styles.treeView_item_content)}>
                        {/* file/folder icon */}
                        {elementData.kind === EFifoKind.DIR ? (
                            elementData.open ? (
                                // folder open icon
                                <img
                                    src={folderOpenIcon}
                                    alt=""
                                    style={{ width: 16, height: 16 }}
                                />
                            ) : (
                                // folder close icon
                                <img src={folderIcon} alt="" style={{ width: 16, height: 16 }} />
                            )
                        ) : (
                            // file icon
                            <img src={documentIcon} alt="" style={{ width: 16, height: 16 }} />
                        )}
                    </div>
                </div>

                {/* dynamic width part */}
                {/* file/folder name */}
                {rename && selectedIndex === index ? (
                    <RenameInput
                        value={elementData.kind === EFifoKind.DIR ? elementData.name : elementData.name.split('.').slice(0, -1).join('.')}
                        setRename={setRename}
                        path={elementData.kind === EFifoKind.DIR ? path : path.slice(0, -1)}
                        element={elementData}
                        projectId={projectId}
                    />
                ) : (
                    <p
                        onClick={(e: React.MouseEvent<HTMLElement>) => {
                            e.stopPropagation();
                            onClickFifo();
                        }}
                        className={clsx('m-0', styles.treeView_item_text, styles.dynamicPart)}
                    >
                        {elementData.name}
                    </p>
                )}

                {/* more button dropdown */}
                <div className={clsx(styles.dropdown_menu)}>
                    {elementData.kind === EFifoKind.DIR ? (
                        <FolderDropdownMenu
                            setRename={setRename}
                            index={index}
                            setSelectedIndex={setSelectedIndex}
                        />
                    ) : (
                        <FileDropdownMenu
                            setRename={setRename}
                            index={index}
                            setSelectedIndex={setSelectedIndex}
                        />
                    )}
                </div>
            </div>

            {/* loading */}
            {loading && (
                <div className="d-flex justify-content-center">
                    <div className="spinner-border spinner-border-sm text-secondary" role="status">
                        <span className="sr-only"></span>
                    </div>
                </div>
            )}

            {/* children element */}
            {!loading && elementData.children && (
                <ul
                    className={clsx(
                        styles.treeView_item_submenu_wrapper,
                        !elementData.open && styles.hidden
                    )}>
                    {elementData.children.map((f: IFolderTreeElement, index) => (
                        <FolderTreeElement
                            key={index}
                            level={level + 1}
                            elementData={f}
                            path={`${path}${f.name}/`}
                            dispatch={dispatch}
                            fetchChildren={fetchChildren}
                            setSearchParams={setSearchParams}
                            currentPath={currentPath}
                            browserSearchDispatch={browserSearchDispatch}
                            rename={rename}
                            setRename={setRename}
                            index={`${f.name}_${index}`}
                            selectedIndex={selectedIndex}
                            setSelectedIndex={setSelectedIndex}
                            projectId={projectId}
                        />
                    ))}
                </ul>
            )}
        </li>
    );
}

export default FolderTreeElement;
