import clsx from 'clsx';
import React, { useCallback, useEffect, useState } from 'react';
import styles from './manage-permission-table.module.css';
import { IPermission } from '../../types/permission.interface';
import { IMultiItemResponseData } from '../../types/response-data.interface';
import axiosService, { axiosErrorHandler } from '../../services/axios.service';
import axios from 'axios';
import ManagePermissionRow from './manage-permission-row';
import { EAccessPermission } from '../../types/fifo.interface';

function ManagePermissionTable({ projectId, path }: { projectId: string; path: string }) {
    const [isLoading, setIsLoading] = useState(false);
    const [permissions, setPermissions] = useState<IPermission[]>([]);

    const fetchPermision = useCallback(async (projectId: string, path: string) => {
        try {
            setIsLoading(true);
            const { data }: { data: IMultiItemResponseData<IPermission> } = (
                await axiosService.get(`permission?prj=${projectId}&path=${path}`)
            ).data;
            const { items } = data;
            setPermissions(items);
        } catch (error) {
            if (axios.isAxiosError(error)) {
                axiosErrorHandler(error);
            } else {
                console.log('error:  ', error);
            }
        } finally {
            setIsLoading(false);
        }
    }, []);

    useEffect(() => {
        fetchPermision(projectId, path);
    }, [projectId, path, fetchPermision]);

    const onChangePermission = useCallback(
        (user: string | null, group: string | null, permission: EAccessPermission) => {
            const newList = permissions.map((p) => {
                if (group && p.group?.id === group) {
                    p.permission = permission;
                    return p;
                }
                if (user && p.user?.id === user) {
                    p.permission = permission;
                    return p;
                }
                return p;
            });
            setPermissions(newList);
        },
        [permissions]
    );

    return (
        <div className={clsx(styles.wrapper)}>
            <table className={clsx('table')}>
                <thead className={clsx('')}>
                    <tr>
                        <th>Group</th>
                        <th>Read</th>
                        <th>Write</th>
                        <th>Recursive</th>
                        <th className={clsx(styles.action)}>Action</th>
                    </tr>
                </thead>
                <tbody className={clsx(styles.tableBody)}>
                    {isLoading ? (
                        [...Array(2)].map((x, i) => (
                            <tr key={i}>
                                {[...Array(5)].map((x, j) => (
                                    <td key={j} className="placeholder-glow">
                                        <span className="placeholder col-10 bg-secondary"></span>
                                    </td>
                                ))}
                            </tr>
                        ))
                    ) : (
                        <>
                            {permissions
                                .filter((p) => !!p.group)
                                .sort((a, b) => {
                                    if (a.group.name > b.group.name) {
                                        return 1;
                                    } else if (a.group.name < b.group.name) {
                                        return -1;
                                    } else {
                                        return 0;
                                    }
                                })
                                .map((p, i) => (
                                    <ManagePermissionRow
                                        key={i}
                                        permission={p}
                                        UoG="group"
                                        path={path}
                                        onChangePermission={onChangePermission}
                                    />
                                ))}
                        </>
                    )}
                </tbody>
            </table>

            {/*<table className={clsx('table')}>
        <thead className={clsx('')}>
          <tr>
            <th>User</th>
            <th>Read</th>
            <th>Write</th>
            <th>Recursive</th>
            <th className={clsx(styles.action)}>Action</th>
          </tr>
        </thead>
        <tbody className={clsx(styles.tableBody)}>
          {isLoading ? (
            [...Array(2)].map((x, i) => <tr key={i}>
              {[...Array(5)].map((x, j) =>
                <td key={j} className='placeholder-glow'>
                  <span className='placeholder col-10 bg-secondary'></span>
                </td>
              )}
            </tr>)
          ) : (
            <>
              {permissions
                .filter(p => !!p.user)
                .sort((a, b) => {
                  if (a.user.username > b.user.username) {
                    return 1;
                  } else if (a.user.username < b.user.username) {
                    return -1;
                  } else {
                    return 0;
                  }
                })
                .map((p, i) => (
                  <ManagePermissionRow
                    key={i}
                    permission={p}
                    UoG='user'
                    path={path}
                    onChangePermission={onChangePermission}
                  />
                )
                )}
            </>
          )}
        </tbody>
      </table>*/}
        </div>
    );
}

export default ManagePermissionTable;
