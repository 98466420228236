import clsx from 'clsx';
import React, { useCallback, useState } from 'react';
import styles from './my-profile-page.module.css';
import { editIcon, keyIcon, userIcon } from '../../assets/icons';
import { useAuth } from '../../providers/auth.provider';
import { EUserRole, ILoggedInUser } from '../../types/login.interface';
import { Tooltip } from 'react-tooltip';
import axiosService, { axiosErrorHandler } from '../../services/axios.service';
import { toast } from 'react-toastify';
import axios from 'axios';
import { AuthStorageService } from '../../services/authStorage.service';
import ChangePasswordModal from '../../components/change-password-modal/change-password-modal';
import { emailRegex } from '../../constants/regex.const';

function MyProfilePage() {
    const { user, setUser }: { user: ILoggedInUser; setUser: Function } = useAuth();
    const [isEdit, setIsEdit] = useState(false);
    const [email, setEmail] = useState(user.email);
    const [isValidEmail, setIsvalidEmail] = useState(true);
    const [firstName, setFirstName] = useState(user.firstName);
    const [lastName, setLastName] = useState(user.lastName);
    const [isLoadingEdit, setIsLoadingEdit] = useState(false);

    const resetValidate = useCallback(() => {
        setIsvalidEmail(true);
    }, []);

    const validateForm = useCallback(
        (email: string | undefined | null): boolean => {
            resetValidate();
            let isValid = true;
            if (email && email?.length > 0 && !emailRegex.test(email)) {
                setIsvalidEmail(false);
                isValid = false;
            }
            return isValid;
        },
        [resetValidate]
    );

    const updateMyProfile = useCallback(
        async (
            email: string | undefined | null,
            firstName: string | undefined | null,
            lastName: string | undefined | null
        ) => {
            try {
                setIsLoadingEdit(true);
                await axiosService.patch(`users/my-profile`, {
                    ...(email && email?.length > 0 ? { email } : { email: null }),
                    firstName,
                    lastName
                });
                toast('Edit profile successfuly!', {
                    type: 'success',
                    autoClose: 2000
                });
                setIsEdit(false);
                const newProfileData = { ...user };
                if (email?.length === 0) {
                    newProfileData.email = null;
                }
                setUser(newProfileData);
                AuthStorageService.setLoginUser(newProfileData);
            } catch (error) {
                if (axios.isAxiosError(error)) {
                    axiosErrorHandler(error);
                } else {
                    console.log('error:  ', error);
                }
            } finally {
                setIsLoadingEdit(false);
            }
        },
        []
    );

    const handleUpdateMyProfile = useCallback(
        async (
            email: string | undefined | null,
            firstName: string | undefined | null,
            lastName: string | undefined | null
        ) => {
            if (validateForm(email)) {
                await updateMyProfile(email, firstName, lastName);
            }
        },
        [validateForm, updateMyProfile]
    );

    return (
        <div className={clsx(styles.wrapper, 'd-flex align-items-center flex-column')}>
            <ChangePasswordModal />
            <div className={clsx(styles.form)}>
                <div className={'d-flex justify-content-between align-items-center'}>
                    <div className="d-flex align-items-center">
                        <img src={userIcon} alt="" className={clsx(styles.avatar, 'me-2')} />
                        <p className={clsx(styles.username)}>{user.username}</p>
                    </div>
                    <div className={clsx('d-flex align-items-center ')}>
                        <Tooltip className={clsx(styles.tooltip)} anchorSelect="#editIcon">
                            Edit profile
                        </Tooltip>
                        <img
                            id="editIcon"
                            src={editIcon}
                            alt=""
                            className={clsx(styles.iconAsButton, 'me-2', isEdit && styles.active)}
                            onClick={(e: React.MouseEvent) => {
                                e.stopPropagation();
                                setIsEdit(true);
                            }}
                        />
                        <Tooltip className={clsx(styles.tooltip)} anchorSelect="#changePassIcon">
                            Change password
                        </Tooltip>
                        <img
                            id="changePassIcon"
                            src={keyIcon}
                            alt=""
                            className={clsx(styles.iconAsButton, 'me-2')}
                            data-bs-toggle="modal"
                            data-bs-target="#changePassModal"
                        />
                    </div>
                </div>
                <div className="form-group mt-3">
                    <label htmlFor="email" className={clsx('form-label', styles.label)}>
                        Email
                    </label>
                    <input
                        type="email"
                        className={clsx('form-control', !isValidEmail && 'is-invalid')}
                        id="email"
                        value={email || ''}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            setEmail(e.target.value);
                        }}
                        maxLength={255}
                        disabled={!isEdit}
                    />
                    <div id="validationEmail" className="invalid-feedback">
                        Email is invalid
                    </div>
                </div>

                <div className="form-group mt-3">
                    <label htmlFor="firstname" className={clsx('form-label', styles.label)}>
                        First name
                    </label>
                    <input
                        type="text"
                        className={clsx('form-control')}
                        id="firstname"
                        value={firstName || ''}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            setFirstName(e.target.value);
                        }}
                        maxLength={64}
                        disabled={!isEdit}
                    />
                </div>

                <div className="form-group mt-3">
                    <label htmlFor="lastname" className={clsx('form-label', styles.label)}>
                        Last name
                    </label>
                    <input
                        type="text"
                        className={clsx('form-control')}
                        id="lastname"
                        value={lastName || ''}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            setLastName(e.target.value);
                        }}
                        maxLength={64}
                        disabled={!isEdit}
                    />
                </div>

                <div className="form-group mt-3">
                    <label htmlFor="role" className={clsx('form-label', styles.label)}>
                        Role
                    </label>
                    <input
                        type="text"
                        className={clsx('form-control')}
                        id="role"
                        value={user.role === EUserRole.SYSTEM_ADMIN ? 'System admin' : 'User'}
                        disabled
                    />
                </div>

                {isEdit && (
                    <div className="mt-3 d-flex">
                        <button
                            type="submit"
                            className="btn btn-success w-100 me-1"
                            disabled={isLoadingEdit}
                            onClick={(e: React.MouseEvent) => {
                                e.preventDefault();
                                handleUpdateMyProfile(email, firstName, lastName);
                            }}>
                            {isLoadingEdit ? 'Updating...' : 'Update'}
                        </button>
                        <button
                            className="btn btn-light w-100"
                            disabled={isLoadingEdit}
                            onClick={(e: React.MouseEvent) => {
                                e.preventDefault();
                                setIsEdit(false);
                                setEmail(user.email);
                                setFirstName(user.firstName);
                                setLastName(user.lastName);
                            }}>
                            Cancel
                        </button>
                    </div>
                )}
            </div>
        </div>
    );
}

export default MyProfilePage;
