import { addIcon, adminSettingsIcon, createIcon, downloadIcon, exportIcon, moreIcon, moveToFolderIcon, setting02Icon, shareIcon, sortIcon, submitDocumentIcon, trashIcon, uploadIcon } from '../../../assets/icons'
import clsx from 'clsx'
import styles from "../dropdown-menu.module.css"
import RenameButton from '../rename/rename'

export type FolderDropdownMenuProps = {
    setRename: (rename: boolean) => void;
    index: string;
    setSelectedIndex: any;
}

const FolderDropdownMenu = ({
    setRename,
    index,
    setSelectedIndex,
}: FolderDropdownMenuProps) => {
    return (
        <div className="dropdown">
            <button
                className="btn d-flex p-1 border-0"
                type="button"
                id="folderDropdown"
                data-bs-toggle="dropdown"
                aria-expanded="false"
            >
                <img src={moreIcon} alt="" className={clsx(styles.icon, styles.icon_vertical)} />
            </button>
            <ul
                className={clsx("dropdown-menu", styles.dropdown_menu_container)} aria-labelledby="folderDropdown"
            >
                <li>
                    <a className="dropdown-item d-flex align-items-center" href="#">
                        <img src={addIcon} alt="" className={clsx(styles.icon, "me-2")} />
                        Add subfolder
                    </a>
                </li>
                <RenameButton setRename={setRename} index={index} setSelectedIndex={setSelectedIndex} />
                <li>
                    <a className="dropdown-item d-flex align-items-center" href="#">
                        <img src={shareIcon} alt="" className={clsx(styles.icon, "me-2")} />
                        Share
                    </a>
                </li>
                <li>
                    <a className="dropdown-item d-flex align-items-center" href="#">
                        <img src={moveToFolderIcon} alt="" className={clsx(styles.icon, "me-2")} />
                        Move
                    </a>
                </li>
                <li>
                    <a className="dropdown-item d-flex align-items-center" href="#">
                        <img src={trashIcon} alt="" className={clsx(styles.icon, "me-2")} />
                        Delete
                    </a>
                </li>
                <li>
                    <a className="dropdown-item d-flex align-items-center" href="#">
                        <img src={sortIcon} alt="" className={clsx(styles.icon, "me-2")} />
                        Sort
                    </a>
                </li>
                <li><hr className="dropdown-divider" /></li>
                <li>
                    <a className="dropdown-item d-flex align-items-center" href="#">
                        <img src={uploadIcon} alt="" className={clsx(styles.icon, "me-2")} />
                        Upload
                    </a>
                </li>
                <li>
                    <a className="dropdown-item d-flex align-items-center" href="#">
                        <img src={downloadIcon} alt="" className={clsx(styles.icon, "me-2")} />
                        Download source files
                    </a>
                </li>
                <li>
                    <a className="dropdown-item d-flex align-items-center" href="#">
                        <img src={exportIcon} alt="" className={clsx(styles.icon, "me-2")} />
                        Export files log
                    </a>
                </li>
                <li>
                    <a className="dropdown-item d-flex align-items-center" href="#">
                        <img src={submitDocumentIcon} alt="" className={clsx(styles.icon, "me-2")} />
                        Submit for review
                    </a>
                </li>
                <li>
                    <a className="dropdown-item d-flex align-items-center" href="#">
                        <img src={createIcon} alt="" className={clsx(styles.icon, "me-2")} />
                        Create transmittal
                    </a>
                </li>
                <li><hr className="dropdown-divider" /></li>
                <li>
                    <a className="dropdown-item d-flex align-items-center" href="#">
                        <img src={adminSettingsIcon} alt="" className={clsx(styles.icon, "me-2")} />
                        Permission setting
                    </a>
                </li>
                <li>
                    <a className="dropdown-item d-flex align-items-center" href="#">
                        <img src={setting02Icon} alt="" className={clsx(styles.icon, "me-2")} />
                        More
                    </a>
                </li>
            </ul>
        </div>
    )
}

export default FolderDropdownMenu