import React, { useCallback, useEffect, useState } from 'react';
import clsx from 'clsx';
import { deleteIcon, editIcon, folderTreeIcon, timelineIcon } from '../../assets/icons';
import { IProject } from '../../types/project.interface';
import { Link } from 'react-router-dom';
import styles from './project-table.module.css';
import Swal from 'sweetalert2';
import axiosService, { axiosErrorHandler } from '../../services/axios.service';
import axios from 'axios';
import UpdateProjectModal from '../update-project-modal/update-project-modal';
import ProjectStatus from '../project-status/project-status';
import dayjs from 'dayjs';

function ProjectTable({
    projects,
    isLoading,
    onDeleteProject
}: {
    projects: IProject[];
    isLoading: boolean;
    onDeleteProject: () => void;
}) {
    const [firstOpened, setFirstOpened] = useState<boolean[]>(projects.map(() => false));
    const openEdit = useCallback(
        (i: number) => {
            firstOpened[i] = true;
            setFirstOpened([...firstOpened]);
        },
        [firstOpened]
    );
    useEffect(() => {
        setFirstOpened(projects.map(() => false));
    }, [projects]);

    const deleteProject = async (projectId: string) => {
        try {
            await axiosService.delete(`projects/${projectId}`);
            Swal.fire({
                icon: 'success',
                title: 'Delete project success',
                showConfirmButton: true,
                confirmButtonText: 'Ok'
            }).then(() => {
                onDeleteProject();
            });
        } catch (error) {
            if (axios.isAxiosError(error)) {
                axiosErrorHandler(error);
            } else {
                console.log('error:  ', error);
            }
        }
    };

    const handleDeleteProject = (project: IProject) => {
        Swal.fire({
            icon: 'question',
            title: `Delete project '${project.name}'`,
            text: 'Are you sure you want to delete this project?',
            showConfirmButton: true,
            showCancelButton: true,
            reverseButtons: true,
            showLoaderOnConfirm: true,
            preConfirm: async () => {
                await deleteProject(project.id);
            }
        });
    };

    return (
        <table className={clsx(styles.table)}>
            <thead className={clsx(styles.tableHead)}>
                <tr>
                    <th className={clsx(styles.firstCol)}>Name</th>
                    <th>Status</th>
                    <th>Start date</th>
                    <th>End date</th>
                    <th>Location</th>
                    <th className={clsx(styles.lastCol)}>Action</th>
                </tr>
            </thead>
            <tbody className={clsx()}>
                {isLoading ? (
                    <></>
                ) : (
                    projects.map((project, index) => (
                        <tr key={project.id} className={clsx(styles.tableRow)}>
                            <td className={clsx(styles.firstCol)}>
                                <Link to={`/projects/${project.id}`} className={clsx(styles.link)}>
                                    {project.name}
                                </Link>
                            </td>
                            <td>
                                <ProjectStatus statusId={project.status} />
                            </td>
                            <td>
                                {project.startDate && dayjs(project.startDate).format('DD/MM/YYYY')}
                            </td>
                            <td>
                                {project.endDate && dayjs(project.endDate).format('DD/MM/YYYY')}
                            </td>
                            <td>{project.location}</td>

                            <td>
                                <Link to={`/projects/${project.id}/browser?path=/`}>
                                    <img
                                        id="goToBrowser"
                                        className="me-2"
                                        src={folderTreeIcon}
                                        alt=""
                                        style={{ width: 20, height: 20, cursor: 'pointer' }}
                                        onClick={(e: React.MouseEvent<HTMLElement>) => {
                                            e.stopPropagation();
                                        }}
                                        title="Browser"
                                    />
                                </Link>
                                <Link to={`/projects/${project.id}/timeline`}>
                                    <img
                                        id="timeline"
                                        className="me-2"
                                        src={timelineIcon}
                                        alt=""
                                        style={{ width: 20, height: 20, cursor: 'pointer' }}
                                        onClick={(e: React.MouseEvent) => {
                                            e.stopPropagation();
                                        }}
                                        title="Timeline"
                                    />
                                </Link>
                                <img
                                    src={editIcon}
                                    alt=""
                                    style={{ width: 20, height: 20, cursor: 'pointer' }}
                                    className="me-2"
                                    onClick={(e: React.MouseEvent<HTMLElement>) => {
                                        e.stopPropagation();
                                        openEdit(index);
                                    }}
                                    title="Update project info"
                                    data-bs-toggle="modal"
                                    data-bs-target={`#updateProject${project.id}`}
                                />
                                <UpdateProjectModal
                                    id={`updateProject${project.id}`}
                                    projectId={project.id}
                                    firstOpened={firstOpened[index]}
                                    gotoProject={true}
                                />
                                <img
                                    src={deleteIcon}
                                    alt=""
                                    style={{ width: 20, height: 20, cursor: 'pointer' }}
                                    onClick={(e: React.MouseEvent<HTMLElement>) => {
                                        e.stopPropagation();
                                        handleDeleteProject(project);
                                    }}
                                    title="Delete project"
                                />
                            </td>
                        </tr>
                    ))
                )}
            </tbody>
        </table>
    );
}

export default ProjectTable;
