import React from 'react';
import { IFolderTree } from '../../types/fifo.interface';
import FolderTreeElement from './folder-tree-element';
import styles from './folder-tree.module.css';
import clsx from 'clsx';
import { ITreeReducerAction } from '../../reducers/tree.reducer';

function FolderTree({
    data,
    fetchChildren,
    dispatch,
    setSearchParams,
    currentPath,
    browserSearchDispatch,
    rename,
    setRename,
    selectedIndex,
    setSelectedIndex,
    projectId
}: {
    data: IFolderTree;
    fetchChildren: (path: string) => Promise<boolean>;
    dispatch: React.Dispatch<ITreeReducerAction>;
    setSearchParams: Function;
    currentPath: string;
    browserSearchDispatch: Function;
    rename: boolean;
    setRename: (rename: boolean) => void;
    selectedIndex: any;
    setSelectedIndex: any;
    projectId: string;
}) {
    return (
        <div className="pe-2">
            <ul className={clsx(styles.treeView)}>
                {data.children.map((f, index) => (
                    <FolderTreeElement
                        key={index}
                        elementData={f}
                        level={1}
                        path={`/${f.name}/`}
                        dispatch={dispatch}
                        fetchChildren={fetchChildren}
                        setSearchParams={setSearchParams}
                        currentPath={currentPath}
                        browserSearchDispatch={browserSearchDispatch}
                        rename={rename}
                        setRename={setRename}
                        index={`${f.name}_${index}_${f.commit?.revision}`}
                        selectedIndex={selectedIndex}
                        setSelectedIndex={setSelectedIndex}
                        projectId={projectId}
                    />
                ))}
            </ul>
        </div>
    );
}

export default FolderTree;
