export enum EFifoKind {
    FILE = 'file',
    DIR = 'dir'
}

export enum EAccessPermission {
    READ = 'r',
    READ_WRITE = 'rw',
    NO_PERMISSION = ''
}

export interface IFifoCommitInfo {
    revision: number;
    author: string;
    date: Date;
}

export interface IFifo {
    kind: EFifoKind;
    name: string;
    enableView?: boolean;
    size?: string;
    commit?: IFifoCommitInfo;
}

export interface IFolderTreeElement extends IFifo {
    children?: IFolderTreeElement[];
    open?: boolean;
}

export interface IFolderTree {
    children: IFolderTreeElement[];
}
