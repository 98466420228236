import clsx from 'clsx';
import React, { useCallback, useRef, useState } from 'react';
import styles from './download-zip-button.module.css';
import { downloadZipIcon } from '../../assets/icons';
import axiosService, { axiosErrorHandler } from '../../services/axios.service';
import axios from 'axios';
import { toast } from 'react-toastify';

function DownloadZipButton({
    projectId,
    selectedPaths
}: {
    projectId: string;
    selectedPaths: string[];
}) {
    const [isDownloadingZip, setIsDownloadingZip] = useState(false);
    const toastId = useRef<any>();

    const downloadZip = useCallback(
        async (projectId: string, selectedPaths: string[]): Promise<string | null> => {
            try {
                setIsDownloadingZip(true);
                const { data }: { data: string } = (
                    await axiosService.post(`fifos/get-link-zip/${projectId}`, {
                        paths: selectedPaths
                    })
                ).data;
                return data;
            } catch (error) {
                if (axios.isAxiosError(error)) {
                    axiosErrorHandler(error);
                } else {
                    console.log('error:  ', error);
                }
                return null;
            } finally {
                setIsDownloadingZip(false);
            }
        },
        []
    );

    const onClickDownload = useCallback(
        async (projectId: string, selectedPaths: string[]) => {
            if (!isDownloadingZip) {
                toastId.current = toast(
                    <div className="d-flex justify-content-center align-items-center">
                        <div className="spinner-border spinner-border-sm me-2" role="status"></div>
                        <div className="">
                            Zipping...
                            <br />
                            Please wait! This may take a few minutes.
                        </div>
                    </div>,
                    {
                        autoClose: false,
                        position: 'bottom-right',
                        closeOnClick: false,
                        draggable: false,
                        closeButton: false
                    }
                );
                const zipId = await downloadZip(projectId, selectedPaths);
                toast.dismiss(toastId.current);
                if (zipId) {
                    window.open(
                        `${process.env.REACT_APP_API_URL}/fifos/download-zip-file/${zipId}`
                    );
                }
            }
        },
        [isDownloadingZip, downloadZip]
    );
    return (
        <img
            className={clsx(styles.fixedElement, 'me-2')}
            src={downloadZipIcon}
            alt=""
            style={{ width: 25, height: 25, cursor: 'pointer' }}
            onClick={(e: React.MouseEvent) => {
                e.stopPropagation();
                onClickDownload(projectId, selectedPaths);
            }}
        />
    )
}

export default DownloadZipButton