import clsx from 'clsx';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Link, useParams, useSearchParams } from 'react-router-dom';
import styles from './browser.module.css';
import { IFifo, IFolderTree } from '../../types/fifo.interface';
import axiosService, { axiosErrorHandler } from '../../services/axios.service';
import axios from 'axios';
import { IProject } from '../../types/project.interface';
import FolderTree from '../../components/folder-tree/folder-tree';
import PageWithLeftSidebar from '../../components/layouts/page-with-left-sidebar/page-with-left-sidebar';
import { ETreeReducerActionType, useTreeReducer } from '../../reducers/tree.reducer';
import { IMultiItemResponseData } from '../../types/response-data.interface';
import NotFoundPage from '../not-found-page';
import { EUserRole, ILoggedInUser } from '../../types/login.interface';
import { useAuth } from '../../providers/auth.provider';
import { IUser } from '../../types/user.interface';
import { useBrowserSearchReducer } from '../../reducers/brower-search.reducer';
import { toast } from 'react-toastify';
import BrowserTabBar from '../../components/browser-tab-bar/browser-tab-bar';
import { OpenDesign } from '../../utils/api-path.util';
import { EOpenDesign, useOpenDesignReducer } from '../../reducers/open-design.reducer';
import CopyCheckoutUrlButton from '../../components/copy-checkout-url-button/copy-checkout-url-button';
import TimelineButton from '../../components/timeline-button/timeline-button';
import { TEXT_FILE_TYPES } from '../../constants/file-types.const';
import { useWebSocketContext } from '../../providers/websocket.provider';
import { EnableFileWsType } from '../../events/types';
import { TopicIDs } from '../../events/topic';

function Browser() {
    const { projectId } = useParams();
    const [searchParams, setSearchParams] = useSearchParams();
    const [isNotFound, setIsNotFound] = useState(false);
    const path = searchParams.get('path') || '/';
    const { getData } = useWebSocketContext();

    const message = getData && getData<EnableFileWsType>();

    const [project, setProject] = useState<IProject>();
    const [isLoadingBrowser, setIsLoadingBrowser] = useState(false);

    const [openDesignState, openDesignDispatch] = useOpenDesignReducer();
    const { fileViewer, detailAssembly } = openDesignState;

    const treeReducer = useTreeReducer();
    const [state, dispatch] = treeReducer;
    const { tree } = state;

    const [, browserSearchDispatch] = useBrowserSearchReducer();

    const { user }: { user: ILoggedInUser } = useAuth();
    const role = useRef(user.role === EUserRole.USER ? 0 : 2);

    const [msOfficeFileURL, setMSOfficeFileURL] = useState<string>('');
    const [selectedViewPath, setSelectedViewPath] = useState<string>('');
    const [rename, setRename] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState<any>(null);

    const fetchProjectData = useCallback(
        async (projectId: string): Promise<boolean> => {
            try {
                const { data }: { data: IProject } = (
                    await axiosService.get(`projects/${projectId}?fields=name admins`)
                ).data;

                if (
                    user.role === EUserRole.USER &&
                    data.admins.some((a) => (a as IUser).id === user.id)
                ) {
                    role.current = 1;
                }

                setProject(data);
                return true;
            } catch (error) {
                if (axios.isAxiosError(error)) {
                    if (error.response?.status === 404) {
                        setIsNotFound(true);
                    } else {
                        axiosErrorHandler(error);
                    }
                } else {
                    console.log('error:  ', error);
                }
                return false;
            }
        },
        [user]
    );

    const fetchTree = useCallback(
        async (projectId: string, path: string) => {
            try {
                setIsLoadingBrowser(true);
                const { data }: { data: IFolderTree } = (
                    await axiosService.get(
                        `fifos/minimum-folder-tree?path=${path}&prj=${projectId}`
                    )
                ).data;
                dispatch({
                    type: ETreeReducerActionType.INIT_TREE,
                    payload: { data, path }
                });
            } catch (error) {
                if (axios.isAxiosError(error)) {
                    // console.log('axois error:', error);
                    axiosErrorHandler(error);
                } else {
                    console.log('error:  ', error);
                }
            } finally {
                setIsLoadingBrowser(false);
            }
        },
        [dispatch]
    );

    // get tree child node
    const fetchChildren = useCallback(
        async (path: string): Promise<boolean> => {
            try {
                const { data }: { data: IMultiItemResponseData<IFifo> } = (
                    await axiosService.get(`fifos?path=${path}&prj=${projectId}`)
                ).data;
                const { items } = data;
                dispatch({
                    type: ETreeReducerActionType.APPEND_CHILDREN_TO_FOLDER,
                    payload: {
                        path,
                        children: items
                    }
                });
                return true;
            } catch (error) {
                if (axios.isAxiosError(error)) {
                    axiosErrorHandler(error);
                } else {
                    console.log('error:  ', error);
                }
                return false;
            }
        },
        [dispatch, projectId]
    );

    // init - get project data
    // when get project data success, get minimum tree
    useEffect(() => {
        (async () => {
            const success = await fetchProjectData(projectId as string);
            success && (await fetchTree(projectId as string, path));
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [projectId]);

    useEffect(() => {
        if (message?.id !== TopicIDs.ENABLE_FILE_NOTIFY) return;
        fetchChildren(path);
    }, [message?.data?.fileId]);

    const isMSOffficeFile = useCallback((path: string) => {
        const fileType = path.split('.').pop();

        if (!fileType) {
            return;
        }
        return TEXT_FILE_TYPES.includes(fileType);
    }, []);

    const selectedModelViewer = useCallback(
        async (projectId: string, path: string, revision: any) => {
            try {
                if (isMSOffficeFile(path)) {
                    const { data } = await axiosService.get(
                        `fifos/gen-text-file/${projectId}?path=${path}&revision=${revision}`
                    );

                    if (data.data) {
                        setMSOfficeFileURL(
                            `${process.env.REACT_APP_API_URL}/fifos/get-text-file/?path=${data.data.filePath}&name=${data.data.fileNameFromPath}`
                        );
                    }
                } else {
                    // if file type is not text file, enable 3D view
                    const { data } = await axiosService.get(
                        OpenDesign(
                            { projectId: projectId as string },
                            { path: encodeURIComponent(path), revision: revision }
                        ).SELECTED_VIEWER
                    );
                    const file = data.data;
                    if (file.message) {
                        toast.success(file.message, {
                            autoClose: 500,
                            closeButton: true
                        });

                        return;
                    }

                    openDesignDispatch({
                        type: EOpenDesign.VIEW_FILE,
                        payload: {
                            file: file
                        }
                    });
                }
                setSelectedViewPath(path);
            } catch (error) {
                console.log('error:  ', error);
            }
        },
        [isMSOffficeFile, openDesignDispatch]
    );

    const tabBarPropValues = {
        projectId,
        path,
        project,
        treeReducer,
        isLoadingBrowser,
        fetchChildren,
        setSearchParams,
        selectedModelViewer,
        fileViewer,
        openDesignDispatch,
        isMSOffficeFile,
        selectedViewPath,
        setSelectedViewPath,
        msOfficeFileURL,
        detailAssembly,
        rename,
        setRename,
        selectedIndex,
        setSelectedIndex
    };

    return project ? (
        <PageWithLeftSidebar
            // left side bar
            sideBarContent={
                <div className={clsx('d-flex flex-column h-100', styles.sidebar)}>
                    <p className={clsx('m-0 mb-2', styles.fixedElement)}>Files</p>
                    <div className={clsx(styles.expandingElement, 'overflow-auto', 'pb-5')}>
                        <FolderTree
                            data={tree}
                            dispatch={dispatch}
                            fetchChildren={fetchChildren}
                            setSearchParams={setSearchParams}
                            currentPath={path}
                            browserSearchDispatch={browserSearchDispatch}
                            rename={rename}
                            setRename={setRename}
                            selectedIndex={selectedIndex}
                            setSelectedIndex={setSelectedIndex}
                            projectId={projectId as string}
                        />
                    </div>
                </div>
            }
            // right main content
            mainContent={
                projectId && (
                    <div className={clsx('h-100 d-flex flex-column')} id="mainContent">
                        {/* heading */}
                        <div
                            className={clsx(
                                'px-3 py-2 d-flex justify-content-between align-items-center',
                                styles.fixedElement
                            )}
                            id="heading">
                            <div className="">
                                <Link
                                    to={`/projects/${projectId}`}
                                    className={clsx(styles.projectName)}>
                                    {project?.name}
                                </Link>
                                <p className={clsx(styles.path)}>{path}</p>
                            </div>
                            <div className="d-flex align-items-center">
                                {/* copy checkout url */}
                                <CopyCheckoutUrlButton project={project} path={path} />
                                {/* go to timeline button */}
                                <TimelineButton projectId={projectId} />
                            </div>
                        </div>

                        <div className={clsx(styles.expandingElement, 'overflow-hidden')}>
                            <BrowserTabBar {...tabBarPropValues} />
                        </div>
                    </div>
                )
            }
        />
    ) : isNotFound ? (
        <NotFoundPage />
    ) : null;
}

export default Browser;
