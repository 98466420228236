import React from 'react';
import VisualizeViewer from './components/Visualize-viewer';
import styles from './viewer.module.css';
import clsx from 'clsx';
import PopupProperties from './components/popup-properties';
import { IOpenCloud, ODAViewerType } from './lib/open-cloud.interface';
import { MdOutlineAccountTree as TreeIcon } from 'react-icons/md';
import PopupTree from './components/popup-tree';
import { IAssemblies } from '../../types/open-design.interface';

export type TreeNodeType = {
    id: string;
    name: string;
};

type PropsType = {
    fileId: string;
    isAssemblies: boolean;
    detailAssembly: IAssemblies;
};

type StateType = {
    viewer: ODAViewerType | null;
    properties: any;
    showProperties: boolean;
    showTree: boolean;
    canvas_id: string;
    tree: TreeNodeType[];
    openCloud: IOpenCloud | null;
};

class OdaViewer extends React.Component<PropsType, StateType> {
    constructor(props: PropsType) {
        super(props);

        this.state = {
            viewer: null,
            properties: {},
            showProperties: false,
            showTree: false,
            canvas_id: '',
            tree: [],
            openCloud: null
        };

        this.onInit = this.onInit.bind(this);
        this.onError = this.onError.bind(this);
        this.selectedProperties = this.selectedProperties.bind(this);
        this.clickShowPropertiesHandle = this.clickShowPropertiesHandle.bind(this);
        this.clickShowTreeHandle = this.clickShowTreeHandle.bind(this);
        this.setTree = this.setTree.bind(this);
    }

    shouldComponentUpdate(
        nextProps: Readonly<PropsType>,
        nextState: Readonly<StateType>,
        nextContext: any
    ): boolean {
        return (
            nextProps.fileId !== this.props.fileId ||
            nextProps.isAssemblies !== this.props.isAssemblies ||
            nextState.properties?.handle !== this.state.properties?.handle ||
            nextState.showProperties !== this.state.showProperties ||
            nextState.showTree !== this.state.showTree
        );
    }

    componentDidMount(): void {
        if (!this.props.isAssemblies) {
            this.setTree([{ id: this.props.fileId, name: this.props.fileId }]);
        } else {
            if (this.props.detailAssembly?.associatedFiles?.length) {
                this.setTree([
                    ...this.props.detailAssembly.associatedFiles?.map((file) => ({
                        name: file.name,
                        id: this.props.detailAssembly.id as string
                    }))
                ]);
            }
        }
    }

    onInit(args: { viewer: any; canvas_id?: string; openCloud: IOpenCloud }): void {
        this.setState((prevState) => ({
            ...prevState,
            viewer: args.viewer,
            canvas_id: args.canvas_id || '',
            openCloud: args.openCloud
        }));
    }

    onError(err: any): void {
        console.log('ERROR VIEWER:', err);
    }

    selectedProperties(properties: any): void {
        this.setState((prevState) => ({
            ...prevState,
            properties: properties
        }));
    }

    setTree(tree: TreeNodeType[]) {
        this.setState((prevState) => ({
            ...prevState,
            tree: [...tree]
        }));
    }

    clickShowPropertiesHandle(): void {
        this.setState((prevState) => ({
            ...prevState,
            showProperties: !this.state.showProperties
        }));
    }

    clickShowTreeHandle(): void {
        this.setState((prevState) => ({
            ...prevState,
            showTree: !this.state.showTree
        }));
    }

    render(): React.ReactNode {
        return (
            <div className={clsx(styles.ViewerContainer)}>
                <VisualizeViewer
                    fileId={this.props.fileId}
                    isAssemblies={this.props.isAssemblies}
                    viewer={this.state.viewer}
                    selectedProperties={this.selectedProperties}
                    onInit={this.onInit}
                    onError={this.onError}
                />
                <button
                    aria-description="Properties"
                    title="Properties"
                    onClick={this.clickShowPropertiesHandle}
                    className={clsx(styles.ButtonPopupProperties, 'btn btn-primary')}
                    type="button">
                    +
                    {!!this.state.properties?.handle && !this.state.showProperties && <span></span>}
                </button>
                <button
                    onClick={this.clickShowTreeHandle}
                    aria-description="Tree"
                    title="Tree"
                    className={clsx(styles.ButtonPopupTree)}
                    type="button">
                    <TreeIcon />
                </button>

                {!!this.state.properties?.handle && this.state.showProperties && (
                    <PopupProperties properties={this.state.properties} />
                )}

                {this.state.showTree && (
                    <PopupTree
                        openCloud={this.state.openCloud}
                        isAssemblies={this.props.isAssemblies}
                        setTree={this.setTree}
                        tree={this.state.tree}
                    />
                )}
            </div>
        );
    }
}

function areEquals(prevProps: Readonly<PropsType>, nextProps: Readonly<PropsType>): boolean {
    return (
        prevProps.fileId === nextProps.fileId &&
        prevProps.isAssemblies === nextProps.isAssemblies &&
        nextProps.detailAssembly.id === prevProps.detailAssembly.id
    );
}

export default React.memo(OdaViewer, areEquals);
