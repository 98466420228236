import { useReducer } from 'react';
import { IBrowserSearchResult } from '../types/browser-search-result.interface';

export interface IState {
    searchText: string;
    isShowSearchResult: boolean;
    isLoadingSearchResult: boolean;
    searchResults: IBrowserSearchResult[];
}

export interface IAction {
    type: EBrowserSearchAction;
    payload: any;
}

export enum EBrowserSearchAction {
    SET_IS_SHOW_SEARCH_RESULT = 1,
    SET_SEARCH_RESULT = 2,
    SET_IS_LOADING = 3,
    SET_SEARCH_TEXT = 4
}

const initState = {
    searchText: '',
    isShowSearchResult: false,
    isLoadingSearchResult: false,
    searchResults: []
};

export const reducer = (state: IState, action: IAction): IState => {
    switch (action.type) {
        case EBrowserSearchAction.SET_IS_SHOW_SEARCH_RESULT:
            (() => {
                state.isShowSearchResult = !!action.payload.isShowSearchResult;
            })();
            break;

        case EBrowserSearchAction.SET_IS_LOADING:
            (() => {
                state.isLoadingSearchResult = !!action.payload.isLoadingSearchResult;
            })();
            break;

        case EBrowserSearchAction.SET_SEARCH_TEXT:
            (() => {
                state.searchText = action.payload.searchText as string;
            })();
            break;

        case EBrowserSearchAction.SET_SEARCH_RESULT:
            (() => {
                state.searchResults = action.payload.searchResults as IBrowserSearchResult[];
            })();
            break;

        default:
            throw new Error('Invalid action');
    }

    return { ...state };
};

export const useBrowserSearchReducer = () => {
    return useReducer(reducer, initState);
};
