import clsx from 'clsx';
import React, { ReactNode, useCallback, useEffect, useRef } from 'react';
import styles from './page-with-left-sidebar.module.css';

function PageWithLeftSidebar({
    sideBarContent,
    mainContent
}: {
    sideBarContent: ReactNode;
    mainContent: ReactNode;
}) {
    // handle resize sidebar
    const dragHolder = useRef<HTMLDivElement>(null);
    const sideBar = useRef<HTMLDivElement>(null);
    const contentArea = useRef<HTMLDivElement>(null);
    const sizeBarWidth = useRef(0);
    const clickPositionX = useRef(0);

    function onMouseMove(e: any) {
        sideBar.current!.style.width = `${sizeBarWidth.current + (e.pageX - clickPositionX.current)}px`;
    }

    const onMouseDown = useCallback((e: any) => {
        document.addEventListener('mousemove', onMouseMove);
        dragHolder &&
            dragHolder.current &&
            (dragHolder.current.className = `${clsx(styles.dragHolderChild, styles.active)}`);
        clickPositionX && (clickPositionX.current = e.pageX);
    }, []);

    const onMouseUp = useCallback(() => {
        document.removeEventListener('mousemove', onMouseMove);
        dragHolder &&
            dragHolder.current &&
            (dragHolder.current.className = `${clsx(styles.dragHolderChild)}`);
        sizeBarWidth && (sizeBarWidth.current = sideBar.current?.offsetWidth || 0);
    }, []);

    useEffect(() => {
        if (sideBar && sideBar.current) {
            sizeBarWidth.current = sideBar.current.offsetWidth;
        }
        if (dragHolder && dragHolder.current) {
            dragHolder.current.addEventListener('mousedown', onMouseDown);
            dragHolder.current.addEventListener('mouseup', onMouseUp);
        }
        if (contentArea && contentArea.current) {
            contentArea.current.addEventListener('mouseup', onMouseUp);
        }
        document.addEventListener('mouseup', onMouseUp);
    }, [onMouseDown, onMouseUp]);

    return (
        <div className="pageMinHeight d-flex">
            {/* left sidebar */}
            <div className={clsx(styles.sideBar, 'pageMinHeight')} ref={sideBar}>
                {sideBarContent}
            </div>

            {/* divider */}
            <div className={clsx(styles.dragHolder)}>
                <div className={clsx(styles.dragHolderChild)} ref={dragHolder}></div>
            </div>

            {/* main content */}
            <div className={clsx(styles.contentArea)} ref={contentArea}>
                {mainContent}
            </div>
        </div>
    );
}

export default PageWithLeftSidebar;
