import React from 'react';
import './App.css';
import AuthProvider from './providers/auth.provider';
import Routes from './routes';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import SocketProvider from './providers/websocket.provider';
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";

function App() {
    return (
        <AuthProvider>
            <SocketProvider>
                <Routes />
                <ToastContainer limit={5} />
            </SocketProvider>
        </AuthProvider>
    );
}

export default App;
