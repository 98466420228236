import clsx from 'clsx';
import React, { useCallback, useEffect, useState } from 'react';
import UserTable from '../../../components/user-table/user-table';
import { useSearchParams } from 'react-router-dom';
import { IMultiItemResponseData } from '../../../types/response-data.interface';
import { IUser } from '../../../types/user.interface';
import axiosService, { axiosErrorHandler } from '../../../services/axios.service';
import axios from 'axios';
import styles from './users-admin-page.module.css';
import { IoSearchSharp } from 'react-icons/io5';
import Pagination from '../../../components/pagination/pagination';
import CreateUserModal from '../../../components/create-user-modal/create-user-modal';

const limit = parseInt(process.env.REACT_APP_PAGINATION_LIMIT as string) | 12;

function UsersAdminPage() {
    let [searchParams, setSearchParams] = useSearchParams();
    const [users, setUsers] = useState<IUser[]>([]);
    const [page, setPage] = useState(
        searchParams.get('page') ? parseInt(searchParams.get('page') as string) : 1
    );
    const [total, setTotal] = useState(0);
    const [isLoading, setIsLoading] = useState(false);

    const fetchUsers = useCallback(async (page: number) => {
        try {
            setIsLoading(true);
            const { data }: { data: IMultiItemResponseData<IUser> } = (
                await axiosService.get(`/users?page=${page}&limit=${limit}&sortBy=-createdAt`)
            ).data;
            const { items, total } = data;
            setUsers(items);
            setTotal(total);
        } catch (error) {
            setUsers([]);
            if (axios.isAxiosError(error)) {
                axiosErrorHandler(error);
            } else {
                console.log('error:  ', error);
            }
        } finally {
            setIsLoading(false);
        }
    }, []);

    useEffect(() => {
        fetchUsers(page);
    }, [page, fetchUsers]);

    const onDeleteUser = () => {
        fetchUsers(page);
    };

    const handleChangePage = useCallback(
        (page: number) => {
            setPage(page);
            setSearchParams({ page: page.toString() });
        },
        [setSearchParams]
    );

    const onCreateSuccess = useCallback(() => {
        if (page === 1) {
            fetchUsers(1);
        } else {
            handleChangePage(1);
        }
    }, [handleChangePage, fetchUsers, page]);

    return (
        <div className={clsx('p-3')}>
            <div className={clsx(styles.mainContent)}>
                <div
                    className={clsx(
                        styles.heading,
                        'd-flex justify-content-between align-items-center'
                    )}>
                    <div className={clsx(styles.titles)}>Manage Users</div>
                    <div className="d-flex">
                        <div className={clsx(styles.searchBar)}>
                            <IoSearchSharp className={clsx(styles.searchBarIcon)} />
                            <div className="form-group">
                                <input
                                    type="text"
                                    className={clsx('form-control', styles.searchBarInput)}
                                    id="search"
                                    placeholder="Search user"
                                />
                            </div>
                        </div>
                        <button
                            className={clsx(styles.addNewButton, 'btn btn-success')}
                            type="button"
                            data-bs-toggle="modal"
                            data-bs-target="#createUserModal">
                            Create user
                        </button>
                        <CreateUserModal id="createUserModal" onCreateSuccess={onCreateSuccess} />
                    </div>
                </div>
                <UserTable users={users} isLoading={isLoading} onDeleteUser={onDeleteUser} />
                <div className={clsx(styles.optionBlock)}>
                    <Pagination
                        page={page}
                        handleChangePage={handleChangePage}
                        total={total}
                        limit={limit}
                    />
                </div>
            </div>
        </div>
    );
}

export default UsersAdminPage;
