export enum EUserRole {
    USER = 0,
    SYSTEM_ADMIN = 1
}

export interface ILoggedInUser {
    id: string;
    username: string;
    role: EUserRole;
    firstName?: string | null;
    lastName?: string | null;
    email?: string | null;
}

export interface ILoginResponse extends ILoggedInUser {
    access_token: string;
    refresh_token: string;
    access_token_expires_at: number;
    refresh_token_expires_at: number;
}
