export const Projects = {};

/****** FIFOS API *****/
type TFifoParams = {
    projectId: string;
};

type TfifoQuery = {};

interface TFifos<P, Q> {
    (params: P, query?: Q): { ENABLED_VIEWER: string };
}

export const Fifos: TFifos<TFifoParams, TfifoQuery> = (
    params: TFifoParams,
    query: TfifoQuery = {}
) => ({
    ENABLED_VIEWER: `fifos/enables-view/${params.projectId}`
});

/***** Open Design API *****/
type TOpenDesignParams = {
    projectId?: string;
    fileId?: string;
    database?: string;
    assemblyId?: string;
};

type TOpenDesignQuery = {
    path?: string;
    handles?: string;
    version?: string;
    projectId?: string;
    assemblies?: boolean;
    revision?: number;
};

interface TOpenDesign<P, Q> {
    (
        params: P,
        query?: Q
    ): {
        SELECTED_VIEWER: string;
        DETAIL_GEOMETRY: string;
        DOWNLOAD_FILE: string;
        GET_PROPERTIES: string;
        GET_ASSEMBLIES: string;
        CREATE_ASSEMBLIES: string;
        GET_DETAIL_ASSEMBLY: string;
        DELETE_ASSEMBLY: string;
        GET_TREE_ASSEMBLIES: string;
        UPDATE_ASSEMBLY: string;
    };
}

export const OpenDesign: TOpenDesign<TOpenDesignParams, TOpenDesignQuery> = (
    params: TOpenDesignParams,
    query: TOpenDesignQuery = {}
) => ({
    SELECTED_VIEWER: `oda/files/${params.projectId}?path=${query.path}&revision=${query.revision}`,
    DETAIL_GEOMETRY: `oda/files/${params.fileId}/geometry`,
    DOWNLOAD_FILE: `oda/files/${params.fileId}/downloads?assemblies=${query.assemblies}`,
    GET_PROPERTIES: `oda/files/${params.fileId}/properties?handles=${query.handles}&version=${query.version}&assemblies=${query.assemblies}`,
    GET_ASSEMBLIES: `oda/assemblies/${params.projectId}`,
    CREATE_ASSEMBLIES: `oda/assemblies/${params.projectId}`,
    GET_DETAIL_ASSEMBLY: `oda/assemblies/${params.assemblyId}/detail?project_id=${query.projectId}`,
    DELETE_ASSEMBLY: `oda/assemblies/${params.assemblyId}?project_id=${query.projectId}`,
    GET_TREE_ASSEMBLIES: `oda/assemblies/${params.assemblyId}/tree`,
    UPDATE_ASSEMBLY: `oda/assemblies/${params.assemblyId}/update?project_id=${query.projectId}`
});
