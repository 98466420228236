import clsx from 'clsx';
import React from 'react';
import styles from './email-status.module.css';

const status = [
    {
        className: 'inactivate',
        title: 'Inactivate'
    },
    {
        className: 'activate',
        title: 'Activated'
    }
];

function EmailStatus({ statusId }: { statusId: number }) {
    return (
        <p className={clsx(styles[status[statusId].className], styles.cell)}>
            {status[statusId].title}
        </p>
    );
}

export default EmailStatus;
