import { Outlet } from 'react-router-dom';
import { useAuth } from '../providers/auth.provider';
import AuthenicatedLayout from '../components/layouts/authenticated-layout';
import UnauthenicatedLayout from '../components/layouts/unauthenicated-layout';
import { ILoggedInUser } from '../types/login.interface';

export function Flexlayout() {
    const { user }: { user: ILoggedInUser } = useAuth();
    if (user.id) {
        return (
            <AuthenicatedLayout>
                <Outlet />
            </AuthenicatedLayout>
        );
    } else {
        return (
            <UnauthenicatedLayout>
                <Outlet />
            </UnauthenicatedLayout>
        );
    }
}
