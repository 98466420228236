import React from 'react';
import VisualizejsProgress from '../visualizejs-progress';
import { OpenCloud } from '../../lib/open-cloud';
import styles from './visualize-viewer.module.css';
import clsx from 'clsx';
import { toast } from 'react-toastify';
import { ImSpinner11 as OrbitIcon } from 'react-icons/im';
import { FaRegHandPaper as PanIcon } from 'react-icons/fa';
import { MdOutlineZoomIn as ZoomIcon } from 'react-icons/md';
import { MdZoomInMap as ZoomToExtents } from 'react-icons/md';
import { openDesignGetProperties } from '../../../../services/files/open-design-get-properties';
import { IOpenCloud, ODAViewerType } from '../../lib/open-cloud.interface';

type PropTypes = {
    fileId: string;
    isAssemblies: boolean;
    onInit: (viewer: any) => void;
    onError: (err: any) => void;
    selectedProperties: (properties: any) => void;
    viewer: ODAViewerType | null;
};

const options = {
    urlMemFile: 'https://opencloud.azureedge.net/libs/visualizejs/25.1/Visualize.js.wasm',
    TOTAL_MEMORY: 234217728
};

let memoSelectedObject: { [key: string]: boolean } = {};

const VisualizeViewer: React.FC<PropTypes> = ({
    fileId,
    isAssemblies,
    viewer,
    onInit,
    selectedProperties,
    onError
}) => {
    const [loading, setLoading] = React.useState<boolean>(false);
    const [progress, setProgress] = React.useState<number>(0);
    const [openCloudScope, setOpenCloudScope] = React.useState<IOpenCloud>();
    const [isViewer, setIsViewer] = React.useState<boolean>(false);
    const canvasRef = React.useRef<any>();

    async function selectPropertiesHandle(propt: any) {
        if (propt === false) {
            if (!memoSelectedObject['unselected']) {
                selectedProperties({});
                memoSelectedObject['unselected'] = true;
            }
            return;
        }

        if (!memoSelectedObject[propt as string]) {
            if (Object.keys(memoSelectedObject).length > 0) memoSelectedObject = {};
            const res = await openDesignGetProperties(fileId, {
                handles: propt as string,
                version: '0',
                isAssemblies: isAssemblies
            });

            memoSelectedObject[propt as string] = true;
            selectedProperties(res);
        }
    }

    async function loadViewer() {
        try {
            console.log(
                '%cLOAD VIEWER!',
                'color: white; font-size: large; background-color: #2db473; text-align: center'
            );
            setLoading(true);
            const openCloud: IOpenCloud = new OpenCloud(`canvas-${fileId}`);
            await openCloud.init(options, selectPropertiesHandle);
            await openCloud.loadFile(fileId, isAssemblies);

            // load action
            openCloud.activePanAction();
            openCloud.activeOrbitAction();
            openCloud.activeZoomAction();

            setOpenCloudScope(openCloud);

            onInit({
                viewer: openCloud.getViewer(),
                canvas_id: `canvas-${fileId}`,
                openCloud: openCloud
            });
        } catch (error: any) {
            // throw new Error(error.message);
            onError(error);
        } finally {
            setLoading(false);
        }
    }

    React.useEffect(() => {
        if (!canvasRef.current || !fileId) return;
        try {
            if (!isViewer) {
                loadViewer();
                setIsViewer(true);
            }
        } catch (error) {
            toast.error('L?i t?i mô hình vui lòng th? l?i!', {
                autoClose: 2000,
                closeButton: true
            });
            onError(error);
        }
    }, [fileId, canvasRef.current]);

    return (
        <div style={{ height: '100%', width: '100%' }}>
            <canvas id={`canvas-${fileId}`} ref={canvasRef} className={clsx(styles.canvas)} />
            <div className={clsx(styles.actionPanel)}>
                <div id="pan_btn" className={clsx(styles.actionItem)}>
                    <span className="material-icons" style={{ fontSize: '2em' }}>
                        <PanIcon />
                    </span>
                    <div className={clsx(styles.actionItemText)}>Pan</div>
                </div>
                <div id="orbit_btn" className={clsx(styles.actionItem)}>
                    <span className="material-icons" style={{ fontSize: '2em' }}>
                        <OrbitIcon />
                    </span>
                    <div className={clsx(styles.actionItemText)}>Orbit</div>
                </div>
                <div id="zoom_btn" className={clsx(styles.actionItem)}>
                    <span className="material-icons" style={{ fontSize: '2em' }}>
                        <ZoomIcon />
                    </span>
                    <div className={clsx(styles.actionItemText)}>Zoom</div>
                </div>
                <div
                    id="zoom_btn"
                    className={clsx(styles.actionItem)}
                    onClick={() => {
                        if (openCloudScope) {
                            openCloudScope.zoomToExtents(false, true);
                        }
                    }}>
                    <span className="material-icons" style={{ fontSize: '2em' }}>
                        <ZoomToExtents />
                    </span>
                    <div className={clsx(styles.actionItemText)}>Home</div>
                </div>
            </div>
            <VisualizejsProgress loading={loading} value={progress} />
        </div>
    );
};

function areEquals(prevProps: Readonly<PropTypes>, nextProps: Readonly<PropTypes>) {
    return prevProps.fileId === nextProps.fileId;
}

export default React.memo(VisualizeViewer, areEquals);
