import clsx from 'clsx';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import styles from './update-user-modal.module.css';
import axiosService, { axiosErrorHandler } from '../../services/axios.service';
import Swal from 'sweetalert2';
import axios from 'axios';
import { IUser } from '../../types/user.interface';

function UpdateUserModal({
    id,
    userId,
    firstOpened
}: {
    id: string;
    userId: string;
    firstOpened: boolean;
}) {
    const [isLoading, setIsLoading] = useState(false);

    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [role, setRole] = useState(0);

    const closeButtonRef = useRef(null);

    const fetchUser = useCallback(async (userId: string) => {
        try {
            setIsLoading(true);
            const { data }: { data: IUser } = (await axiosService.get(`users/${userId}`)).data;
            setUsername(data.username);
            setEmail(data.email);
            setFirstName(data.firstName);
            setLastName(data.lastName);
            setRole(data.role);
        } catch (error) {
            if (axios.isAxiosError(error)) {
                axiosErrorHandler(error);
            } else {
                console.log('error:  ', error);
            }
        } finally {
            setIsLoading(false);
        }
    }, []);

    const resetValidate = useCallback(() => {}, []);

    const validateForm = useCallback((): boolean => {
        resetValidate();
        let isValid = true;
        return isValid;
    }, [resetValidate]);

    const updateUser = useCallback(
        async (
            userId: string,
            email: string,
            firstName: string,
            lastName: string,
            role: number
        ) => {
            try {
                setIsLoading(true);
                await axiosService.patch(`users/${userId}`, {
                    email,
                    firstName,
                    lastName,
                    role
                });
                (closeButtonRef.current as any).click();
                Swal.fire({
                    title: 'Update user successfully',
                    icon: 'success',
                    showConfirmButton: true,
                    confirmButtonText: 'Ok'
                });
            } catch (error) {
                if (axios.isAxiosError(error)) {
                    axiosErrorHandler(error);
                } else {
                    console.log('error:  ', error);
                }
            } finally {
                setIsLoading(false);
            }
        },
        []
    );

    const handleUpdateUser = useCallback(
        async (
            userId: string,
            email: string,
            firstName: string,
            lastName: string,
            role: number
        ) => {
            if (validateForm()) {
                await updateUser(userId as string, email, firstName, lastName, role);
            }
        },
        [validateForm, updateUser]
    );

    useEffect(() => {
        if (firstOpened) {
            fetchUser(userId as string);
        }
    }, [userId, fetchUser, firstOpened]);

    return (
        <div
            className={clsx('modal fade', styles.modalWrapper)}
            id={id}
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            tabIndex={-1}
            aria-labelledby="staticBackdropLabel"
            aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="staticBackdropLabel">
                            Update project
                        </h5>
                        <button
                            type="button"
                            className={clsx('btn-close')}
                            data-bs-dismiss="modal"
                            aria-label="Close"
                            ref={closeButtonRef}></button>
                    </div>
                    <div className="modal-body">
                        {isLoading ? (
                            <div className="d-flex justify-content-center">
                                <div className="spinner-border" role="status"></div>
                            </div>
                        ) : (
                            <div className={clsx(styles.form)}>
                                <div className="form-group">
                                    <label className={clsx('form-label required', styles.label)}>
                                        Username
                                    </label>
                                    <input
                                        type="text"
                                        className={clsx('form-control')}
                                        value={username}
                                        disabled
                                    />
                                </div>

                                <div className="form-group mt-3">
                                    <label className={clsx('form-label', styles.label)}>
                                        Email
                                    </label>
                                    <input
                                        type="email"
                                        className={clsx('form-control')}
                                        value={email}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                            setEmail(e.target.value);
                                        }}
                                        maxLength={255}
                                    />
                                </div>

                                <div className="form-group mt-3">
                                    <label className={clsx('form-label', styles.label)}>
                                        First name
                                    </label>
                                    <input
                                        type="text"
                                        className={clsx('form-control')}
                                        value={firstName}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                            setFirstName(e.target.value);
                                        }}
                                        maxLength={64}
                                    />
                                </div>

                                <div className="form-group mt-3">
                                    <label className={clsx('form-label', styles.label)}>
                                        Last name
                                    </label>
                                    <input
                                        type="text"
                                        className={clsx('form-control')}
                                        value={lastName}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                            setLastName(e.target.value);
                                        }}
                                        maxLength={64}
                                    />
                                </div>

                                <div className="form-group mt-3">
                                    <label className={clsx('form-label required', styles.label)}>
                                        Role
                                    </label>
                                    <select
                                        name="role"
                                        className="form-select"
                                        value={role}
                                        onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                                            setRole(parseInt(e.target.value));
                                        }}>
                                        <option value={0}>User</option>
                                        <option value={1}>System admin</option>
                                    </select>
                                </div>

                                <div className="w-100 pt-4">
                                    <button
                                        type="submit"
                                        className="btn btn-success w-100"
                                        disabled={isLoading}
                                        onClick={(e: React.MouseEvent) => {
                                            e.preventDefault();
                                            handleUpdateUser(
                                                userId as string,
                                                email,
                                                firstName,
                                                lastName,
                                                role
                                            );
                                        }}>
                                        {isLoading ? 'Updating...' : 'Update'}
                                    </button>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default UpdateUserModal;
