import React from 'react';
import { socket } from '../socket';
import { TOPIC_WS } from '../events/topic';

export type MessageWsType<T> = { id: string; t: number; data?: T };

type ReturnDataWsType<T> = {
    isReady: boolean;
    message: MessageWsType<T> | null;
    emit: (topic: string, data: any) => void;
    send: (data: any) => void;
};

export function useWs<T>(): ReturnDataWsType<T> {
    const [isReady, setIsReady] = React.useState<boolean>(false);
    const [message, setMessage] = React.useState<MessageWsType<T> | null>(null);

    const ws = React.useRef<any>(null);

    React.useEffect(() => {
        socket.on('connect', () => {
            setIsReady(true);
            console.log('connect websocket success!');
            socket.emit('ping', 'pong');
        });

        socket.on('disconnect', () => {
            console.log('disconnect websocket!');
            setIsReady(false);
        });

        socket.on(TOPIC_WS, (topics: string[]) => {
            topics.forEach((topic) => {
                socket.on(`${topic}`, (data) => {
                    // topic-id, sub-topic, date-time, data
                    const [ti, st, dt, d] = data;
                    setMessage({
                        t: dt,
                        id: ti,
                        data: d
                    });
                });
            });
        });

        function emit(topic: string, data: any) {
            // logic code
            socket.emit(topic, data);
        }

        ws.current = socket;

        // return () => {
        //     socket.close();
        // };
    }, []);

    // bind is needed to make sure `send` references correct `this`
    return {
        isReady,
        message,
        send: ws.current?.send.bind(ws.current),
        emit: ws.current?.emit.bind(ws.current)
    };
}
