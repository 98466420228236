import { RouteObject, RouterProvider, createBrowserRouter } from 'react-router-dom';
import HomePage from '../pages/home-page';
import LoginPage from '../pages/auth/login/login-page';
import { AuthenticatedOnly } from './authenticated-only';
import NotFoundPage from '../pages/not-found-page';
import { UnauthenticatedOnly } from './unauthenticated-only';
import LogoutPage from '../pages/auth/logout/logout-page';
import { useAuth } from '../providers/auth.provider';
import { EUserRole, ILoggedInUser } from '../types/login.interface';
import MyProfilePage from '../pages/me/my-profile-page';
import UsersAdminPage from '../pages/admin/users/users-admin-page';
import ProjectsAdminPage from '../pages/admin/projects/projects-admin-page';
import SettingAdminPage from '../pages/admin/setting/setting-admin-page';
import ErrorPage from '../pages/error-page';
import ProjectDetailPage from '../pages/projects/project-detail-page';
import Browser from '../pages/projects/browser';
import { Flexlayout } from './flex-layout';
import ListProjectPage from '../pages/projects/list-project-page';
import TimelinePage from '../pages/projects/timeline-page';
import EmailsAdminPage from '../pages/admin/emails/emails-admin-page';

export default function Routes() {
    const { user }: { user: ILoggedInUser } = useAuth();

    // Define public routes accessible to all users
    const routesForPublic: RouteObject[] = [];

    // Define routes accessible only to authenticated users
    const routesForAuthenticatedOnly: RouteObject[] = [
        {
            path: '/',
            element: <AuthenticatedOnly />,
            errorElement: <ErrorPage />,
            children: [
                {
                    index: true,
                    element: <HomePage />
                },
                {
                    path: 'auth',
                    children: [
                        {
                            path: 'logout',
                            element: <LogoutPage />
                        }
                    ]
                },
                {
                    path: 'me',
                    element: <MyProfilePage />
                },
                {
                    path: 'projects',
                    children: [
                        {
                            index: true,
                            element: <ListProjectPage />
                        },
                        {
                            path: ':projectId',
                            element: <ProjectDetailPage />
                        },
                        {
                            path: ':projectId/browser',
                            element: <Browser />
                        },
                        {
                            path: ':projectId/timeline',
                            element: <TimelinePage />
                        }
                    ]
                }
            ]
        }
    ];

    // Define routes accessible only to non-authenticated users
    const routesForUnauthenticatedOnly: RouteObject[] = [
        {
            path: '',
            element: <UnauthenticatedOnly />,
            errorElement: <ErrorPage />,
            children: [
                {
                    path: 'login',
                    element: <LoginPage />
                }
            ]
        }
    ];

    // Define routes accessible only to admin users
    const routersForAdminOnly: RouteObject[] = [
        {
            path: '/admin',
            element: <AuthenticatedOnly />,
            errorElement: <ErrorPage />,
            children: [
                {
                    path: 'users',
                    children: [
                        {
                            index: true,
                            element: <UsersAdminPage />
                        }
                    ]
                },
                {
                    path: 'projects',
                    children: [
                        {
                            index: true,
                            element: <ProjectsAdminPage />
                        }
                    ]
                },
                {
                    path: 'setting',
                    element: <SettingAdminPage />
                },
                {
                    path: 'emails',
                    element: <EmailsAdminPage />
                }
            ]
        }
    ];

    const router = createBrowserRouter([
        ...routesForPublic,
        ...routesForUnauthenticatedOnly,
        ...routesForAuthenticatedOnly,
        ...(user.role === EUserRole.SYSTEM_ADMIN ? routersForAdminOnly : []),
        {
            path: '/',
            element: <Flexlayout />,
            children: [
                {
                    path: '*',
                    element: <NotFoundPage />
                }
            ]
        }
    ]);

    return <RouterProvider router={router} />;
}
