export const TEXT_FILE_TYPES = [
    'txt',
    'doc',
    'docx',
    'pdf',
    'xls',
    'xlsx',
    'ppt',
    'pptx',
    'jpg',
    'jpeg',
    'png',
    'gif',
    'bmp'
];