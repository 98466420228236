import clsx from 'clsx';
import React from 'react';
import { MdOutlineArrowBackIosNew, MdOutlineArrowForwardIos } from 'react-icons/md';
import ReactPaginate from 'react-paginate';
import styles from './pagination.module.css';

function Pagination({
    page,
    handleChangePage,
    total,
    limit
}: {
    page: number;
    handleChangePage: Function;
    total: number;
    limit: number;
}) {
    const numOfPage = Math.ceil(total / limit);

    const onPageChange = (event: any) => {
        handleChangePage(event.selected + 1);
    };

    return (
        <ReactPaginate
            pageCount={numOfPage}
            initialPage={page - 1}
            onPageChange={onPageChange}
            previousLabel={<MdOutlineArrowBackIosNew style={{ color: 'black' }} />}
            nextLabel={<MdOutlineArrowForwardIos style={{ color: 'black' }} />}
            className={clsx(styles.pagination)}
            pageClassName={clsx(styles.pageBtn)}
            activeClassName={clsx(styles.activePage)}
            activeLinkClassName={clsx(styles.activePageLink)}
            breakClassName={clsx(styles.pageBtn)}
            previousClassName={clsx(styles.pageBtn)}
            nextClassName={clsx(styles.pageBtn)}
            pageLinkClassName={clsx(styles.aTag)}
            breakLinkClassName={clsx(styles.aTag)}
            previousLinkClassName={clsx(styles.aTag)}
            nextLinkClassName={clsx(styles.aTag)}
        />
    );
}

export default Pagination;
