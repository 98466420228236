import clsx from 'clsx';
import React, { useCallback, useEffect, useState } from 'react';
import styles from './commit-changes-modal.module.css';
import { ICommitChange } from '../../types/commit-change.interface';
import { IMultiItemResponseData } from '../../types/response-data.interface';
import axiosService, { axiosErrorHandler } from '../../services/axios.service';
import axios from 'axios';
import dayjs from 'dayjs';

function CommitChangesModal({
    id,
    projectId,
    revision,
    firstOpened,
    author,
    date,
    message
}: {
    id: string;
    projectId: string;
    revision: number;
    firstOpened: boolean;
    author: string;
    date: Date;
    message: string;
}) {
    const [isLoading, setIsLoading] = useState(false);
    const [changes, setChanges] = useState<ICommitChange[]>([]);

    const fetchCommitChanges = useCallback(async (projectId: string, revision: number) => {
        try {
            setIsLoading(true);
            const { data }: { data: IMultiItemResponseData<ICommitChange> } = (
                await axiosService.get(`projects/${projectId}/commits/${revision}`)
            ).data;
            setChanges(data.items);
        } catch (error) {
            if (axios.isAxiosError(error)) {
                axiosErrorHandler(error);
            } else {
                console.log('error:  ', error);
            }
        } finally {
            setIsLoading(false);
        }
    }, []);

    useEffect(() => {
        if (firstOpened) {
            fetchCommitChanges(projectId, revision);
        }
    }, [projectId, revision, firstOpened, fetchCommitChanges]);

    return (
        <div
            className={clsx('modal fade')}
            id={id}
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            tabIndex={-1}
            aria-labelledby="staticBackdropLabel"
            aria-hidden="true">
            <div className="modal-dialog">
                <div className={clsx('modal-content', styles.modalWrapper)}>
                    <div className="modal-header">
                        <h5 className="modal-title" id="staticBackdropLabel">
                            Commit {revision}
                        </h5>
                        <button
                            type="button"
                            className={clsx('btn-close')}
                            data-bs-dismiss="modal"
                            aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <div className="d-flex">
                            <p className={clsx(styles.fixedElement, styles.typeWrapper)}>Author:</p>
                            <p className={clsx(styles.expandingElement, styles.text)}>{author}</p>
                        </div>
                        <div className="d-flex">
                            <p className={clsx(styles.fixedElement, styles.typeWrapper)}>Date:</p>
                            <p className={clsx(styles.expandingElement, styles.text)}>
                                {dayjs(date.toString()).format('DD/MM/YYYY hh:mm:ss')}
                            </p>
                        </div>
                        <div className="d-flex">
                            <p className={clsx(styles.fixedElement, styles.typeWrapper)}>
                                Message:
                            </p>
                            <p className={clsx(styles.expandingElement, styles.text)}>{message}</p>
                        </div>
                        {isLoading ? (
                            <div className="d-flex justify-content-center">
                                <div className="spinner-border" role="status"></div>
                            </div>
                        ) : (
                            changes.map((c, i) => (
                                <div className="d-flex">
                                    <div className={clsx(styles.typeWrapper)}>
                                        <div
                                            className={clsx(
                                                styles.fixedElement,
                                                styles.type,
                                                c.type === 'A' && styles.add,
                                                c.type === 'M' && styles.modify,
                                                c.type === 'D' && styles.delete,
                                                c.type === 'MM' && styles.doubleM
                                            )}>
                                            <p>{c.type}</p>
                                        </div>
                                    </div>
                                    <div className={clsx(styles.expandingElement, styles.path)}>
                                        {c.path}
                                    </div>
                                </div>
                            ))
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CommitChangesModal;
