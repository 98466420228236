import clsx from 'clsx';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import styles from './browser-search.module.css';
import { cCharacterIcon, findIcon, mCharacterIcon } from '../../assets/icons';
import { IMultiItemResponseData } from '../../types/response-data.interface';
import axiosService, { axiosErrorHandler } from '../../services/axios.service';
import axios from 'axios';
import { debounce } from 'lodash';
import { EBrowserSearchAction } from '../../reducers/brower-search.reducer';
import { IBrowserSearchResult } from '../../types/browser-search-result.interface';

function BrowserSearch({
    projectId,
    path,
    cacheOptions = true,
    browserSearchDispatch
}: {
    projectId: string;
    path: string;
    cacheOptions?: boolean;
    browserSearchDispatch: Function;
}) {
    // const [, browserSearchDispatch] = useBrowserSearchReducer();
    const [caseInsensitive, setCaseInsensitive] = useState(false);
    const [matchWholeWord, setMatchWholeWord] = useState(false);
    const [input, setInput] = useState('');
    // cache loaded option
    const cache = useRef<{ [key: string]: IBrowserSearchResult[] }>({});

    const loadOptions = useCallback(
        async (
            inputText: string,
            path: string,
            caseInsensitive: boolean,
            matchWholeWord: boolean
        ): Promise<IBrowserSearchResult[]> => {
            try {
                const { data }: { data: IMultiItemResponseData<IBrowserSearchResult> } = (
                    await axiosService.get(
                        `fifos/find?prj=${projectId}&text=${inputText}&path=${path}&caseInsensitive=${caseInsensitive}&matchWholeWord=${matchWholeWord}`
                    )
                ).data;
                return data.items;
            } catch (error) {
                if (axios.isAxiosError(error)) {
                    axiosErrorHandler(error);
                } else {
                    console.log('error:  ', error);
                }
                return [];
            }
        },
        [projectId]
    );

    // handle load options
    const handleLoadOptions = useCallback(
        debounce(
            async (
                inputText: string,
                path: string,
                caseInsensitive: boolean,
                matchWholeWord: boolean
            ) => {
                // hide search result when input text  is empty
                if (inputText.length === 0) {
                    browserSearchDispatch({
                        type: EBrowserSearchAction.SET_IS_SHOW_SEARCH_RESULT,
                        payload: { isShowSearchResult: false }
                    });
                    return;
                }

                // show search result
                browserSearchDispatch({
                    type: EBrowserSearchAction.SET_SEARCH_TEXT,
                    payload: { searchText: inputText }
                });
                browserSearchDispatch({
                    type: EBrowserSearchAction.SET_IS_SHOW_SEARCH_RESULT,
                    payload: { isShowSearchResult: true }
                });
                inputText = inputText.replaceAll(/[^a-zA-Z0-9_\-/. ]/g, '');
                if (inputText.length === 0) {
                    browserSearchDispatch({
                        type: EBrowserSearchAction.SET_SEARCH_RESULT,
                        payload: { searchResults: [] }
                    });
                } else {
                    if (
                        cacheOptions &&
                        cache.current[
                            `${inputText}|${path}|${caseInsensitive ? 'C' : 'c'}${matchWholeWord ? 'M' : 'm'}`
                        ]
                    ) {
                        // setResult(cache.current[`${inputText}|${path}|${caseInsensitive ? 'C' : 'c'}${matchWholeWord ? 'M' : 'm'}`]);
                        browserSearchDispatch({
                            type: EBrowserSearchAction.SET_SEARCH_RESULT,
                            payload: {
                                searchResults:
                                    cache.current[
                                        `${inputText}|${path}|${caseInsensitive ? 'C' : 'c'}${matchWholeWord ? 'M' : 'm'}`
                                    ]
                            }
                        });
                    } else {
                        // set isloading true
                        browserSearchDispatch({
                            type: EBrowserSearchAction.SET_IS_LOADING,
                            payload: { isLoadingSearchResult: true }
                        });

                        const res = await loadOptions(
                            inputText,
                            path,
                            caseInsensitive,
                            matchWholeWord
                        );
                        // set isloading false
                        browserSearchDispatch({
                            type: EBrowserSearchAction.SET_IS_LOADING,
                            payload: { isLoadingSearchResult: false }
                        });

                        // set result = res;
                        browserSearchDispatch({
                            type: EBrowserSearchAction.SET_SEARCH_RESULT,
                            payload: {
                                searchResults: res
                            }
                        });

                        if (cacheOptions) {
                            cache.current[
                                `${inputText}|${path}|${caseInsensitive ? 'C' : 'c'}${matchWholeWord ? 'M' : 'm'}`
                            ] = res;
                        }
                    }
                }
            },
            1000
        ),
        []
    );

    // clear search result when change path
    useEffect(() => {
        browserSearchDispatch({
            type: EBrowserSearchAction.SET_SEARCH_RESULT,
            payload: {
                searchResults: []
            }
        });
        setInput('');
    }, [path, browserSearchDispatch]);

    return (
        <div className={clsx(styles.browserSearch)}>
            <input
                value={input}
                type="text"
                placeholder={`Find in ${path}`}
                className={clsx(styles.input, 'form-control')}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    handleLoadOptions(e.target.value, path, caseInsensitive, matchWholeWord);
                    setInput(e.target.value);
                }}
            />
            <img src={findIcon} alt="" className={clsx(styles.findIcon)} />
            <img
                src={cCharacterIcon}
                alt=""
                className={clsx(styles.cIcon, caseInsensitive && styles.activeIcon)}
                title="Case Insensitive"
                onClick={(e: React.MouseEvent<HTMLElement>) => {
                    e.stopPropagation();
                    setCaseInsensitive(!caseInsensitive);
                    handleLoadOptions(input, path, !caseInsensitive, matchWholeWord);
                }}
            />
            <img
                src={mCharacterIcon}
                alt=""
                className={clsx(styles.mIcon, matchWholeWord && styles.activeIcon)}
                title="Match Whole Word"
                onClick={(e: React.MouseEvent<HTMLElement>) => {
                    e.stopPropagation();
                    setMatchWholeWord(!matchWholeWord);
                    handleLoadOptions(input, path, caseInsensitive, !matchWholeWord);
                }}
            />
        </div>
    );
}

export default BrowserSearch;
