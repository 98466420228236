import React, { useCallback, useState } from 'react';
import { IUser } from '../../types/user.interface';
import clsx from 'clsx';
import styles from './reset-password-modal.module.css';
import axios from 'axios';
import axiosService, { axiosErrorHandler } from '../../services/axios.service';
import { copyIcon } from '../../assets/icons';
import { Tooltip } from 'react-tooltip';

function ResetPasswordModal({ user }: { user: IUser }) {
    const [isLoading, setIsLoading] = useState(false);
    const [password, setPassword] = useState<string | null>(null);

    const [copyPasswordText, setCopyPasswordText] = useState('Copy password');

    const handleCopyPassword = useCallback((password: string) => {
        navigator.clipboard.writeText(password);
        setCopyPasswordText('Copied!');
        setTimeout(() => {
            setCopyPasswordText('Copy password');
        }, 3000);
    }, []);

    const resetPassword = useCallback(async (userId: string) => {
        try {
            setIsLoading(true);
            const { data }: { data: string } = (
                await axiosService.get(`auth/reset-password/${userId}`)
            ).data;
            console.log(data);
            setPassword(data);
        } catch (error) {
            if (axios.isAxiosError(error)) {
                axiosErrorHandler(error);
            } else {
                console.log('error:  ', error);
            }
        } finally {
            setIsLoading(false);
        }
    }, []);

    return (
        <div
            className="modal fade"
            id={`resetPassModal-${user.id}`}
            tabIndex={-1}
            aria-labelledby="exampleModalLabel"
            aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">
                            Reset {user.username}'s password
                        </h5>
                        <button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        {password ? (
                            <div className="form-group">
                                <label
                                    htmlFor="newPass"
                                    className={clsx('form-label', styles.label)}>
                                    New password
                                </label>
                                <div className="d-flex">
                                    <input
                                        type="password"
                                        className={clsx('form-control me-1')}
                                        id="newPass"
                                        value={password}
                                        disabled
                                    />
                                    <Tooltip
                                        className={clsx(styles.tooltip)}
                                        anchorSelect="#copyPassword">
                                        {copyPasswordText}
                                    </Tooltip>
                                    <button
                                        className={clsx(styles.copyButton, 'btn')}
                                        id="copyPassword"
                                        onClick={(e: React.MouseEvent) => {
                                            e.stopPropagation();
                                            handleCopyPassword(password);
                                        }}>
                                        <img src={copyIcon} alt="" />
                                    </button>
                                </div>
                            </div>
                        ) : (
                            <>
                                <p>Do you want to reset {user.username}'s password?</p>
                                <button
                                    type="button"
                                    className="btn btn-success w-100 mt-3"
                                    onClick={(e: React.MouseEvent) => {
                                        e.stopPropagation();
                                        resetPassword(user.id);
                                    }}>
                                    {isLoading ? 'Resetting...' : 'Reset'}
                                </button>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ResetPasswordModal;
