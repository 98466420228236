import clsx from 'clsx';
import React from 'react';
import styles from './browser-search-result.module.css';
import { IBrowserSearchResult } from '../../types/browser-search-result.interface';
import { documentIcon, folderIcon } from '../../assets/icons';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
dayjs.extend(relativeTime);

function BrowserSearchResult({
    isLoading,
    searchResults,
    projectId,
    path
}: {
    isLoading: boolean;
    searchResults: IBrowserSearchResult[];
    projectId: string;
    path: string;
}) {
    return isLoading ? (
        <div className="w-100 d-flex justify-content-center">
            <div className="spinner-border text-secondary"></div>
        </div>
    ) : searchResults.length > 0 ? (
        <table className={clsx('table table-hover', styles.table)}>
            <thead className={clsx(styles.tableHead)}>
                <tr>
                    <th>Name</th>
                    <th>Last commit</th>
                    <th>Last commit date</th>
                    <th>Last commit message</th>
                </tr>
            </thead>
            <tbody className={clsx(styles.tableBody)}>
                {searchResults.map((s, i) => (
                    <tr key={i}>
                        <td>
                            <div className="d-flex align-items-center">
                                {s.path[s.path.length - 1] === '/' ? (
                                    <img
                                        src={folderIcon}
                                        alt=""
                                        style={{ width: 16, height: 16 }}
                                    />
                                ) : (
                                    <img
                                        src={documentIcon}
                                        alt=""
                                        style={{ width: 16, height: 16 }}
                                    />
                                )}
                                <p
                                    className={clsx(styles.theLink, 'm-0 ms-2')}
                                    onClick={(e: React.MouseEvent<HTMLElement>) => {
                                        e.stopPropagation();
                                        window.location.href = `/projects/${projectId}/browser?path=${path}${s.path.substring(1)}`;
                                    }}>
                                    {s.path}
                                </p>
                            </div>
                        </td>
                        <td>
                            {s.revision.revision} by {s.revision.author}
                        </td>
                        <td>{dayjs(s.revision.date).fromNow().toString()}</td>
                        <td>{s.revision.msg}</td>
                    </tr>
                ))}
            </tbody>
        </table>
    ) : (
        <div className={clsx(styles.noContent)}>
            <p className={clsx(styles.noContent_text)}>no matching results found</p>
        </div>
    );
}

export default BrowserSearchResult;
