import React, { useCallback, useEffect, useState } from 'react';
import { ICommit } from '../../types/commit.interface';
import { IMultiItemResponseData } from '../../types/response-data.interface';
import axiosService, { axiosErrorHandler } from '../../services/axios.service';
import axios from 'axios';
import clsx from 'clsx';
import styles from './commit-table.module.css';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { changeListIcon } from '../../assets/icons';
import CommitChangesModal from '../commit-changes-modal/commit-changes-modal';
dayjs.extend(relativeTime);

function CommitTable({
    projectId,
    from,
    to,
    limit,
    showMessage = false
}: {
    projectId: string;
    from?: number;
    to?: number;
    limit?: number;
    showMessage?: boolean;
}) {
    const [firstOpened, setFirstOpened] = useState<boolean[]>([]);
    const openEdit = useCallback(
        (i: number) => {
            firstOpened[i] = true;
            setFirstOpened([...firstOpened]);
        },
        [firstOpened]
    );

    const [isLoading, setIsLoading] = useState(false);
    const [commits, setCommits] = useState<ICommit[]>([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                setIsLoading(true);
                let query = `projects/${projectId}/commits?`;
                from && (query += `from=${from}&`);
                to && (query += `to=${to}&`);
                limit && (query += `limit=${limit}&`);

                const { data }: { data: IMultiItemResponseData<ICommit> } = (
                    await axiosService.get(query)
                ).data;
                const { items } = data;
                setCommits(items);
                setFirstOpened(items.map(() => false));
            } catch (error) {
                setCommits([]);
                if (axios.isAxiosError(error)) {
                    axiosErrorHandler(error);
                } else {
                    console.log('error:  ', error);
                }
            } finally {
                setIsLoading(false);
            }
        };
        fetchData();
    }, [projectId, from, to, limit]);

    return (
        <table className={clsx(styles.table)}>
            <thead className={clsx(styles.tableHead)}>
                <tr>
                    <th className={clsx(styles.firstCol)}>Revision</th>
                    <th>Author</th>
                    <th>Date</th>
                    {showMessage && (
                        <>
                            <th>Message</th>
                            <th className={clsx(styles.lastCol)}>Action</th>
                        </>
                    )}
                </tr>
            </thead>
            <tbody className={clsx()}>
                {isLoading ? (
                    <></>
                ) : (
                    commits.map((c, i) => (
                        <tr
                            key={i}
                            onClick={(e) => {}}
                            style={{ cursor: 'pointer' }}
                            title={showMessage ? undefined : c.msg}
                            className={clsx(styles.tableRow)}>
                            <td className={clsx(styles.firstCol)}>
                                <p className="m-0">{c.revision}</p>
                            </td>
                            <td>
                                <p className="m-0">{c.author}</p>
                            </td>
                            <td>
                                <p className="m-0">{dayjs(c.date).fromNow().toString()}</p>
                            </td>
                            {showMessage && (
                                <>
                                    <td>
                                        <p className="m-0">
                                            {typeof c.msg === 'string' ? c.msg : null}
                                        </p>
                                    </td>
                                    <th className={clsx(styles.lastCol)}>
                                        <img
                                            src={changeListIcon}
                                            alt=""
                                            style={{ width: 20, height: 20, cursor: 'pointer' }}
                                            className="me-2"
                                            onClick={(e: React.MouseEvent<HTMLElement>) => {
                                                e.stopPropagation();
                                                openEdit(i);
                                            }}
                                            title="View change list"
                                            data-bs-toggle="modal"
                                            data-bs-target={`#viewCommitChanges${c.revision}`}
                                        />
                                        <CommitChangesModal
                                            id={`viewCommitChanges${c.revision}`}
                                            projectId={projectId}
                                            revision={c.revision}
                                            firstOpened={firstOpened[i]}
                                            author={c.author}
                                            date={c.date}
                                            message={c.msg}
                                        />
                                    </th>
                                </>
                            )}
                        </tr>
                    ))
                )}
            </tbody>
        </table>
    );
}

export default CommitTable;
