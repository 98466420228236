import clsx from 'clsx';
import React, { useState } from 'react';
import styles from './sidebar.module.css';
import { Link, useLocation } from 'react-router-dom';
import {
    homeIcon,
    projectIcon,
    manageUserIcon,
    manageProjectIcon,
    sidebarIcon,
    manageEmailIcon
} from '../../../assets/icons';
import { useAuth } from '../../../providers/auth.provider';
import { EUserRole, ILoggedInUser } from '../../../types/login.interface';
import { LocalStorageService } from '../../../services/localStorage.service';

function Sidebar() {
    const [open, setOpen] = useState(LocalStorageService.get('sidebar') === 'expanded');
    const handleResize = () => {
        LocalStorageService.set('sidebar', open ? 'collapsed' : 'expanded');
        setOpen(!open);
    };

    const { user }: { user: ILoggedInUser } = useAuth();
    const location = useLocation();

    return (
        <div className={clsx(styles.wrapper, open && styles.wrapper_open)}>
            <div className="h-100 d-flex flex-column justify-content-between align-align-items-center">
                <div className="">
                    <Link
                        to="/"
                        className={clsx(
                            'text-decoration-none mb-1 d-flex justify-content-start align-items-center',
                            styles.item,
                            location.pathname.split('/')[1] === '' && styles.item_active
                        )}>
                        <div className="d-flex justify-content-start align-items-center overflow-hidden ">
                            <img src={homeIcon} alt="" className={clsx(styles.item_icon, 'me-3')} />
                            <p className={clsx(styles.item_text)}>Home</p>
                        </div>
                    </Link>
                    <Link
                        to="/projects"
                        className={clsx(
                            'text-decoration-none mb-1 d-flex justify-content-start align-items-center',
                            styles.item,
                            location.pathname.split('/')[1] === 'projects' && styles.item_active
                        )}>
                        <div className="d-flex justify-content-start align-items-center overflow-hidden ">
                            <img
                                src={projectIcon}
                                alt=""
                                className={clsx(styles.item_icon, 'me-3')}
                            />
                            <p className={clsx(styles.item_text)}>Projects</p>
                        </div>
                    </Link>
                    {user.role === EUserRole.SYSTEM_ADMIN && (
                        <>
                            <Link
                                to="/admin/users"
                                className={clsx(
                                    'text-decoration-none mb-1 d-flex justify-content-start align-items-center',
                                    styles.item,
                                    location.pathname.split('/')[1] === 'admin' &&
                                        location.pathname.split('/')[2] === 'users' &&
                                        styles.item_active
                                )}>
                                <div className="d-flex justify-content-start align-items-center overflow-hidden ">
                                    <img
                                        src={manageUserIcon}
                                        alt=""
                                        className={clsx(styles.item_icon, 'me-3')}
                                    />
                                    <p className={clsx(styles.item_text)}>Manage users</p>
                                </div>
                            </Link>
                            <Link
                                to="/admin/projects"
                                className={clsx(
                                    'text-decoration-none mb-1 d-flex justify-content-start align-items-center',
                                    styles.item,
                                    location.pathname.split('/')[1] === 'admin' &&
                                        location.pathname.split('/')[2] === 'projects' &&
                                        styles.item_active
                                )}>
                                <div className="d-flex justify-content-start align-items-center overflow-hidden ">
                                    <img
                                        src={manageProjectIcon}
                                        alt=""
                                        className={clsx(styles.item_icon, 'me-3')}
                                    />
                                    <p className={clsx(styles.item_text)}>Manage projects</p>
                                </div>
                            </Link>
                            <Link
                                to="/admin/emails"
                                className={clsx(
                                    'text-decoration-none mb-1 d-flex justify-content-start align-items-center',
                                    styles.item,
                                    location.pathname.split('/')[1] === 'admin' &&
                                        location.pathname.split('/')[2] === 'emails' &&
                                        styles.item_active
                                )}>
                                <div className="d-flex justify-content-start align-items-center overflow-hidden ">
                                    <img
                                        src={manageEmailIcon}
                                        alt=""
                                        className={clsx(styles.item_icon, 'me-3')}
                                    />
                                    <p className={clsx(styles.item_text)}>Manage emails</p>
                                </div>
                            </Link>
                        </>
                    )}
                </div>
                <div className="">
                    <img
                        src={sidebarIcon}
                        alt=""
                        style={{ width: 30, height: 30, cursor: 'pointer', margin: 5 }}
                        onClick={(e: React.MouseEvent) => {
                            e.stopPropagation();
                            handleResize();
                        }}
                    />
                </div>
            </div>
        </div>
    );
}

export default Sidebar;
