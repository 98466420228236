import React from 'react';

type PropTypes = {
    loading: boolean;
    value: number;
};

const VisualizejsProgress: React.FC<PropTypes> = ({ loading, value }) => {
    return loading ? (
        <div
            className="d-flex flex-column justify-content-center align-items-center"
            style={{ zIndex: 90, position: 'absolute', top: '50%', left: '50%' }}>
            <div className="spinner-border text-success" role="status">
                <span className="visually-hidden">Loading...</span>
            </div>
            {/* <div>{`${value}%`}</div> */}
        </div>
    ) : (
        <></>
    );
};

export default VisualizejsProgress;
