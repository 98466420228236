import React from 'react';
import clsx from 'clsx';
import styles from './popup-properties.module.css';
import { dragElement } from '../../events/dragable-popup.event';

type PropTypes = {
    properties: any;
};

const PopupProperties: React.FC<PropTypes> = ({ properties }) => {
    React.useEffect(() => {
        dragElement(document.getElementById('popup-properties-container'));
    }, []);
    return (
        <div className={clsx(styles.PopupPropertiesContainer)} id="popup-properties-container">
            <h4 id="popup-properties-container-header">Properties</h4>
            <div className={clsx(styles.PropertiesWrapContent)}>
                {Object.keys(properties).map((key, index) => (
                    <div
                        className={clsx(
                            styles.PropertiesContentRow,
                            index % 2 ? styles.RowEven : styles.RowOdd
                        )}>
                        <span>{key}</span>
                        <span>{properties[key]}</span>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default PopupProperties;
