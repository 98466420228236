import React from 'react';
import { IAssemblies } from '../types/open-design.interface';

export interface IState {
    fileViewer: {
        fileId?: string;
        projectId?: string;
        path: string;
        isAssemblies?: boolean;
    };
    detailAssembly: IAssemblies;
}

export enum EOpenDesign {
    VIEW_FILE = 1,
    DISPOSE_VIEW3D = 2,
    GET_DETAIL_ASSEMBLY = 3
}

export interface IAction {
    type: EOpenDesign;
    payload: any;
}

export const reducer = (state: IState, action: IAction) => {
    switch (action.type) {
        case EOpenDesign.VIEW_FILE:
            (() => {
                state.fileViewer = action.payload.file;
            })();
            break;
        case EOpenDesign.DISPOSE_VIEW3D:
            (() => {
                state.fileViewer = {
                    path: '',
                    fileId: '',
                    projectId: ''
                };
            })();
            break;
        case EOpenDesign.GET_DETAIL_ASSEMBLY:
            (() => {
                state.detailAssembly = {
                    ...action.payload.assembly
                };
            })();
            break;
    }

    return { ...state };
};

const initState: IState = {
    fileViewer: {
        path: '',
        fileId: '',
        projectId: '',
        isAssemblies: false
    },
    detailAssembly: {}
};

export const useOpenDesignReducer = () => {
    return React.useReducer(reducer, initState);
};
