import { OpenDesign } from '../../utils/api-path.util';
import axiosService from '../axios.service';

export const downloadFile = async (
    fileId: string,
    database?: string,
    isAssemblies: boolean = false
) => {
    try {
        const res = await axiosService.get(
            OpenDesign({ fileId: fileId, database: database }, { assemblies: isAssemblies })
                .DOWNLOAD_FILE,
            { responseType: 'arraybuffer' }
        );
        return res.data;
    } catch (error) {
        console.log(error);
        throw new Error();
    }
};
