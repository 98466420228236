import { OpenDesign } from '../../utils/api-path.util';
import axiosService from '../axios.service';

export const openDesignGetProperties = async (
    fileId: string,
    query: { handles: string; version: string; isAssemblies: boolean }
) => {
    try {
        const { data } = await axiosService.get(
            OpenDesign(
                { fileId: fileId },
                { handles: query.handles, version: query.version, assemblies: query.isAssemblies }
            ).GET_PROPERTIES
        );

        return data.data;
    } catch (error) {
        console.log(error);
    }
};
