import React from 'react';
import clsx from 'clsx';
import styles from './manage-items-table.module.css';
import { cancelIcon } from '../../assets/icons';

// TODO: optional type required id
function ManageItemsTable<T>({
    items,
    setItems,
    editable,
    columns
}: {
    items: T[];
    setItems: Function;
    editable: boolean;
    columns: string[];
}) {
    const handleRemoveItems = (itemId: string) => {
        setItems(items.filter((item) => (item as any).id !== itemId));
    };

    return items.length > 0 ? (
        <table className={clsx('table table-hover', styles.table)}>
            <thead className={clsx('')}>
                <tr>
                    {columns.map((c, columnIndex) => (
                        <th key={columnIndex}>{c.charAt(0).toUpperCase() + c.slice(1)}</th>
                    ))}
                    {editable && <th style={{ width: 50 }}>Action</th>}
                </tr>
            </thead>
            <tbody className={clsx(styles.tableBody)}>
                {items.map((item, i) => (
                    <tr key={i}>
                        {columns.map((c, columnIndex) => (
                            <td key={columnIndex}>
                                <p className="m-0">{(item as any)[c]}</p>
                            </td>
                        ))}
                        {editable && (
                            <td className="d-flex justify-content-center align-items-center  ">
                                <img
                                    src={cancelIcon}
                                    alt=""
                                    style={{ width: 16, height: 16, cursor: 'pointer' }}
                                    onClick={(e: React.MouseEvent<HTMLElement>) => {
                                        e.stopPropagation();
                                        handleRemoveItems((item as any).id);
                                    }}
                                />
                            </td>
                        )}
                    </tr>
                ))}
            </tbody>
        </table>
    ) : null;
}

export default ManageItemsTable;
