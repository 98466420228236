import React, { createContext, useContext, useMemo, useState } from 'react';
import { AuthStorageService } from '../services/authStorage.service';
import { ILoggedInUser } from '../types/login.interface';

const AuthContext = createContext<any>(null);

const AuthProvider = ({ children }: { children: React.ReactNode }) => {
    const [user, setUser] = useState<ILoggedInUser>(AuthStorageService.getLoginUser());

    const contextValue = useMemo(
        () => ({
            user,
            setUser
        }),
        [user]
    );

    return <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
    return useContext(AuthContext);
};

export default AuthProvider;
