import clsx from 'clsx';
import React, { useCallback, useEffect, useState } from 'react';
import styles from './emails-admin-page.module.css';
import { IoSearchSharp } from 'react-icons/io5';
import Pagination from '../../../components/pagination/pagination';
import { useSearchParams } from 'react-router-dom';
import { IEmail } from '../../../types/email.interface';
import { IMultiItemResponseData } from '../../../types/response-data.interface';
import axiosService, { axiosErrorHandler } from '../../../services/axios.service';
import axios from 'axios';
import EmailTable from '../../../components/email-table/email-table';
import CreateEmailModal from '../../../components/create-email-modal/create-email-modal';

const limit = parseInt(process.env.REACT_APP_PAGINATION_LIMIT as string) | 12;

function EmailsAdminPage() {
    let [searchParams, setSearchParams] = useSearchParams();
    const [emails, setEmails] = useState<IEmail[]>([]);
    const [page, setPage] = useState(
        searchParams.get('page') ? parseInt(searchParams.get('page') as string) : 1
    );
    const [total, setTotal] = useState(0);
    const [isLoading, setIsLoading] = useState(false);

    const fecthEmails = useCallback(async (page: number) => {
        try {
            setIsLoading(true);
            const { data }: { data: IMultiItemResponseData<IEmail> } = (
                await axiosService.get(`emails?page=${page}&limit=${limit}&sortBy=-createdAt`)
            ).data;
            const { items, total } = data;
            setEmails(items);
            setTotal(total);
        } catch (error) {
            setEmails([]);
            if (axios.isAxiosError(error)) {
                axiosErrorHandler(error);
            } else {
                console.log('error:  ', error);
            }
        } finally {
            setIsLoading(false);
        }
    }, []);

    useEffect(() => {
        fecthEmails(page);
    }, [page, fecthEmails]);

    const onDeleteEmail = useCallback(() => {
        fecthEmails(page);
    }, [fecthEmails, page]);

    const handleChangePage = useCallback(
        (page: number) => {
            setPage(page);
            setSearchParams({ page: page.toString() });
        },
        [setSearchParams]
    );

    const onCreateSuccess = useCallback(() => {
        if (page === 1) {
            fecthEmails(1);
        } else {
            handleChangePage(1);
        }
    }, [handleChangePage, fecthEmails, page]);

    return (
        <div className={clsx('p-3')}>
            <div className={clsx(styles.mainContent)}>
                <div
                    className={clsx(
                        styles.heading,
                        'd-flex justify-content-between align-items-center'
                    )}>
                    <div className={clsx(styles.titles)}>Manage Mailer</div>
                    <div className="d-flex">
                        <div className={clsx(styles.searchBar)}>
                            <IoSearchSharp className={clsx(styles.searchBarIcon)} />
                            <div className="form-group">
                                <input
                                    type="text"
                                    className={clsx('form-control', styles.searchBarInput)}
                                    id="search"
                                    placeholder="Search Mailer"
                                />
                            </div>
                        </div>
                        <button
                            className={clsx(styles.addNewButton, 'btn btn-success')}
                            type="button"
                            data-bs-toggle="modal"
                            data-bs-target="#createEmailModal">
                            Add mailer
                        </button>
                        <CreateEmailModal id="createEmailModal" onCreateSuccess={onCreateSuccess} />
                    </div>
                </div>
                <EmailTable
                    emails={emails}
                    setEmails={setEmails}
                    isLoading={isLoading}
                    onDeleteEmail={onDeleteEmail}
                />
                <div className={clsx(styles.optionBlock)}>
                    <Pagination
                        page={page}
                        handleChangePage={handleChangePage}
                        total={total}
                        limit={limit}
                    />
                </div>
            </div>
        </div>
    );
}

export default EmailsAdminPage;
