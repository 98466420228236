import clsx from 'clsx';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import styles from './update-project-modal.module.css';
import { useNavigate } from 'react-router-dom';
import { IProject } from '../../types/project.interface';
import axiosService, { axiosErrorHandler } from '../../services/axios.service';
import { IUser } from '../../types/user.interface';
import axios from 'axios';
import Swal from 'sweetalert2';
import { IGroup } from '../../types/group.interface';
import dayjs from 'dayjs';
import { addIcon, closeIcon, groupIcon } from '../../assets/icons';

function UpdateProjectModal({
    id,
    projectId,
    firstOpened,
    gotoProject,
    projectData,
    onUpdateSuccess
}: {
    id: string;
    projectId: string;
    firstOpened: boolean;
    gotoProject: boolean;
    projectData?: IProject;
    onUpdateSuccess?: Function;
}) {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [status, setStatus] = useState(1);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [isValidEndDate, setIsValidEndDate] = useState(true);
    const [inValidEndDateMessage, setInValidEndDateMessage] = useState('');
    const [location, setLocation] = useState('');
    const [admins, setAdmin] = useState<IUser[]>([]);
    const [defaultGroups, setDefaultGroups] = useState<IGroup[]>([]);
    const [customGroups, setCustomGroups] = useState<IGroup[]>([]);
    const [newGroups, setNewGroups] = useState<string[]>([]);

    const [newGroupInput, setNewGroupInput] = useState('');
    const [isValidNewGroupName, setIsValidNewGroupName] = useState(true);

    const newGroupInputRef = useRef(null);
    const closeButtonRef = useRef(null);

    const fetchProjectData = useCallback(async (projectId: string) => {
        try {
            setIsLoading(true);
            const { data }: { data: IProject } = (
                await axiosService.get(`projects/${projectId}?detail=true`)
            ).data;

            setName(data.name);
            setDescription(data.description);
            setStatus(data.status);
            setStartDate(data.startDate ? dayjs(data.startDate).format('YYYY-MM-DD') : '');
            setEndDate(data.endDate ? dayjs(data.endDate).format('YYYY-MM-DD') : '');
            setLocation(data.location || '');
            setAdmin(data.admins as IUser[]);
            setDefaultGroups(data.defaultGroups as IGroup[]);
            setCustomGroups(data.customGroups as IGroup[]);
        } catch (error) {
            if (axios.isAxiosError(error)) {
                axiosErrorHandler(error);
            } else {
                console.log('error:  ', error);
            }
        } finally {
            setIsLoading(false);
        }
    }, []);

    const resetValidate = useCallback(() => {
        setIsValidEndDate(true);
    }, []);

    const validateForm = useCallback(
        (startDate: string | undefined, endDate: string | undefined): boolean => {
            resetValidate();
            let isValid = true;
            if (startDate && endDate && startDate > endDate) {
                setIsValidEndDate(false);
                setInValidEndDateMessage('End date must greater than or equal start date');
                isValid = false;
            }
            return isValid;
        },
        [resetValidate]
    );

    useEffect(() => {
        if (projectData) {
            setName(projectData.name);
            setDescription(projectData.description);
            setStatus(projectData.status);
            setStartDate(
                projectData.startDate ? dayjs(projectData.startDate).format('YYYY-MM-DD') : ''
            );
            setEndDate(projectData.endDate ? dayjs(projectData.endDate).format('YYYY-MM-DD') : '');
            setLocation(projectData.location || '');
            setAdmin(projectData.admins as IUser[]);
            setDefaultGroups(projectData.defaultGroups as IGroup[]);
            setCustomGroups(projectData.customGroups as IGroup[]);
        } else {
            if (firstOpened) {
                fetchProjectData(projectId);
            }
        }
    }, [fetchProjectData, firstOpened, projectId, projectData]);

    const updateProject = useCallback(
        async (
            projectId: string,
            description: string,
            status: number,
            startDate: string,
            endDate: string,
            location: string,
            customGroups: IGroup[],
            newGroups: string[]
        ) => {
            try {
                setIsLoading(true);
                const { data }: { data: IProject } = (
                    await axiosService.patch(`projects/${projectId}`, {
                        description,
                        status,
                        startDate,
                        endDate,
                        location,
                        customGroups: customGroups.map((g) => g.id),
                        newGroups
                    })
                ).data;
                (closeButtonRef.current as any).click();
                if (gotoProject) {
                    Swal.fire({
                        title: 'Update project successfully',
                        text: 'Go to project?',
                        icon: 'success',
                        showConfirmButton: true,
                        confirmButtonText: 'Ok',
                        showCancelButton: true,
                        cancelButtonText: 'Stay here',
                        reverseButtons: true
                    }).then((result) => {
                        if (result.isConfirmed) {
                            navigate(`/projects/${projectId}`);
                        }
                    });
                } else {
                    Swal.fire({
                        title: 'Update project successfully',
                        icon: 'success',
                        showConfirmButton: true,
                        confirmButtonText: 'Ok'
                    }).then((result) => {
                        if (result.isConfirmed) {
                            onUpdateSuccess && onUpdateSuccess();
                        }
                    });
                }
            } catch (error) {
                if (axios.isAxiosError(error)) {
                    axiosErrorHandler(error);
                } else {
                    console.log('error:  ', error);
                }
            } finally {
                setIsLoading(false);
            }
        },
        [navigate, gotoProject, onUpdateSuccess]
    );

    const handleUpdateProject = useCallback(
        async (
            projectId: string,
            description: string,
            status: number,
            startDate: string,
            endDate: string,
            location: string,
            customGroups: IGroup[],
            newGroups: string[]
        ) => {
            if (validateForm(startDate, endDate)) {
                await updateProject(
                    projectId as string,
                    description,
                    status,
                    startDate,
                    endDate,
                    location,
                    customGroups,
                    newGroups
                );
            }
        },
        [validateForm, updateProject]
    );

    const handleDeleteCustomGroups = useCallback(
        (groupId: string) => {
            setCustomGroups(
                customGroups.filter((g) => {
                    return g.id !== groupId;
                })
            );
        },
        [customGroups]
    );

    const validateNewGroupName = useCallback(
        (groupName: string): boolean => {
            const names = [
                'Admins',
                ...defaultGroups.map((g) => g.name),
                ...customGroups.map((g) => g.name),
                ...newGroups
            ];
            return !names.includes(groupName);
        },
        [customGroups, defaultGroups, newGroups]
    );

    const handleAddNewGroup = useCallback(() => {
        if (newGroupInput.length > 0) {
            if (validateNewGroupName(newGroupInput)) {
                setIsValidNewGroupName(true);
                setNewGroups([...newGroups, newGroupInput]);
                setNewGroupInput('');
                newGroupInputRef.current && (newGroupInputRef.current as any).focus();
            } else {
                setIsValidNewGroupName(false);
            }
        }
    }, [newGroupInput, newGroups, validateNewGroupName]);

    const handleDeleteNewGroup = useCallback(
        (group: string) => {
            setNewGroups(newGroups.filter((g) => g !== group));
        },
        [newGroups]
    );

    return (
        <div
            className={clsx('modal modal-xl fade', styles.modalWrapper)}
            id={id}
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            tabIndex={-1}
            aria-labelledby="staticBackdropLabel"
            aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="staticBackdropLabel">
                            Update project
                        </h5>
                        <button
                            type="button"
                            className={clsx('btn-close')}
                            data-bs-dismiss="modal"
                            aria-label="Close"
                            ref={closeButtonRef}></button>
                    </div>
                    <div className="modal-body">
                        {isLoading ? (
                            <div className="d-flex justify-content-center">
                                <div className="spinner-border" role="status"></div>
                            </div>
                        ) : (
                            <div className={clsx(styles.form)}>
                                <div className="row">
                                    <div className="col-12 col-lg-6">
                                        <div className="form-group">
                                            <label
                                                className={clsx(
                                                    'form-label required',
                                                    styles.label
                                                )}>
                                                Name
                                            </label>
                                            <input
                                                type="text"
                                                className={clsx('form-control')}
                                                value={name}
                                                disabled
                                            />
                                        </div>
                                        <div className="form-group mt-3">
                                            <label className={clsx('form-label', styles.label)}>
                                                Description
                                            </label>
                                            <textarea
                                                className="form-control"
                                                rows={5}
                                                value={description}
                                                onChange={(
                                                    e: React.ChangeEvent<HTMLTextAreaElement>
                                                ) => {
                                                    setDescription(e.target.value);
                                                }}
                                            />
                                        </div>
                                        <div className="form-group mt-3">
                                            <label
                                                className={clsx(
                                                    'form-label required',
                                                    styles.label
                                                )}>
                                                Status
                                            </label>
                                            <select
                                                className="form-select"
                                                aria-label="Default select example"
                                                value={status}
                                                onChange={(
                                                    e: React.ChangeEvent<HTMLSelectElement>
                                                ) => {
                                                    e.stopPropagation();
                                                    setStatus(parseInt(e.target.value));
                                                }}>
                                                <option value={1}>Initialization</option>
                                                <option value={2}>In Progress</option>
                                                <option value={3}>Completed</option>
                                                <option value={4}>On Hold</option>
                                            </select>
                                        </div>

                                        <div className="form-group mt-3">
                                            <label className={clsx('form-label', styles.label)}>
                                                Start date
                                            </label>
                                            <input
                                                type="date"
                                                className={clsx('form-control')}
                                                value={startDate || ''}
                                                onChange={(
                                                    e: React.ChangeEvent<HTMLInputElement>
                                                ) => {
                                                    setStartDate(e.target.value);
                                                }}
                                            />
                                        </div>

                                        <div className="form-group mt-3">
                                            <label className={clsx('form-label', styles.label)}>
                                                End date
                                            </label>
                                            <input
                                                type="date"
                                                className={clsx(
                                                    'form-control',
                                                    !isValidEndDate && 'is-invalid'
                                                )}
                                                value={endDate || ''}
                                                onChange={(
                                                    e: React.ChangeEvent<HTMLInputElement>
                                                ) => {
                                                    setEndDate(e.target.value);
                                                }}
                                            />
                                            <div
                                                id="validationEndDate"
                                                className="invalid-feedback">
                                                {inValidEndDateMessage}
                                            </div>
                                        </div>

                                        <div className="form-group mt-3">
                                            <label className={clsx('form-label', styles.label)}>
                                                Location
                                            </label>
                                            <input
                                                type="text"
                                                className={clsx('form-control')}
                                                onChange={(
                                                    e: React.ChangeEvent<HTMLInputElement>
                                                ) => {
                                                    setLocation(e.currentTarget.value);
                                                }}
                                                value={location}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-12 col-lg-6">
                                        <div className="form-group mt-3 mt-lg-0">
                                            <label className={clsx('form-label', styles.label)}>
                                                Groups
                                            </label>
                                            <ul className={clsx(styles.groupList)}>
                                                <li className={clsx(styles.group)}>
                                                    <img
                                                        src={groupIcon}
                                                        alt=""
                                                        style={{
                                                            width: 18,
                                                            height: 18
                                                        }}
                                                        className="me-3"
                                                    />
                                                    Admins
                                                </li>
                                                {defaultGroups.map((g) => (
                                                    <li key={g.id} className={clsx(styles.group)}>
                                                        <img
                                                            src={groupIcon}
                                                            alt=""
                                                            style={{
                                                                width: 18,
                                                                height: 18
                                                            }}
                                                            className="me-3"
                                                        />
                                                        {g.name}
                                                    </li>
                                                ))}
                                                {customGroups.map((g) => (
                                                    <li key={g.id} className={clsx(styles.group)}>
                                                        <img
                                                            src={groupIcon}
                                                            alt=""
                                                            style={{
                                                                width: 18,
                                                                height: 18
                                                            }}
                                                            className="me-3"
                                                        />
                                                        {g.name}
                                                        <img
                                                            src={closeIcon}
                                                            className="ms-3"
                                                            alt=""
                                                            style={{
                                                                width: 14,
                                                                height: 14,
                                                                cursor: 'pointer'
                                                            }}
                                                            onClick={(e: React.MouseEvent) => {
                                                                e.stopPropagation();
                                                                handleDeleteCustomGroups(g.id);
                                                            }}
                                                        />
                                                    </li>
                                                ))}
                                                {newGroups.map((g) => (
                                                    <li key={g} className={clsx(styles.group)}>
                                                        <img
                                                            src={groupIcon}
                                                            alt=""
                                                            style={{
                                                                width: 18,
                                                                height: 18
                                                            }}
                                                            className="me-3"
                                                        />
                                                        {g}
                                                        <img
                                                            src={closeIcon}
                                                            className="ms-3"
                                                            alt=""
                                                            style={{
                                                                width: 14,
                                                                height: 14,
                                                                cursor: 'pointer'
                                                            }}
                                                            onClick={(e: React.MouseEvent) => {
                                                                e.stopPropagation();
                                                                handleDeleteNewGroup(g);
                                                            }}
                                                        />
                                                    </li>
                                                ))}
                                                <li
                                                    className={clsx(
                                                        'd-flex justify-content-start align-items-center'
                                                    )}>
                                                    <div className="form-group">
                                                        <input
                                                            placeholder="Create new group"
                                                            type="text"
                                                            className={clsx(
                                                                'form-control me-2',
                                                                !isValidNewGroupName && 'is-invalid'
                                                            )}
                                                            onChange={(
                                                                e: React.ChangeEvent<HTMLInputElement>
                                                            ) => {
                                                                setNewGroupInput(
                                                                    e.currentTarget.value
                                                                );
                                                            }}
                                                            value={newGroupInput}
                                                            ref={newGroupInputRef}
                                                            onKeyDown={(e: React.KeyboardEvent) => {
                                                                if (e.key === 'Enter') {
                                                                    handleAddNewGroup();
                                                                }
                                                            }}
                                                        />
                                                        <div
                                                            id="validationNewGroup"
                                                            className="invalid-feedback">
                                                            Group name has been duplicated
                                                        </div>
                                                    </div>
                                                    <img
                                                        src={addIcon}
                                                        alt=""
                                                        style={{
                                                            width: 20,
                                                            height: 20,
                                                            cursor: 'pointer'
                                                        }}
                                                        onClick={(e: React.MouseEvent) => {
                                                            e.stopPropagation();
                                                            handleAddNewGroup();
                                                        }}
                                                    />
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="w-100 pt-3">
                                    <button
                                        type="submit"
                                        className="btn btn-success float-end"
                                        disabled={isLoading}
                                        onClick={(e: React.MouseEvent) => {
                                            e.preventDefault();
                                            handleUpdateProject(
                                                projectId as string,
                                                description,
                                                status,
                                                startDate,
                                                endDate,
                                                location,
                                                customGroups,
                                                newGroups
                                            );
                                        }}>
                                        {isLoading ? 'Updating...' : 'Update'}
                                    </button>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default UpdateProjectModal;
