import clsx from 'clsx';
import React, { useCallback, useEffect, useState } from 'react';
import styles from './timeline-page.module.css';
import { IProject } from '../../types/project.interface';
import axiosService, { axiosErrorHandler } from '../../services/axios.service';
import { Link, useParams } from 'react-router-dom';
import axios from 'axios';
import CommitTable from '../../components/commit-table/commit-table';
import NotFoundPage from '../not-found-page';
import { Tooltip } from 'react-tooltip';
import { copyLinkIcon, folderTreeIcon } from '../../assets/icons';

function TimelinePage() {
    const { projectId } = useParams();
    const [project, setProject] = useState<IProject>();
    const [isNotFound, setIsNotFound] = useState(false);
    const [from, setFrom] = useState<number>();
    const [to, setTo] = useState<number>();
    const [currentFrom, setCurrentFrom] = useState<number>();
    const [currentTo, setCurrentTo] = useState<number>();

    const [copyIconText, setCopyIconText] = useState('Copy checkout url');

    const fetchProjectData = useCallback(async (projectId: string): Promise<boolean> => {
        try {
            const { data }: { data: IProject } = (
                await axiosService.get(`projects/${projectId}?fields=name`)
            ).data;
            setProject(data);
            return true;
        } catch (error) {
            if (axios.isAxiosError(error)) {
                if (error.response?.status === 404) {
                    setIsNotFound(true);
                } else {
                    axiosErrorHandler(error);
                }
            } else {
                console.log('error:  ', error);
            }
            return false;
        }
    }, []);

    useEffect(() => {
        fetchProjectData(projectId as string);
    }, [fetchProjectData, projectId]);

    const handleSearchCommit = useCallback((from: number | undefined, to: number | undefined) => {
        setCurrentFrom(from);
        setCurrentTo(to);
    }, []);

    const handleCopyCheckoutUrl = useCallback((projectName: string) => {
        navigator.clipboard.writeText(`${process.env.REACT_APP_SVN_URL}/${projectName}/`);
        setCopyIconText('Copied!');
        setTimeout(() => {
            setCopyIconText('Copy checkout url');
        }, 3000);
    }, []);

    return project ? (
        <div className="p-3">
            <div className="row">
                <div className="col-10">
                    <div className={clsx(styles.mainContent)}>
                        <div
                            className={clsx(
                                styles.heading,
                                'd-flex justify-content-between align-items-center'
                            )}>
                            <div className={clsx(styles.titles)}>{project.name}</div>
                            <div className="">
                                <Tooltip anchorSelect="#copyCheckoutUrlIcon">
                                    {copyIconText}
                                </Tooltip>
                                <img
                                    id="copyCheckoutUrlIcon"
                                    className="ms-0"
                                    src={copyLinkIcon}
                                    alt=""
                                    style={{ width: 25, height: 25, cursor: 'pointer' }}
                                    onClick={(e: React.MouseEvent) => {
                                        e.stopPropagation();
                                        handleCopyCheckoutUrl(project.name);
                                    }}
                                />
                                <Tooltip anchorSelect="#goToBrowser">Go to browser</Tooltip>
                                <Link to={`/projects/${projectId}/browser?path=/`}>
                                    <img
                                        id="goToBrowser"
                                        className="ms-2"
                                        src={folderTreeIcon}
                                        alt=""
                                        style={{ width: 25, height: 25, cursor: 'pointer' }}
                                        onClick={(e: React.MouseEvent<HTMLElement>) => {
                                            e.stopPropagation();
                                        }}
                                    />
                                </Link>
                            </div>
                        </div>
                        <CommitTable
                            projectId={projectId!}
                            limit={100}
                            showMessage={true}
                            from={currentFrom}
                            to={currentTo}
                        />
                    </div>
                </div>
                <div className="col-2">
                    <div className="form-group">
                        <label htmlFor="from" className={clsx('form-label', styles.label)}>
                            From (revision):
                        </label>
                        <input
                            type="number"
                            className={clsx('form-control')}
                            id="from"
                            value={from || ''}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                setFrom(
                                    !!e.target.value
                                        ? Math.abs(parseInt(e.target.value))
                                        : undefined
                                );
                            }}
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="to" className={clsx('form-label', styles.label)}>
                            To (revision):
                        </label>
                        <input
                            type="number"
                            className={clsx('form-control')}
                            id="to"
                            value={to || ''}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                setTo(
                                    !!e.target.value
                                        ? Math.abs(parseInt(e.target.value))
                                        : undefined
                                );
                            }}
                        />
                    </div>
                    <button
                        type="submit"
                        className="btn btn-success w-100 mt-3"
                        onClick={(e: React.MouseEvent) => {
                            e.stopPropagation();
                            handleSearchCommit(from, to);
                        }}>
                        Search
                    </button>
                </div>
            </div>
        </div>
    ) : isNotFound ? (
        <NotFoundPage />
    ) : null;
}

export default TimelinePage;
