import { memo } from 'react';
import DocViewer, { DocViewerRenderers } from 'react-doc-viewer';
import AnnotationToolBar from './annotation-toolbar';
import { Layer, Stage } from 'react-konva';

function MSOfficePdfFileView({ uri, fileType }: any) {
    const docs = [{ uri: uri, fileType: fileType }];

    return (
        // <div style={{ position: 'relative' }}>
        //     <AnnotationToolBar />

        <DocViewer
            documents={docs}
            pluginRenderers={DocViewerRenderers}
            config={{
                header: {
                    disableHeader: true,
                    disableFileName: true,
                    retainURLParams: false
                }
            }}
            className="pt-2"
            style={{ height: 'inherit' }}
        />

        //     <Stage width={200} height={200} style={{ position: 'absolute', top: 0, left: 0, pointerEvents: 'none' }}>
        //         <Layer>
        //             {/* Add your Konva shapes here */}
        //         </Layer>
        //     </Stage>
        // </div>
    );
}

function isEqual(prevProps: any, nextProps: any) {
    return prevProps.uri === nextProps.uri && prevProps.fileType === nextProps.fileType;
}

export default memo(MSOfficePdfFileView, isEqual);
