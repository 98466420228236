import React, { useCallback, useRef, useState } from 'react';
import axiosService, { axiosErrorHandler } from '../../services/axios.service';
import Swal from 'sweetalert2';
import axios from 'axios';
import { emailRegex } from '../../constants/regex.const';
import clsx from 'clsx';
import styles from './create-email-modal.module.css';

function CreateEmailModal({ id, onCreateSuccess }: { id: string; onCreateSuccess: Function }) {
    const [isLoading, setIsLoading] = useState(false);

    const [serverName, setServerName] = useState('');
    const [emailAddress, setEmailAddress] = useState('');
    const [isValidEmailAddress, setIsValidEmailAddress] = useState(true);
    const [invalidEmailMessage, setInvalidEmailMessage] = useState('');
    const [host, setHost] = useState('');
    const [isValidHost, setIsValidHost] = useState(true);
    const [port, setPort] = useState<number>();
    const [isValidPort, setIsValidPort] = useState(true);
    const [status, setStatus] = useState(1);
    const [password, setPassword] = useState('');
    const [isValidPassword, setIsValidPassword] = useState(true);

    const closeButtonRef = useRef(null);

    const resetForm = useCallback(() => {
        setServerName('');
        setEmailAddress('');
        setHost('');
        setPort(undefined);
        setStatus(1);
        setPassword('');
    }, []);

    const resetValidate = useCallback(() => {
        setIsValidEmailAddress(true);
        setIsValidHost(true);
        setIsValidPort(true);
        setIsValidPassword(true);
    }, []);

    const validateForm = useCallback(
        (emailAddress: string, host: string, port: number | undefined, password: string) => {
            resetValidate();
            let isValid = true;
            if (emailAddress.length === 0) {
                setIsValidEmailAddress(false);
                setInvalidEmailMessage('Missing email');
                isValid = false;
            }
            if (emailAddress.length > 0 && !emailRegex.test(emailAddress)) {
                setIsValidEmailAddress(false);
                setInvalidEmailMessage('Email is invalid');
                isValid = false;
            }
            if (host.length === 0) {
                setIsValidHost(false);
                isValid = false;
            }
            if (!port) {
                setIsValidPort(false);
                isValid = false;
            }
            if (password.length === 0) {
                setIsValidPassword(false);
                isValid = false;
            }
            return isValid;
        },
        [resetValidate]
    );

    const createNewEmail = useCallback(
        async (
            serverName: string,
            emailAddress: string,
            host: string,
            port: number,
            status: number,
            password: string
        ) => {
            try {
                setIsLoading(true);
                await axiosService.post('emails', {
                    serverName,
                    email: emailAddress,
                    password,
                    host,
                    port,
                    status
                });
                resetForm();
                (closeButtonRef.current as any).click();
                Swal.fire({
                    title: 'Create mailer successfully',
                    icon: 'success',
                    showConfirmButton: true,
                    confirmButtonText: 'Ok'
                }).then((result) => {
                    if (result.isConfirmed) {
                        onCreateSuccess();
                    }
                });
            } catch (error) {
                if (axios.isAxiosError(error)) {
                    axiosErrorHandler(error);
                } else {
                    console.log('error:  ', error);
                }
            } finally {
                setIsLoading(false);
            }
        },
        [onCreateSuccess, resetForm]
    );

    const handleCreateEmail = useCallback(
        async (
            serverName: string,
            emailAddress: string,
            host: string,
            port: number | undefined,
            status: number,
            password: string
        ) => {
            if (validateForm(emailAddress, host, port, password)) {
                await createNewEmail(serverName, emailAddress, host, port!, status, password);
            }
        },
        [createNewEmail, validateForm]
    );

    return (
        <div
            className={clsx('modal fade', styles.modalWrapper)}
            id={id}
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            tabIndex={-1}
            aria-labelledby="staticBackdropLabel"
            aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="staticBackdropLabel">
                            Create mailer
                        </h5>
                        <button
                            type="button"
                            className={clsx('btn-close')}
                            data-bs-dismiss="modal"
                            aria-label="Close"
                            ref={closeButtonRef}></button>
                    </div>
                    <div className="modal-body">
                        {isLoading ? (
                            <div className="d-flex justify-content-center">
                                <div className="spinner-border" role="status"></div>
                            </div>
                        ) : (
                            <div className={clsx(styles.form)}>
                                <div className="form-group">
                                    <label className={clsx('form-label', styles.label)}>
                                        Server name
                                    </label>
                                    <input
                                        type="text"
                                        className={clsx('form-control')}
                                        value={serverName}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                            setServerName(e.target.value);
                                        }}
                                    />
                                </div>
                                <div className="form-group mt-3">
                                    <label className={clsx('form-label required', styles.label)}>
                                        Email
                                    </label>
                                    <input
                                        type="email"
                                        className={clsx(
                                            'form-control',
                                            !isValidEmailAddress && 'is-invalid'
                                        )}
                                        value={emailAddress}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                            setEmailAddress(e.target.value);
                                        }}
                                    />
                                    <div id="validationEmailAddress" className="invalid-feedback">
                                        {invalidEmailMessage}
                                    </div>
                                </div>
                                <div className="form-group mt-3">
                                    <label className={clsx('form-label required', styles.label)}>
                                        Password
                                    </label>
                                    <input
                                        type="password"
                                        className={clsx(
                                            'form-control',
                                            !isValidPassword && 'is-invalid'
                                        )}
                                        value={password}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                            setPassword(e.target.value);
                                        }}
                                    />
                                    <div id="validationHost" className="invalid-feedback">
                                        Missing host
                                    </div>
                                </div>
                                <div className="form-group mt-3">
                                    <label className={clsx('form-label required', styles.label)}>
                                        Host
                                    </label>
                                    <input
                                        type="text"
                                        className={clsx(
                                            'form-control',
                                            !isValidHost && 'is-invalid'
                                        )}
                                        value={host}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                            setHost(e.target.value);
                                        }}
                                    />
                                    <div id="validationHost" className="invalid-feedback">
                                        Missing host
                                    </div>
                                </div>
                                <div className="form-group mt-3">
                                    <label className={clsx('form-label required', styles.label)}>
                                        Port
                                    </label>
                                    <input
                                        type="number"
                                        className={clsx(
                                            'form-control',
                                            !isValidPort && 'is-invalid'
                                        )}
                                        value={port}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                            setPort(parseInt(e.target.value));
                                        }}
                                    />
                                    <div id="validationPort" className="invalid-feedback">
                                        Missing Port
                                    </div>
                                </div>
                                <div className="form-group mt-3">
                                    <label className={clsx('form-label required', styles.label)}>
                                        Status
                                    </label>
                                    <select
                                        className="form-select"
                                        aria-label="Default select example"
                                        value={status}
                                        onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                                            e.stopPropagation();
                                            setStatus(parseInt(e.target.value));
                                        }}>
                                        <option value={0}>Inactivate</option>
                                        <option value={1}>Activate</option>
                                    </select>
                                </div>
                                <div className="w-100 pt-3">
                                    <button
                                        type="submit"
                                        className="btn btn-success w-100"
                                        disabled={isLoading}
                                        onClick={(e: React.MouseEvent) => {
                                            e.preventDefault();
                                            handleCreateEmail(
                                                serverName,
                                                emailAddress,
                                                host,
                                                port,
                                                status,
                                                password
                                            );
                                        }}>
                                        {isLoading ? 'Creating...' : 'Create'}
                                    </button>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CreateEmailModal;
