import { createIcon, downloadIcon, moreIcon, moveToFolderIcon, pencilIcon, shareIcon, sortIcon, submitDocumentIcon, trashIcon } from '../../../assets/icons'
import clsx from 'clsx'
import styles from "../dropdown-menu.module.css"
import RenameButton from '../rename/rename'

export type FileDropdownMenuProps = {
    setRename: (rename: boolean) => void;
    index: string;
    setSelectedIndex: any;
}

const FileDropdownMenu = ({
    setRename,
    index,
    setSelectedIndex,
}: FileDropdownMenuProps) => {
    return (
        <div className="dropdown">
            <button className="btn d-flex p-1 border-0" type="button" id="fileDropdown" data-bs-toggle="dropdown" aria-expanded="false">
                <img src={moreIcon} alt="" style={{ width: 16, height: 16, transform: "rotate(90deg)" }} />
            </button>
            <ul
                className={clsx("dropdown-menu", styles.dropdown_menu_container)} aria-labelledby="fileDropdown"
            >
                <RenameButton setRename={setRename} index={index} setSelectedIndex={setSelectedIndex} />
                <li>
                    <a className="dropdown-item d-flex align-items-center" href="#">
                        <img src={shareIcon} alt="" className={clsx(styles.icon, "me-2")} />
                        Share
                    </a>
                </li>
                <li>
                    <a className="dropdown-item d-flex align-items-center" href="#">
                        <img src={moveToFolderIcon} alt="" className={clsx(styles.icon, "me-2")} />
                        Move
                    </a>
                </li>
                <li>
                    <a className="dropdown-item d-flex align-items-center" href="#">
                        <img src={trashIcon} alt="" className={clsx(styles.icon, "me-2")} />
                        Delete
                    </a>
                </li>
                <li>
                    <a className="dropdown-item d-flex align-items-center" href="#">
                        <img src={sortIcon} alt="" className={clsx(styles.icon, "me-2")} />
                        Sort
                    </a>
                </li>
                <li><hr className="dropdown-divider" /></li>
                <li>
                    <a className="dropdown-item d-flex align-items-center" href="#">
                        <img src={downloadIcon} alt="" className={clsx(styles.icon, "me-2")} />
                        Download source files
                    </a>
                </li>
                <li>
                    <a className="dropdown-item d-flex align-items-center" href="#">
                        <img src={submitDocumentIcon} alt="" className={clsx(styles.icon, "me-2")} />
                        Submit for review
                    </a>
                </li>
                <li>
                    <a className="dropdown-item d-flex align-items-center" href="#">
                        <img src={createIcon} alt="" className={clsx(styles.icon, "me-2")} />
                        Create transmittal
                    </a>
                </li>
            </ul>
        </div>
    )
}

export default FileDropdownMenu