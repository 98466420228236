import clsx from 'clsx';
import React from 'react';
import DataView from '../browser-tab-content/data-view';
import styles from './browser-tab-bar.module.css';
// import { close1Icon } from '../../assets/icons';
import OdaViewer from '../../containers/viewer';
// import { EOpenDesign } from '../../reducers/open-design.reducer';
// import AssembliesContainer from '../../containers/assemblies';
import MsOfficePdfFileView from '../browser-tab-content/ms-office-pdf-file-view';
// import ModalEditAssemblies from '../modal-edit-assemblies/modal-edit-assemblies';

// type CreateNewBrowserTabType = {
//     path: string;
//     fileId: string;
//     onClickView: (fileId: string) => void;
//     closeViewer: (fileId: string) => void;
//     isMSOffficeFile: (path: string) => boolean;
//     closeMSOfficeViewer: (path: string) => void;
// };

// const activeViewerTab: { [key: string]: boolean } = {};

// const CreateNewBrowserTab: React.FC<CreateNewBrowserTabType> = ({
//     fileId,
//     path,
//     onClickView,
//     closeViewer,
//     isMSOffficeFile,
//     closeMSOfficeViewer
// }: CreateNewBrowserTabType) => {
//     return (
//         <li className="nav-item d-flex" role="presentation">
//             <button
//                 className="nav-link d-flex align-items-center"
//                 style={{ gap: '5px' }}
//                 id="nav-viewer-tab"
//                 data-bs-toggle="tab"
//                 data-bs-target="#nav-viewer"
//                 type="button"
//                 role="tab"
//                 aria-controls="nav-viewer"
//                 aria-selected="true">
//                 <div
//                     onClick={(e) => onClickView(fileId)}
//                     className={clsx(styles.navItemBtn, 'pe-2')}>
//                     {path.split('/').pop()}
//                 </div>
//                 {!!path && (
//                     <div className={clsx(styles.navItemBtn)}>
//                         <img
//                             onClick={() => {
//                                 if (isMSOffficeFile(path)) {
//                                     closeMSOfficeViewer(path);
//                                 }
//                                 closeViewer(fileId);
//                             }}
//                             src={close1Icon}
//                             alt="close-file"
//                             style={{ width: 20, height: 20, cursor: 'pointer' }}
//                         />
//                     </div>
//                 )}
//             </button>
//         </li>
//     );
// };

function BrowserTabBar(tabBarPropValues: any) {
    const {
        fileViewer,
        openDesignDispatch,
        isMSOffficeFile,
        selectedViewPath,
        setSelectedViewPath,
        msOfficeFileURL,
        detailAssembly
    } = tabBarPropValues;
    const [isViewAssemblies, setIsViewAssemblies] = React.useState<boolean>(false);
    const [renderViewer3D, setRenderViewer3D] = React.useState<{ [key: string]: React.ReactNode }>(
        {}
    );
    const [renderMSOffice, setRenderMSOffice] = React.useState<{ [key: string]: React.ReactNode }>(
        {}
    );

    React.useEffect(() => {
        if (!fileViewer.fileId) return;
        setRenderViewer3D({
            [fileViewer.fileId]: (
                <div
                    // key={fileId}
                    style={{ width: '100%', height: '100%', zIndex: 2, position: 'relative' }}
                    className={`tab-pane fade`}
                    id="nav-viewer"
                    role="tabpanel"
                    aria-labelledby="nav-viewer-tab">
                    <OdaViewer
                        fileId={fileViewer.fileId}
                        isAssemblies={fileViewer.isAssemblies}
                        detailAssembly={detailAssembly}
                    />
                </div>
            )
        });
    }, [fileViewer.fileId]);

    React.useEffect(() => {
        if (!renderViewer3D[fileViewer.fileId]) return;
        document.getElementById('nav-viewer-tab')?.click();
    }, [renderViewer3D[fileViewer.fileId]]);

    React.useEffect(() => {
        if (!selectedViewPath) return;

        const fileType = selectedViewPath.split('.').pop();

        if (isMSOffficeFile(selectedViewPath)) {
            setRenderMSOffice({
                [selectedViewPath]: (
                    <div
                        style={{ width: '100%', height: '100%' }}
                        className={`tab-pane fade`}
                        id="nav-viewer"
                        role="tabpanel"
                        aria-labelledby="nav-viewer-tab">
                        <MsOfficePdfFileView uri={msOfficeFileURL} fileType={fileType} />
                    </div>
                )
            });
        } else {
            return;
        }
    }, [selectedViewPath]);

    React.useEffect(() => {
        if (!renderMSOffice[selectedViewPath]) return;
        document.getElementById('nav-viewer-tab')?.click();
    }, [renderMSOffice[selectedViewPath]]);

    // const onClickView = (fileId: string) => {
    //     // document.getElementById('nav-viewer-tab')?.click();
    // };

    // const closeViewer = (fileId: string) => {
    //     console.log('CLOSE TAB:', fileId);
    //     openDesignDispatch({
    //         type: EOpenDesign.DISPOSE_VIEW3D,
    //         payload: {
    //             fileId: fileId
    //         }
    //     });
    //     document.getElementById('nav-wip-data-tab')?.click();
    //     setRenderViewer3D({});
    // };

    // const closeMSOfficeViewer = (path: string) => {
    //     document.getElementById('nav-wip-data-tab')?.click();
    //     setSelectedViewPath('');
    //     setRenderMSOffice({});
    // };

    return (
        <div
            style={{ width: '100%', position: 'relative' }}
            id="viewContent"
            className={clsx('d-flex flex-column h-100')}>
            {/* tabbar */}
            <div id="tabBar" className={clsx(styles.fixedElement)}>
                <ul className="nav nav-tabs px-3" id="myTab" role="tablist">
                    <li className="nav-item" role="presentation">
                        <button
                            onClick={() => setIsViewAssemblies(false)}
                            className="nav-link active"
                            id="nav-wip-data-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#nav-wip-data"
                            type="button"
                            role="tab"
                            aria-controls="nav-wip-data"
                            aria-selected="true">
                            Data
                        </button>
                    </li>
                    {/* <li className="nav-item" role="presentation">
                        <button
                            className="nav-link"
                            id="nav-assemblies-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#nav-assemblies"
                            type="button"
                            role="tab"
                            onClick={() => setIsViewAssemblies(true)}
                            aria-controls="nav-assemblies"
                            aria-selected="true">
                            Models
                        </button>
                    </li> */}
                    {/* <CreateNewBrowserTab
                        key={fileViewer.fileId}
                        onClickView={onClickView}
                        closeViewer={closeViewer}
                        fileId={fileViewer.fileId}
                        path={
                            isMSOffficeFile(selectedViewPath) ? selectedViewPath : fileViewer.path
                        }
                        isMSOffficeFile={isMSOffficeFile}
                        closeMSOfficeViewer={closeMSOfficeViewer}
                    /> */}
                </ul>
            </div>

            {/* tab content */}
            <div
                className={clsx('tab-content overflow-y-auto ', styles.expandingElement)}
                id="nav-tabContent"
                style={{ width: '100%', position: 'relative' }}>
                <DataView {...tabBarPropValues} />
                {/* <div
                    className="tab-pane fade"
                    id="nav-assemblies"
                    role="tabpanel"
                    aria-labelledby="nav-assemblies-tab">
                    {isViewAssemblies && (
                        <AssembliesContainer
                            projectId={tabBarPropValues.projectId}
                            openDesignDispatch={tabBarPropValues.openDesignDispatch}
                            path={tabBarPropValues.path}
                            tabBarPropValues={tabBarPropValues}
                        />
                    )}
                </div> */}
                {/* {isMSOffficeFile(selectedViewPath)
                    ? renderMSOffice[selectedViewPath]
                    : renderViewer3D[fileViewer.fileId]} */}
            </div>
        </div>
    );
}

export default BrowserTabBar;
