import { Navigate, Outlet } from 'react-router-dom';
import { useAuth } from '../providers/auth.provider';
import UnauthenicatedLayout from '../components/layouts/unauthenicated-layout';
import { ILoggedInUser } from '../types/login.interface';

export function UnauthenticatedOnly() {
    const { user }: { user: ILoggedInUser } = useAuth();
    if (user.id) {
        return <Navigate to="/" />;
    }

    return (
        <UnauthenicatedLayout>
            <Outlet />
        </UnauthenicatedLayout>
    );
}
