import React, { useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import styles from './header.module.css';
import { IoMenu, IoNotifications } from 'react-icons/io5';
import { Link } from 'react-router-dom';
import { userIcon } from '../../../assets/icons';
import { IoIosArrowDropdownCircle } from 'react-icons/io';
import ProfileSubmenu from './profile-submenu';

function Header() {
    const [isOpen, setIsOpen] = useState(false);

    // handle click inside and outside
    const theRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
        function handleClickOutside(event: any): void {
            if (theRef.current && !theRef.current.contains(event.target)) {
                // console.log('outside');
                setIsOpen(false);
            } else {
                // console.log('inside');
                setIsOpen(true);
            }
        }
        // Bind the event listener
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [theRef]);

    return (
        <header className={clsx(styles.wrapper, 'd-flex justify-content-between')}>
            <div className="d-flex align-items-center">
                <IoMenu className={clsx(styles.burgerMenu, 'me-3')} />
                <Link to="/">
                    <img
                        src="/company-logo-2.png"
                        alt="ADSCivil CDE"
                        className={clsx(styles.logo)}
                    />
                </Link>
            </div>
            <div className="d-flex align-items-center">
                <div className={clsx(styles.notiBlock)}>
                    <IoNotifications className={clsx(styles.notiIcon)} />
                    <div className={clsx(styles.notiActive)}></div>
                </div>
                <div className={clsx(styles.auth)} ref={theRef}>
                    <img src={userIcon} alt="" className={clsx(styles.userIcon)} />
                    <IoIosArrowDropdownCircle className={clsx(styles.arrowIcon)} />
                    {isOpen && (
                        <div className={clsx(styles.submenu)}>
                            <ProfileSubmenu setIsOpen={setIsOpen} />
                        </div>
                    )}
                </div>
            </div>
        </header>
    );
}

export default Header;
