import clsx from 'clsx';
import React from 'react';
import { EFifoKind, IFifo } from '../../types/fifo.interface';
import styles from './overview-browser-table.module.css';
import dayjs from 'dayjs';
import { folderIcon, documentIcon } from '../../assets/icons';
import relativeTime from 'dayjs/plugin/relativeTime';
import { Link } from 'react-router-dom';
dayjs.extend(relativeTime);

function OverviewBrowserTable({
    projectId,
    files,
    isLoading
}: {
    projectId: string;
    files: IFifo[];
    isLoading: boolean;
}) {
    return (
        <table className={clsx(styles.table)}>
            <thead className={clsx(styles.tableHead)}>
                <tr>
                    <th className={clsx(styles.firstCol)}>Name</th>
                    <th>Revision</th>
                    <th>Author</th>
                    <th>Last commit date</th>
                </tr>
            </thead>
            <tbody className={clsx()}>
                {isLoading ? (
                    <></>
                ) : (
                    <>
                        {files.map((f, i) => (
                            <tr key={i} className={clsx(styles.tableRow)}>
                                <td className={clsx(styles.firstCol)}>
                                    <div className="d-flex align-items-center">
                                        {f.kind === EFifoKind.DIR ? (
                                            <img
                                                src={folderIcon}
                                                alt=""
                                                style={{ width: 16, height: 16 }}
                                            />
                                        ) : (
                                            <img
                                                src={documentIcon}
                                                alt=""
                                                style={{ width: 16, height: 16 }}
                                            />
                                        )}
                                        <Link
                                            to={`/projects/${projectId}/browser?path=/${f.name}/`}
                                            className={clsx(styles.theLink, 'ms-2')}>
                                            {f.name}
                                        </Link>
                                    </div>
                                </td>
                                <td>
                                    <p className="m-0">{f.commit && `${f.commit.revision}`}</p>
                                </td>
                                <td>
                                    <p className="m-0">{f.commit && `${f.commit.author}`}</p>
                                </td>
                                <td>{f.commit && dayjs(f.commit.date).fromNow().toString()}</td>
                            </tr>
                        ))}
                    </>
                )}
            </tbody>
        </table>
    );
}

export default OverviewBrowserTable;
