import { io } from 'socket.io-client';

// "undefined" means the URL will be computed from the `window.location` object
const URL = String(process.env.REACT_APP_SOCKET_URL);

export const socket = io(URL, {
    withCredentials: true,
    path: '/svn-ws',
    transports: ['pooling', 'websocket'],
    secure: true,
    rejectUnauthorized: false,
    extraHeaders: {
        'Sec-WebSocket-Extensions': 'svn',
        'Sec-WebSocket-Protocol': 'svn-ws',
        'Sec-WebSocket-Sign': 'sonlt'
    }
});
