import React, { useCallback, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { IProject } from '../../../types/project.interface';
import { IMultiItemResponseData } from '../../../types/response-data.interface';
import axiosService, { axiosErrorHandler } from '../../../services/axios.service';
import axios from 'axios';
import clsx from 'clsx';
import ProjectTable from '../../../components/project-table/project-table';
import styles from './projects-admin-page.module.css';
import { IoSearchSharp } from 'react-icons/io5';
import Pagination from '../../../components/pagination/pagination';
import CreateProjectModal from '../../../components/create-project-modal/create-project-modal';

const limit = parseInt(process.env.REACT_APP_PAGINATION_LIMIT as string) | 12;

function ProjectsAdminPage() {
    let [searchParams, setSearchParams] = useSearchParams();
    const [projects, setProjects] = useState<IProject[]>([]);
    const [page, setPage] = useState(
        searchParams.get('page') ? parseInt(searchParams.get('page') as string) : 1
    );
    const [total, setTotal] = useState(0);
    const [isLoading, setIsLoading] = useState(false);

    const fetchProjects = useCallback(async (page: number) => {
        try {
            setIsLoading(true);
            const { data }: { data: IMultiItemResponseData<IProject> } = (
                await axiosService.get(
                    `projects?page=${page}&limit=${limit}&fields=name status startDate endDate location&sortBy=-createdAt`
                )
            ).data;
            const { items, total } = data;
            setProjects(items);
            setTotal(total);
        } catch (error) {
            setProjects([]);
            if (axios.isAxiosError(error)) {
                axiosErrorHandler(error);
            } else {
                console.log('error:  ', error);
            }
        } finally {
            setIsLoading(false);
        }
    }, []);

    useEffect(() => {
        fetchProjects(page);
    }, [page, fetchProjects]);

    const onDeleteProject = useCallback(() => {
        fetchProjects(page);
    }, [fetchProjects, page]);

    const handleChangePage = useCallback(
        (page: number) => {
            setPage(page);
            setSearchParams({ page: page.toString() });
        },
        [setSearchParams]
    );

    const onCreateSuccess = useCallback(() => {
        if (page === 1) {
            fetchProjects(1);
        } else {
            handleChangePage(1);
        }
    }, [handleChangePage, fetchProjects, page]);

    return (
        <div className={clsx('p-3')}>
            <div className={clsx(styles.mainContent)}>
                <div
                    className={clsx(
                        styles.heading,
                        'd-flex justify-content-between align-items-center'
                    )}>
                    <div className={clsx(styles.titles)}>Manage Projects</div>
                    <div className="d-flex">
                        <div className={clsx(styles.searchBar)}>
                            <IoSearchSharp className={clsx(styles.searchBarIcon)} />
                            <div className="form-group">
                                <input
                                    type="text"
                                    className={clsx('form-control', styles.searchBarInput)}
                                    id="search"
                                    placeholder="Search project"
                                />
                            </div>
                        </div>
                        <button
                            className={clsx(styles.addNewButton, 'btn btn-success')}
                            type="button"
                            data-bs-toggle="modal"
                            data-bs-target="#createProjectModal">
                            Create project
                        </button>
                        <CreateProjectModal
                            id="createProjectModal"
                            onCreateSuccess={onCreateSuccess}
                        />
                    </div>
                </div>
                <ProjectTable
                    projects={projects}
                    isLoading={isLoading}
                    onDeleteProject={onDeleteProject}
                />
                <div className={clsx(styles.optionBlock)}>
                    <Pagination
                        page={page}
                        handleChangePage={handleChangePage}
                        total={total}
                        limit={limit}
                    />
                </div>
            </div>
        </div>
    );
}

export default ProjectsAdminPage;
