import clsx from 'clsx'
import { clear03Icon, tickIcon } from '../../../assets/icons';
import styles from "../dropdown-menu.module.css"
import { useCallback, useState } from 'react';
import { EFifoKind, IFolderTreeElement } from '../../../types/fifo.interface';
import axiosService, { axiosErrorHandler } from '../../../services/axios.service';
import axios from 'axios';
import { ETreeReducerActionType, useTreeReducer } from '../../../reducers/tree.reducer';

export type RenameInputProps = {
    value: string;
    setRename: (rename: boolean) => void;
    path: string;
    element: IFolderTreeElement,
    projectId: string;
}

const RenameInput = ({
    value,
    setRename,
    path,
    element,
    projectId
}: RenameInputProps) => {
    const [renameValue, setRenameValue] = useState(value)
    const [loading, setLoading] = useState(false);
    const [, dispatch] = useTreeReducer();

    const handleRename = useCallback(async () => {
        try {
            setLoading(true);
            const { data } = (await axiosService.patch(`fifos?path=${path}&prj=${projectId}`, {
                newName: element.kind === EFifoKind.DIR ? `${renameValue}/` : `${renameValue}.${element.name.split('.').pop()}`
            }));

            if (data.success) {
                // dispatch({
                //     type: ETreeReducerActionType.ON_RENAME,
                //     payload: { path, newName: renameValue }
                // });
                window.location.reload();
            }
        } catch (error) {
            if (axios.isAxiosError(error)) {
                axiosErrorHandler(error);
            } else {
                console.log('error:  ', error);
            }
            return null;
        } finally {
            setLoading(false);
            setRename(false);
        }
    }, [setRename, renameValue, path, element, projectId, dispatch])

    return (
        <>
            {loading ? (
                <div className="spinner-border text-secondary" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
            ) : (
                <div className='d-flex align-items-center'>
                    <input
                        type="text"
                        className={clsx("me-2")}
                        value={renameValue}
                        onChange={(e) => {
                            setRenameValue(e.target.value);
                        }
                        }
                    />
                    <img src={tickIcon} alt="" className={clsx(styles.icon, "me-2")} onClick={handleRename} />
                    <img src={clear03Icon} alt="" className={clsx(styles.icon, "me-2")} onClick={() => setRename(false)} />
                </div>
            )}
        </>

    )
}

export default RenameInput