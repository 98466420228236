import React, { useCallback, useEffect, useRef, useState } from 'react';
import { IUser } from '../../types/user.interface';
import { IGroup } from '../../types/group.interface';
import axiosService, { axiosErrorHandler } from '../../services/axios.service';
import axios from 'axios';
import Swal from 'sweetalert2';
import { IMultiItemResponseData } from '../../types/response-data.interface';
import clsx from 'clsx';
import styles from './update-group-modal.module.css';
import SearchBar from '../search-bar/search-bar';
import ManageItemsTable from '../manage-items-table/manage-items-table';

function UpdateGroupModal({
    id,
    group,
    onUpdateSuccess
}: {
    id: string;
    group: IGroup;
    onUpdateSuccess?: Function;
}) {
    const [isLoading, setIsLoading] = useState(false);

    const [name, setName] = useState('');
    const [isValidName, setIsValidName] = useState(true);
    const [inValidNameMessage, setInValidNameMessage] = useState('');
    const [members, setMembers] = useState<IUser[]>([]);

    const closeButtonRef = useRef(null);

    useEffect(() => {
        setName(group.name);
        setMembers(group.members as IUser[]);
    }, [group]);

    const resetValidate = useCallback(() => {
        setIsValidName(true);
    }, []);

    const validateForm = useCallback(
        (name: string): boolean => {
            resetValidate();
            let isValid = true;
            if (name.length === 0) {
                setIsValidName(false);
                setInValidNameMessage('Please choose a group name');
                isValid = false;
            }
            return isValid;
        },
        [resetValidate]
    );

    const updateGroup = useCallback(
        async (groupId: string, name: string, members: IUser[]) => {
            try {
                setIsLoading(true);
                await axiosService.patch(`groups/${groupId}`, {
                    ...(!group.isDefault && { name }),
                    members: members.map((m) => m.id)
                });
                (closeButtonRef.current as any).click();
                Swal.fire({
                    title: 'Update group successfully',
                    icon: 'success',
                    showConfirmButton: true,
                    confirmButtonText: 'Ok'
                });
                onUpdateSuccess &&
                    onUpdateSuccess({
                        id: group.id,
                        isDefault: group.isDefault,
                        name,
                        members
                    } as IGroup);
            } catch (error) {
                if (axios.isAxiosError(error)) {
                    axiosErrorHandler(error);
                } else {
                    console.log('error:  ', error);
                }
            } finally {
                setIsLoading(false);
            }
        },
        [onUpdateSuccess, group]
    );

    const handleUpdateGroup = useCallback(
        async (groupId: string, name: string, members: IUser[]) => {
            if (validateForm(name)) {
                await updateGroup(groupId, name, members);
            }
        },
        [updateGroup, validateForm]
    );

    const fetchUsers = useCallback(async (value: string): Promise<IUser[]> => {
        try {
            const { data }: { data: IMultiItemResponseData<IUser> } = (
                await axiosService.get(`/users?fields=username email&q=${value}&limit=10`)
            ).data;
            const { items } = data;
            return items;
        } catch (error) {
            if (axios.isAxiosError(error)) {
                axiosErrorHandler(error);
            } else {
                console.log('error:  ', error);
            }
            return [];
        }
    }, []);

    const handleDisplaySearchUserOption = useCallback((u: IUser) => {
        return u.username;
    }, []);

    const userIsNotExistsInMembers = useCallback(
        (u: IUser) => {
            return !members.some((e) => e.id === u.id);
        },
        [members]
    );

    const addMember = useCallback(
        (member: IUser) => {
            setMembers([...members, member]);
        },
        [members]
    );

    return (
        <div
            className={clsx('modal fade', styles.modalWrapper)}
            id={id}
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            tabIndex={-1}
            aria-labelledby="staticBackdropLabel"
            aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="staticBackdropLabel">
                            Update group
                        </h5>
                        <button
                            type="button"
                            className={clsx('btn-close')}
                            data-bs-dismiss="modal"
                            aria-label="Close"
                            ref={closeButtonRef}></button>
                    </div>
                    <div className="modal-body">
                        {isLoading ? (
                            <div className="d-flex justify-content-center">
                                <div className="spinner-border" role="status"></div>
                            </div>
                        ) : (
                            <div className={clsx(styles.form)}>
                                <div className="form-group">
                                    <label className={clsx('form-label required', styles.label)}>
                                        Name
                                    </label>
                                    <input
                                        type="text"
                                        className={clsx(
                                            'form-control',
                                            !isValidName && 'is-invalid'
                                        )}
                                        value={name}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                            setName(e.target.value);
                                        }}
                                        disabled={group.isDefault}
                                    />
                                    <div id="validationProjectName" className="invalid-feedback">
                                        {inValidNameMessage}
                                    </div>
                                </div>

                                <div className="form-group mt-3">
                                    <label className={clsx('form-label', styles.label)}>
                                        Members
                                    </label>
                                    <SearchBar<IUser>
                                        placeholder="Add member"
                                        loadOptions={fetchUsers}
                                        delayTime={500}
                                        handleDisplayOption={handleDisplaySearchUserOption}
                                        filterBeforeDisplay={userIsNotExistsInMembers}
                                        onSelectOption={addMember}
                                        cacheOptions={true}
                                    />
                                    <ManageItemsTable
                                        items={members}
                                        setItems={setMembers}
                                        editable={true}
                                        columns={['username', 'email']}
                                    />
                                </div>

                                <div className="w-100 pt-3">
                                    <button
                                        type="submit"
                                        className="btn btn-success w-100"
                                        disabled={isLoading}
                                        onClick={(e: React.MouseEvent) => {
                                            e.preventDefault();
                                            handleUpdateGroup(group.id, name, members);
                                        }}>
                                        {isLoading ? 'Updating...' : 'Update'}
                                    </button>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default UpdateGroupModal;
