import clsx from 'clsx';
import styles from './data-view.module.css';
import BrowserSearch from '../browser-search/browser-search';
import BrowserTable from '../browser-table/browser-table';
import BrowserSearchResult from '../browser-search/browser-search-result';
import Enable3dViewButton from '../enable-3d-view-button/enable-3d-view-button';
import CreateAssemblieButton from '../create-assemblie-button/create-assemblie-button';
import UploadFileButton from '../upload-file-button/upload-file-button';
import DownloadZipButton from '../download-zip-button/download-zip-button';
import { useState } from 'react';
import { useBrowserSearchReducer } from '../../reducers/brower-search.reducer';
import { IProject } from '../../types/project.interface';
import { ITreeReducerAction, ITreeReducerState } from '../../reducers/tree.reducer';

export type InfoSelectedFileType = {
    path: string;
    revision: number | undefined;
};

function DataView({
    path,
    project,
    treeReducer,
    isLoadingBrowser,
    fetchChildren,
    setSearchParams,
    selectedModelViewer,
    rename,
    setRename,
    selectedIndex,
    setSelectedIndex,
    projectId
}: {
    path: string;
    project: IProject;
    treeReducer: [ITreeReducerState, React.Dispatch<ITreeReducerAction>];
    isLoadingBrowser: boolean;
    fetchChildren: (path: string) => Promise<boolean>;
    setSearchParams: Function;
    selectedModelViewer: (projectId: string, path: string) => void;
    rename: boolean;
    setRename: (rename: boolean) => void;
    selectedIndex: any;
    setSelectedIndex: any;
    projectId: string;
}) {
    const [selectedPaths, setSelectedPaths] = useState<string[]>([]);
    const [selectedInfoFiles, setSelectedInfoFiles] = useState<InfoSelectedFileType[]>([]);
    const [browserSearchState, browserSearchDispatch] = useBrowserSearchReducer();
    const { isShowSearchResult, searchText, isLoadingSearchResult, searchResults } =
        browserSearchState;
    const [state, dispatch] = treeReducer;
    const { table } = state;

    const selectedInfoFilesHandle = (data: InfoSelectedFileType) => {
        if (selectedInfoFiles.map((file) => file.path).includes(data.path)) {
            setSelectedInfoFiles(selectedInfoFiles.filter((file) => file.path !== data.path));
        } else {
            setSelectedInfoFiles([...selectedInfoFiles, data]);
        }
    };

    return (
        <div
            className="tab-pane fade show active"
            id="nav-wip-data"
            role="tabpanel"
            aria-labelledby="nav-wip-data-tab"
            style={{ height: 'inherit' }}>
            <div className="h-100 p-3">
                <div className={clsx('mb-1', styles.fixedElement)}>
                    <div className="d-flex">
                        <div className={clsx(styles.expandingElement)}>
                            <BrowserSearch
                                projectId={project.id!}
                                path={path}
                                browserSearchDispatch={browserSearchDispatch}
                            />
                        </div>
                        <div className="ms-3 align-self-center">
                            {/* download zip button */}
                            {selectedPaths.length > 0 && (
                                <DownloadZipButton
                                    projectId={project.id}
                                    selectedPaths={selectedPaths}
                                />
                            )}
                            {/* create assemblie button */}
                            {/* <CreateAssemblieButton
                                projectId={project.id}
                                selectedInfoFiles={selectedInfoFiles}
                            /> */}
                            {/* enable 3d view button */}
                            {/* <Enable3dViewButton
                                projectId={project.id}
                                selectedInfoFiles={selectedInfoFiles}
                            /> */}
                            {/* upload file */}
                            <UploadFileButton project={project} path={path} />
                        </div>
                    </div>
                </div>
                <div
                    className={clsx(
                        styles.expandingElement,
                        'overflow-visible pb-5 h-100',
                        isShowSearchResult ? 'd-none' : 'd-block'
                    )}>
                    <BrowserTable
                        project={project}
                        path={path}
                        files={table}
                        isLoading={isLoadingBrowser}
                        dispatch={dispatch}
                        fetchChildren={fetchChildren}
                        setSearchParams={setSearchParams}
                        selectedPaths={selectedPaths}
                        setSelectedPaths={setSelectedPaths}
                        selectedModelViewer={selectedModelViewer}
                        selectedInfoFilesHandle={selectedInfoFilesHandle}
                        selectedInfoFiles={selectedInfoFiles}
                        setSelectedInfoFiles={setSelectedInfoFiles}
                        rename={rename}
                        setRename={setRename}
                        selectedIndex={selectedIndex}
                        setSelectedIndex={setSelectedIndex}
                        projectId={projectId}
                    />
                </div>
                <div
                    className={clsx(
                        styles.expandingElement,
                        'overflow-auto mt-2',
                        isShowSearchResult ? 'd-block' : 'd-none'
                    )}>
                    <p className="mb-0">Search result for '{searchText}':</p>
                    <BrowserSearchResult
                        isLoading={isLoadingSearchResult}
                        searchResults={searchResults}
                        projectId={project.id}
                        path={path}
                    />
                </div>
            </div>
        </div>
    );
}
export default DataView;
