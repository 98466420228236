import React, { useCallback, useEffect, useState } from 'react';
import clsx from 'clsx';
import { IUser } from '../../types/user.interface';
import { EUserRole } from '../../types/login.interface';
import { deleteIcon, editIcon, resetPasswordIcon } from '../../assets/icons';
import axiosService, { axiosErrorHandler } from '../../services/axios.service';
import Swal from 'sweetalert2';
import axios from 'axios';
import styles from './user-table.module.css';
import ResetPasswordModal from '../reset-password-modal/reset-password-modal';
import UpdateUserModal from '../update-user-modal/update-user-modal';

function UserTable({
    users,
    isLoading,
    onDeleteUser
}: {
    users: IUser[];
    isLoading: boolean;
    onDeleteUser: () => void;
}) {
    const [firstOpened, setFirstOpened] = useState<boolean[]>(users.map(() => false));
    const openEdit = useCallback(
        (i: number) => {
            firstOpened[i] = true;
            setFirstOpened([...firstOpened]);
        },
        [firstOpened]
    );
    useEffect(() => {
        setFirstOpened(users.map(() => false));
    }, [users]);

    const deleteUser = async (userId: string) => {
        try {
            await axiosService.delete(`users/${userId}`);
            Swal.fire({
                icon: 'success',
                title: 'Delete user success',
                showConfirmButton: true,
                confirmButtonText: 'Ok'
            }).then(() => {
                onDeleteUser();
            });
        } catch (error) {
            if (axios.isAxiosError(error)) {
                axiosErrorHandler(error);
            } else {
                console.log('error:  ', error);
            }
        }
    };

    const handleDeleteUser = (user: IUser) => {
        Swal.fire({
            icon: 'question',
            title: `Delete user '${user.username}'`,
            text: 'Are you sure you want to delete this user?',
            showConfirmButton: true,
            showCancelButton: true,
            reverseButtons: true,
            showLoaderOnConfirm: true,
            preConfirm: async () => {
                await deleteUser(user.id);
            }
        });
    };

    return (
        <table className={clsx(styles.table)}>
            <thead className={clsx(styles.tableHead)}>
                <tr>
                    <th className={clsx(styles.firstCol)}>Username</th>
                    <th>Firstname</th>
                    <th>Lastname</th>
                    <th>Email</th>
                    <th>Role</th>
                    <th>Created by</th>
                    <th className={clsx(styles.lastCol)}>Action</th>
                </tr>
            </thead>
            <tbody className={clsx()}>
                {isLoading ? (
                    <></>
                ) : (
                    users.map((user, index) => (
                        <tr key={user.id} className={clsx(styles.tableRow)}>
                            <td className={clsx(styles.firstCol)}>{user.username}</td>
                            <td>{user.firstName}</td>
                            <td>{user.lastName}</td>
                            <td>{user.email}</td>
                            <td>{EUserRole[user.role].toLowerCase()}</td>
                            <td>{user.createdBy ? (user.createdBy as IUser).username : null}</td>
                            <td>
                                <img
                                    src={editIcon}
                                    alt=""
                                    style={{ width: 20, height: 20, cursor: 'pointer' }}
                                    className="me-2"
                                    onClick={(e: React.MouseEvent<HTMLElement>) => {
                                        e.stopPropagation();
                                        openEdit(index);
                                    }}
                                    title="Update user info"
                                    data-bs-toggle="modal"
                                    data-bs-target={`#updateUser${user.id}`}
                                />
                                <UpdateUserModal
                                    id={`updateUser${user.id}`}
                                    userId={user.id}
                                    firstOpened={firstOpened[index]}
                                />
                                <img
                                    src={resetPasswordIcon}
                                    alt=""
                                    style={{ width: 20, height: 20, cursor: 'pointer' }}
                                    className="me-2"
                                    onClick={(e: React.MouseEvent<HTMLElement>) => {
                                        e.stopPropagation();
                                    }}
                                    title="Reset password"
                                    data-bs-toggle="modal"
                                    data-bs-target={`#resetPassModal-${user.id}`}
                                />
                                <ResetPasswordModal user={user} />
                                <img
                                    src={deleteIcon}
                                    alt=""
                                    style={{ width: 20, height: 20, cursor: 'pointer' }}
                                    onClick={(e: React.MouseEvent<HTMLElement>) => {
                                        e.stopPropagation();
                                        handleDeleteUser(user);
                                    }}
                                    title="Delete user"
                                />
                            </td>
                        </tr>
                    ))
                )}
            </tbody>
        </table>
    );
}

export default UserTable;
