import { downloadFile } from '../../../services/files/download-file';
import { IOpenCloud } from './open-cloud.interface';

export type VisualizeOptionsType = {
    urlMemFile: string;
    TOTAL_MEMORY: number;
};

let diffHandleId: { [key: string]: boolean } = {};

export class OpenCloud implements IOpenCloud {
    private CANVAS_ID: string;
    private plugin: any;
    private options: VisualizeOptionsType;
    private visualizeLibrary: any;
    private isPerspective: boolean = false;

    private viewer: any;
    private canvas: HTMLCanvasElement;
    // public commandEvent: CommandEventType;

    constructor(CANVAS_ID = 'canvas') {
        this.CANVAS_ID = CANVAS_ID;
        this.canvas = document.getElementById(CANVAS_ID) as HTMLCanvasElement;
        this.options = {
            urlMemFile: 'https://opencloud.azureedge.net/libs/visualizejs/25.1/Visualize.js.wasm',
            TOTAL_MEMORY: 234217728
        };
    }

    /***** INITIALIZATION *****/
    public setCanvasElement(canvas: HTMLCanvasElement) {
        this.canvas = canvas;
    }

    /**
     * @public
     * @param {VisualizeOptionsType} options options for initial View 3D of Open Design Aliance
     * @param {function} callback callback return handle or id of node tree when selected object
     * @returns
     */
    public async init(
        options: VisualizeOptionsType = this.options,
        callback?: (args: any) => void
    ): Promise<any> {
        this.options = options;

        try {
            /* eslint no-use-before-define: 0 */
            // prettier-ignore
            await import('./Visualize');
            /* eslint no-use-before-define: 0 */
            // prettier-ignore
            const OdaViewerPlugin = await import('./OdaViewerPlugin');

            /**
             * @typedef {import('./visualizejs.d.ts').VisualizeJS} VisualizeJS
             */
            const lib = getVisualizeLibInst(options);
            this.visualizeLibrary = lib;

            const VisualizePromise = new Promise((resolve, reject) => {
                try {
                    lib.postRun.push(async () => {
                        try {
                            this.canvas.height = this.canvas.clientHeight;
                            this.canvas.width = this.canvas.clientWidth;

                            lib.canvas = this.canvas;
                            this.plugin = new OdaViewerPlugin.default(lib);
                            // const plugin = new OdaViewerPlugin.default(lib);
                            this.plugin.setActive(this.plugin.type.Pan);
                            this.plugin.setAutoSelect(true);

                            this.initSelectObject(callback);

                            this.viewer = lib.Viewer.create();

                            this.resize();
                            document.addEventListener('resize', this.resize.bind(this));

                            // rendering function which is invoked constantly through requestAnimationFrame() method
                            this.render();

                            resolve(this.viewer);
                        } catch (error) {
                            console.log('Post run error!');
                            reject(error);
                        }
                    });
                } catch (error) {
                    reject(error);
                }
            });

            return await VisualizePromise;
        } catch (error) {
            console.log('INIT FUNCTION ERROR!\n', error);
        }
    }

    /***** PRIVATE *****/
    private resize(): void {
        try {
            this.canvas.height = this.canvas.clientHeight;
            this.canvas.width = this.canvas.clientWidth;
            this.viewer.resize(0, this.canvas.height, this.canvas.width, 0);
            this.viewer.update();
        } catch (error: any) {
            console.log('RESIZE ERROR:', error);
        }
    }

    private render(): void {
        // start frame rendering
        // this.setPerspectiveCamera(true);
        try {
            this.viewer.update();
            requestAnimationFrame(this.render.bind(this));
        } catch (error) {}
    }

    private setPerspectiveCamera(isPerspective: boolean = false, viewer?: any): void {
        try {
            this.isPerspective = isPerspective;
            if (viewer) {
                viewer.activeView.perspective = isPerspective;
            } else {
                this.getViewer().activeView.perspective = isPerspective;
            }
        } catch (error) {
            console.log('ERROR:', error);
        }
    }

    /**
     * Initial selection object of VisualizeJS
     * @param callback Called external function when created selection object processing be success
     */
    private async initSelectObject(callback?: (args: any) => void): Promise<void> {
        this.plugin.onmessage = async (event: any) => {
            const visViewer = this.viewer;

            const handles: string[] = [];
            const selectionSet = visViewer.getSelected();
            if (!selectionSet.isNull() && selectionSet.numItems() !== 0) {
                const itr = selectionSet.getIterator();
                for (; !itr.done(); itr.step()) {
                    const entityId = itr.getEntity();

                    const entityPtr =
                        entityId.getType() === 1
                            ? entityId.openObject()
                            : entityId.getType() === 2
                              ? entityId.openObjectAsInsert()
                              : null;

                    if (entityPtr) {
                        const handle = entityPtr.getNativeDatabaseHandle();
                        if (handle !== '-1') handles.push(handle);
                        entityPtr.delete();
                    }
                }
                itr.delete();
            }
            if (handles.length) {
                // if the object be selected
                if (!diffHandleId[handles[0]]) {
                    diffHandleId = {};
                    diffHandleId[handles[0]] = true;
                    if (callback) callback(handles[0]);
                }
            } else {
                diffHandleId = {};
                if (callback) callback(false);
            }
        };
    }

    /***** PUBLIC *****/
    public async loadFile(fileId: string, isAssemblies: boolean = false): Promise<void> {
        try {
            // const geometry = await getDetailGeometry(fileId);
            const arrayBuffer = await downloadFile(fileId, '', isAssemblies);

            // clear buffer
            this.viewer.clear();

            // pass file as Uint8Array to the viewer for parsing
            this.viewer.parseVsfx(new Uint8Array(arrayBuffer));

            // call zoomExtenst method in case the camera is not aimed at the whole scene by default
            this.viewer.zoomExtents();
            // zoom in to the center of scene
            this.viewer.zoomAt(100, this.canvas.clientWidth / 2, this.canvas.clientHeight / 2);
            // regen all
            this.viewer.regenAll();
            // zoom back
            this.viewer.zoomAt(0.01, this.canvas.clientWidth / 2, this.canvas.clientHeight / 2);
        } catch (error) {
            console.log('FETCH FILE ERROR:', error);
        }
    }

    /**
     * @typedef {import('./visualizejs.d.ts').VisualizeJS.Viewer} VisualizeJS.Viewer
     */
    public getViewer(): VisualizeJS.Viewer {
        return this.viewer;
    }

    public getCanvasElement(): HTMLCanvasElement {
        return this.canvas;
    }

    public setSelected(handles: string[]): void {
        const visViewer = this.getViewer();

        const selectionSet = new this.visualizeLibrary.OdTvSelectionSet();
        handles?.forEach((handle) => {
            const entityId = visViewer.getEntityByOriginalHandle(handle + '');
            if (!entityId.isNull()) selectionSet.appendEntity(entityId);
        });

        visViewer.unselect();
        visViewer.setSelected(selectionSet);
    }

    public zoomToSelected(): void {
        const selectionSet = this.viewer.getSelected();

        this.viewer.zoomToObjects?.(selectionSet);
        this.viewer.update();
    }

    public activePanAction(): void {
        document.querySelector('#pan_btn')?.addEventListener('click', (ev) => {
            this.plugin.setActive(this.plugin.type.Pan);
        });
    }

    public activeOrbitAction(): void {
        document.querySelector('#orbit_btn')?.addEventListener('click', (ev) => {
            this.plugin.setActive(this.plugin.type.Orbit);
        });
    }

    public activeZoomAction(): void {
        document.querySelector('#zoom_btn')?.addEventListener('click', (ev) => {
            this.plugin.setActive(this.plugin.type.Zoom);
        });
    }

    public zoomToExtents(force: boolean = false, animate: boolean = false): void {
        try {
            const saveEnableAmination = this.viewer.getEnableAnimation();
            this.viewer.setEnableAnimation(animate);
            this.viewer.zoomExtents(force);
            this.viewer.update();
            this.viewer.setEnableAnimation(saveEnableAmination);
        } catch (error) {
            console.log(error);
        }
    }
}
