import clsx from 'clsx';
import React, { useCallback, useState } from 'react';
import styles from './manage-permission-row.module.css';
import { EAccessPermission } from '../../types/fifo.interface';
import { IPermission } from '../../types/permission.interface';
import axiosService, { axiosErrorHandler } from '../../services/axios.service';
import { toast } from 'react-toastify';
import axios from 'axios';

function ManagePermissionRow({
    permission,
    UoG,
    path,
    onChangePermission
}: {
    permission: IPermission;
    UoG: 'user' | 'group';
    path: string;
    onChangePermission: (
        user: string | null,
        group: string | null,
        permission: EAccessPermission
    ) => void;
}) {
    const [isLoading, setIsLoading] = useState(false);
    const [tempPermission, setTempPermission] = useState<EAccessPermission>(permission.permission);
    const [recursive, setRecursive] = useState<boolean>(false);

    const changePermission = useCallback(
        async (
            projectId: string,
            path: string,
            user: string | null,
            group: string | null,
            permission: EAccessPermission,
            recursive: boolean
        ) => {
            try {
                setIsLoading(true);
                await axiosService.post(`permission`, {
                    project: projectId,
                    path: path,
                    ...(user && { user }),
                    ...(group && { group }),
                    permission,
                    recursive
                });
                toast('Change permission success!', {
                    type: 'success',
                    autoClose: 1000
                });
                onChangePermission(user, group, permission);
                setRecursive(false);
            } catch (error) {
                if (axios.isAxiosError(error)) {
                    axiosErrorHandler(error);
                } else {
                    console.log('error:  ', error);
                }
            } finally {
                setIsLoading(false);
            }
        },
        [onChangePermission]
    );

    return (
        <tr>
            <td className={clsx(styles.tdName)}>
                {UoG === 'user' ? permission.user.username : permission.group.name}
            </td>
            <td className={clsx(styles.tdAlignCenter)}>
                <input
                    type="checkbox"
                    checked={
                        tempPermission === EAccessPermission.READ ||
                        tempPermission === EAccessPermission.READ_WRITE
                    }
                    onChange={() => { }}
                    onClick={(e: React.MouseEvent) => {
                        e.stopPropagation();
                        if (
                            tempPermission === EAccessPermission.READ ||
                            tempPermission === EAccessPermission.READ_WRITE
                        ) {
                            setTempPermission(EAccessPermission.NO_PERMISSION);
                        } else {
                            setTempPermission(EAccessPermission.READ);
                        }
                    }}
                />
            </td>
            <td className={clsx(styles.tdAlignCenter)}>
                <input
                    type="checkbox"
                    checked={tempPermission === EAccessPermission.READ_WRITE}
                    onChange={() => { }}
                    onClick={(e: React.MouseEvent) => {
                        e.stopPropagation();
                        if (tempPermission === EAccessPermission.READ_WRITE) {
                            setTempPermission(EAccessPermission.READ);
                        } else {
                            setTempPermission(EAccessPermission.READ_WRITE);
                        }
                    }}
                />
            </td>

            <td className={clsx(styles.tdAlignCenter)}>
                <input
                    type="checkbox"
                    checked={recursive}
                    onChange={() => { }}
                    onClick={(e: React.MouseEvent) => {
                        e.stopPropagation();
                        setRecursive(!recursive);
                    }}
                />
            </td>
            <td className={clsx(styles.tdAlignCenter)}>
                {(tempPermission !== permission.permission || recursive) && (
                    <button
                        className={clsx(styles.button, 'w-100')}
                        onClick={(e: React.MouseEvent) => {
                            e.stopPropagation();
                            changePermission(
                                permission.project,
                                path,
                                permission.user?.id || null,
                                permission.group?.id || null,
                                tempPermission,
                                recursive
                            );
                        }}>
                        {isLoading ? (
                            <div
                                className="spinner-border spinner-border-sm mt-1"
                                role="status"></div>
                        ) : (
                            'update'
                        )}
                    </button>
                )}
            </td>
        </tr>
    );
}

export default ManagePermissionRow;
